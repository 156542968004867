import * as action from './ReportActionTypes';
import decode from 'jwt-decode';
const FileSaver = require('file-saver');

export const getPaymentReportData = (page, props) => {
  console.log("getBookingReportData",props);
  const AuthTokenData = decode(props.token);
  const userCountry = AuthTokenData?.location ?.country;
  console.log("getBookingReportData token ",decode(props.token));
  console.log("user country  ",userCountry);
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_PAYMENT_REPORT_DATA,
    });
    axiosInstance.get(`md/report/payment-tracker?format=WEB_VIEW&size=${props.increaseValue}&page=${page}&sort=createdDate&sortOrder=DESCENDING&spaceCountry=${userCountry}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_PAYMENT_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_PAYMENT_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const getSearchResults = (searchText, type,mainType) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: action.SEARCH_BOOKINGS
    });

  axiosInstance.get(`md/report/reporting?searchText=${searchText}&type=${type}&mainType=${mainType}`)
      .then((res) => {
        dispatch({
          type: action.SEARCH_BOOKINGS_SUCCESS,
          payload: res.data
        });
      
      })
      .catch((e) => {
        dispatch({
          type: action.SEARCH_BOOKINGS_FAILS,
          payload: e
        });
      });
      
  };
};

export const getSearchResultsAsLists = (searchText, type,mainType,startDate,endDate) => {
  return (dispatch, getState, { axiosInstance }) => {
    dispatch({
      type: action.SEARCH_LISTS
    });

    axiosInstance.get(`md/report/reporting-lists?searchText=${searchText}&type=${type}&mainType=${mainType}&startDate=${startDate}&endDate=${endDate}`)
      .then((res) => {
        dispatch({
          type: action.SEARCH_LISTS_SUCCESS,
          payload: res.data
        });
      
      })
      .catch((e) => {
        dispatch({
          type: action.SEARCH_LISTS_FAILS,
          payload: e
        });
      });
      
  };
};
export const getBookingReportData = (page, props) => {
  console.log("getBookingReportData",props);
  const AuthTokenData = decode(props.token);
const userCountry = AuthTokenData?.location ?.country;
  console.log("getBookingReportData token ",decode(props.token));
  console.log("user country  ",userCountry);
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_BOOKING_REPORT_DATA,
    });
    axiosInstance.get(`md/report/booking-tracker?format=WEB_VIEW&size=${props.increaseValue}&page=${page}&sort=createdDate&sortOrder=DESCENDING&spaceCountry=${userCountry}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_BOOKING_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_BOOKING_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const getRevenueReportData = (page, props) => {
  console.log("getBookingReportData",props);
  const AuthTokenData = decode(props.token);
  const userCountry = AuthTokenData?.location ?.country;
  console.log("getBookingReportData token ",decode(props.token));
  console.log("user country  ",userCountry);
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_REVENUE_REPORT_DATA,
    });
    axiosInstance.get(`md/report/revenue-tracker?sort=createdDate&sortOrder=DESCENDING&size=${props.increaseValue}&page=${page}&format=WEB_VIEW&spaceCountry=${userCountry}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_REVENUE_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })

      .catch((error) => {
        dispatch({
          type: action.FETCH_REVENUE_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const getPaymentRefundReportData = (page, props) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_PAYMENT_REFUND_REPORT_DATA,
    });
    axiosInstance.get(`md/report/payment-refund?format=WEB_VIEW&size=${props.increaseValue}&page=${page}&sort=createdDate&sortOrder=DESCENDING`)
      .then((res) => {
        dispatch({
          type: action.FETCH_PAYMENT_REFUND_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_PAYMENT_REFUND_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const getSpaceReportData = (page, props) => {
  console.log("getBookingReportData",props);
  const AuthTokenData = decode(props.token);
  const userCountry = AuthTokenData?.location ?.country;
  console.log("getBookingReportData token ",decode(props.token));
  console.log("user country  ",userCountry);
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_SPACE_REPORT_DATA,
    });
    axiosInstance.get(`md/report/space-tracker?format=WEB_VIEW&size=${props.increaseValue}&page=${page}&sort=createdDate&sortOrder=DESCENDING&spaceCountry=${userCountry}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_SPACE_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_SPACE_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const getUserTrackerData = (page, props) => {
  console.log("getBookingReportData",props);
  const AuthTokenData = decode(props.token);
  const userCountry = AuthTokenData?.location ?.country;
  console.log("getBookingReportData token ",decode(props.token));
  console.log("user country  ",userCountry);
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_USER_REPORT_DATA,
    });
    axiosInstance.get(`md/report/user-tracker?size=${props.increaseValue}&page=${page}&sort=createdDate&sortOrder=DESCENDING&format=WEB_VIEW&userCountry=${userCountry}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_USER_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_USER_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const getSummaryTrackerData = (page, props) => {
  console.log("getBookingReportData",props);
  const AuthTokenData = decode(props.token);
const userCountry = AuthTokenData?.location ?.country;
  console.log("getBookingReportData token ",decode(props.token));
  console.log("user country  ",userCountry);
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_SUMMARY_REPORT_DATA,
    });
    axiosInstance.get(`md/report/summary-tracker?format=WEB_VIEW&size=${props.increaseValue}&page=${page}&sort=createdDate&sortOrder=DESCENDING&spaceCountry=${userCountry}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_SUMMARY_REPORT_DATA_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_SUMMARY_REPORT_DATA_FAILS,
          payload: {
            error: error.response,
            data: error.response?.data,
          },
        });
      });
  };
};

export const downloadCSV = (id, reportName) => {

  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.DOWNLOAD_CSV,
    });

    axiosInstance.get(`md/report/export?taskId=${id}`)
      .then((res) => {
        const fetchedResponse = new Blob([res.data], {type: "csv/plain;charset=utf-8"});
        FileSaver.saveAs(fetchedResponse, `${reportName}.csv`);
        dispatch({
          type: action.DOWNLOAD_CSV_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.DOWNLOAD_CSV_FAILS,
          payload: error
        });
      });
  };
}

export const exportReport = (csvData) => {
  const userCountry = csvData.spaceCountry;
  let downloadURl, values ='';

  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_REPORT_STATUS,
    });

    if(csvData.filteredDataLength) {
      for(const [key, value] of Object.entries(csvData.filters)) {
        values = values + `&${key}=${value}`;
      }
      downloadURl = `md/report/${csvData?.reportType}?format=CSV&sort=createdDate&sortOrder=DESCENDING` + values + `&spaceCountry=${userCountry}`;
    } else {
        downloadURl = `md/report/${csvData?.reportType}?format=CSV&sort=createdDate&sortOrder=DESCENDING&spaceCountry=${userCountry}`;
    }

    axiosInstance.get(downloadURl)
      .then((res) => {
        dispatch({
          type: action.FETCH_REPORT_STATUS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_REPORT_STATUS_FAILS,
          payload: error,
        });
    });
  }
};

export const fetchReportStatus = (id) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_REPORT_STATUS,
    });

    axiosInstance.get(`md/report-task?id=${id}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_REPORT_STATUS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_REPORT_STATUS_FAILS,
          payload: error,
        });
    });
  }
};

export const fetchAllReports = (page, props) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: action.FETCH_AVAILABLE_REPORTS,
    });

    axiosInstance.get(`md/report-task?page=${page}&size=${props.increaseValue}`)
      .then((res) => {
        dispatch({
          type: action.FETCH_AVAILABLE_REPORTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: action.FETCH_AVAILABLE_REPORTS_FAILS,
          payload: error,
        });
    });
  }
}

export const clearvailableReports = () => ({
  type: action.CLEAR_AVAILABLE_REPORTS
})

export const clearReportTrackerStatus = (trackerName) => ({
  type: action.CLEAR_COMPLETED_TRACKER_STATUS,
  payload: trackerName
})

export const resetReportModule=()=>{
  
  return dispatch => {
    dispatch({
      type: action.RESET_REPORTS
    });
  }
}
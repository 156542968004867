export const hostOnBoardingData = (country="Singapore") => {
  return {
    "currentRoute":"",
    "routeList":[
      `/${country}/dashboard/host-onboarding/welcome`,
      `/${country}/dashboard/host-onboarding/organization_details`,
      `/${country}/dashboard/host-onboarding/commission`,
      `/${country}/dashboard/host-onboarding/payout_details`,
      `/${country}/dashboard/host-onboarding/agreement`,
      `/${country}/dashboard/host-onboarding/congratulations`
    ],
    "processStarted":false,
    "organization_details":[
      {
        "orgName":"",
        "orgWebsite":"",
        "businessRegNo":"",
        "country":"SG",
        "city":"",
        "street":"",
        "fistName":"",
        "lastName":"",
        "email":"",
        "dialCode":"+65",
        "phone":"",
        "designation":"",
        "addressLine1":"",
        "addressLine2":"",
        "postalCode":""
      }
    ],
    "commission":[
      {
        "percentage":"10"
      }
    ],
    "payout_details":[
      {
        "accountName":"",
        "bank":"",
        "branch":"",
        "accountNumber":"",
        "branchCode":"",
        "swiftCode":""
      }
    ],
    "agreement":[
      {
        "status":false
      }
    ]
  }
}

import React, { useState } from 'react';
import { Tabs } from 'antd'
import ReportView from './ReportView';
import ReportDownload from './ReportDownload';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
const ReportContainer = () => {

    const [activeTabKey, setActiveTabKey] = useState("0");
    const reportsStatus = useSelector(state => state?.report?.reportStatus.data)
    
    const handleTabsChange = tab => {
        setActiveTabKey(tab);
    }

    // const isDownloading = () => {
    //     return Boolean(reportsStatus.filter(report => report.taskStatus === 'PENDING').length);
    // };

    return (<>
        <div className="admin-wrapper reporting-wrap">
            <div className="container"></div>
            <Tabs
                type="card"
                className="report-tab reporting-outer-wrap"
                onChange={handleTabsChange}
                activeKey={activeTabKey}
                key={activeTabKey}
            >
                <TabPane tab="Reports View" key="0" forceRender={false}>
                    <ReportView />
                </TabPane>
                <TabPane tab={`Reports Download`} key="1">
                    <ReportDownload />
                </TabPane>
            </Tabs>
        </div>
    </>)
};

export default ReportContainer;
import moment from 'moment';
import { DATE_FORMAT, MONTH,NEW_DATE_FORMAT_FOR_REPORT } from '../components/Common/constants';
import { Tooltip } from 'antd';
import React from 'react';

export const bookingTrackerObj = (bookingArray) => {
  return bookingArray?.map((data) => ({
    bookingRef: data.reportData.bookingId,
    category: data.reportData.category,
    activity: data.reportData.activity,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceCountry: data.reportData.spaceCountry,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.reportData.eventStartDate !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: data.reportData.eventEndDate !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT) : 'N/A',
    eventStartTime: data.reportData.eventStartTime,
    eventEndTime: data.reportData.eventEndTime,
    month: data.reportData.month,
    year: data.reportData.year,
    guestCount: data.reportData.guest,
    days: data.reportData.days,
    hours: data.reportData.hours,
    blocks: data.reportData.blocks,
    bookingCount: data.reportData.bookingCount,
    promoCode: data.reportData.promoCode,
    bookingType: data.reportData.bookingType,
    paymentStatus: data.reportData.paymentStatus,
    bookingStatus: data.reportData.bookingStatus,
  }));
};

export const bookingTrackerObjList = (bookingArrayList) => {

return bookingArrayList?.map((data) => (

    {

    bookingRef: data.bookingId,
    category: data.category,
    activity: data.activity,
    guestName: data.guestName,
    guestEmail: data.guestEmail,
    hostName: data.hostName,
    hostEmail: data.hostEmail,
    spaceId: data.spaceId,
    spaceName: data.spaceName,
    spaceCountry: data.spaceCountry,
    bookingDate: data.bookingDate !== null ? moment(data.bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.eventStartDate !== null ? moment(data.eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: data.eventEndDate !== null ? moment(data.eventEndDate).format(DATE_FORMAT) : 'N/A',
    eventStartTime: data.eventStartTime,
    eventEndTime: data.eventEndTime,
    month: data.month,
    year: data.year,
    guestCount: data.guest,
    days: data.days,
    hours: data.hours,
    blocks: data.blocks,
    bookingCount: data.bookingCount,
    promoCode: data.promoCode,
    bookingType: data.bookingType,
    paymentStatus: data.paymentStatus,
    bookingStatus: data.bookingStatus,
  }
  )
  )


  
};


export const bookingTrackerSearchResultObj = (bookingObj) => {
  const bookingDate = bookingObj.bookingDate;
  const eventStartDate = bookingObj.eventStartDate
  const  eventEndDate = bookingObj.eventEndDate
  return({
    bookingRef: bookingObj.bookingId,
    category: bookingObj.category,
    activity: bookingObj.activity,
    guestName: bookingObj.guestName,
    guestEmail: bookingObj.guestEmail,
    hostName: bookingObj.hostName,
    hostEmail: bookingObj.hostEmail,
    spaceId: bookingObj.spaceId,
    spaceName: bookingObj.spaceName,
    spaceCountry: bookingObj.spaceCountry,
    bookingDate: bookingDate !== null && moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: eventStartDate !== null && moment(eventStartDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: eventEndDate !== null && moment(eventEndDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartTime: bookingObj.eventStartTime,
    eventEndTime: bookingObj.eventEndTime,
    month: bookingObj.month,
    year: bookingObj.year,
    guestCount: bookingObj.guest,
    days: bookingObj.days,
    hours: bookingObj.hours,
    blocks: bookingObj.blocks,
    bookingCount: bookingObj.bookingCount,
    promoCode: bookingObj.promoCode,
    bookingType: bookingObj.bookingType,
    paymentStatus: bookingObj.paymentStatus,
    bookingStatus: bookingObj.bookingStatus,
  })
};


export const paymentRefundObj = (paymentRefundArray) => {
  return paymentRefundArray?.map((data) => ({
    bookingRef: data.reportData.bookingId,
    paymentRef: data.reportData.paymentId,
    refundRef: data.reportData.paymentRefundId,
    category: data.reportData.category,
    activity: data.reportData.activity,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceCountry: data.reportData.spaceCountry,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT) : 'N/A',
    paymentDate: data.reportData.paymentDate !== null ? moment(data.reportData.paymentDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.reportData.eventStartDate !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: data.reportData.eventEndDate !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT) : 'N/A',
    month: data.reportData.month,
    year: data.reportData.year,
    paymentAmount: data.reportData.paymentAmount,
    transactionFee: data.reportData.transactionFee,
    receivedAmount: data.reportData.receivedAmount,
    refundDate: data.reportData.refundDate !== null ? moment(data.reportData.refundDate).format(DATE_FORMAT) : 'N/A',
    refundAmount: data.reportData.refundAmount,
    refundTransactionFee: data.reportData.refundTransactionFee,
    totalRefundValue: data.reportData.totalRefundValue,
    refundReason: data.reportData.refundReason,
    cardType: data.reportData.cartType,
    cardNumber: data.reportData.cardNumber,
    cardExpDate: data.reportData.cardExpiryDate !== null ? moment(data.reportData.cardExpiryDate).format(DATE_FORMAT) : 'N/A' ,
  }));
};

export const paymentTrackerObj = (paymentArray) => {
  return paymentArray?.map((data) => ({
    bookingRef: data.reportData.bookingId,
    paymentRef: data.reportData.paymentId,
    category: data.reportData.category,
    activity: data.reportData.activity,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceCountry: data.reportData.spaceCountry,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.reportData.startDate !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT) : 'N?A',
    eventEndDate: data.reportData.eventEndDate !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT) : 'N?A',
    paymentDate: data.reportData.paymentDate !== null ? moment(data.reportData.paymentDate).format(DATE_FORMAT) : 'N/A',
    month: data.reportData.month,
    year: data.reportData.year,
    paymentAmount: data.reportData.paymentAmount,
    transactionFee: data.reportData.transactionFee,
    cardType: data.reportData.cardType,
    cardNumber: data.reportData.cardNumber,
    cardExpDate: data.reportData.cardExpiryDate !== null ? moment(data.reportData.cardExpiryDate).format(DATE_FORMAT) : 'N/A',
    paymentStatus: data.reportData.paymentStatus,
    bookingStatus: data.reportData.bookingStatus,
  }));
};


export const paymentTrackerObjList = (paymentArrayList) => {
  return paymentArrayList?.map((data) => ({
    bookingRef: data.bookingId,
    paymentRef: data.paymentId,
    category: data.category,
    activity: data.activity,
    guestName: data.guestName,
    guestEmail: data.guestEmail,
    hostName: data.hostName,
    hostEmail: data.hostEmail,
    spaceId: data.spaceId,
    spaceName: data.spaceName,
    spaceCountry: data.spaceCountry,
    bookingDate: data.bookingDate !== null ? moment(data.bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.startDate !== null ? moment(data.eventStartDate).format(DATE_FORMAT) : 'N?A',
    eventEndDate: data.eventEndDate !== null ? moment(data.eventEndDate).format(DATE_FORMAT) : 'N?A',
    paymentDate: data.paymentDate !== null ? moment(data.paymentDate).format(DATE_FORMAT) : 'N/A',
    month: data.month,
    year: data.year,
    paymentAmount: data.paymentAmount,
    transactionFee: data.transactionFee,
    cardType: data.cardType,
    cardNumber: data.cardNumber,
    cardExpDate: data.cardExpiryDate !== null ? moment(data.cardExpiryDate).format(DATE_FORMAT) : 'N/A',
    paymentStatus: data.paymentStatus,
    bookingStatus: data.bookingStatus,
  }));
};

export const paymentTrackerSearchResultsObj = (paymentObj) => {
  const paymentDate = paymentObj.paymentDate;
  const bookingDate = paymentObj.bookingDate;
  const eventStartDate = paymentObj.eventStartDate
  const  eventEndDate = paymentObj.eventEndDate
  const  cardExpDate = paymentObj.cardExpDate
  return ({
    bookingRef: paymentObj.bookingId,
    paymentRef: paymentObj.paymentId,
    category: paymentObj.category,
    activity: paymentObj.activity,
    guestName: paymentObj.guestName,
    guestEmail: paymentObj.guestEmail,
    hostName: paymentObj.hostName,
    hostEmail: paymentObj.hostEmail,
    spaceId: paymentObj.spaceId,
    spaceName: paymentObj.spaceName,
    spaceCountry: paymentObj.spaceCountry,
    bookingDate: bookingDate !== null && moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    paymentDate:  paymentDate !== null && moment(paymentDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(paymentDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: eventStartDate !== null && moment(eventStartDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: eventEndDate !== null && moment(eventEndDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(eventEndDate).format(DATE_FORMAT) : 'N/A',
    month: paymentObj.month,
    year: paymentObj.year,
    paymentAmount: paymentObj.paymentAmount,
    transactionFee: paymentObj.transactionFee,
    cardType: paymentObj.cardType,
    cardNumber: paymentObj.cardNumber,
    cardExpDate: cardExpDate !== null && moment(cardExpDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(cardExpDate).format(DATE_FORMAT) : 'N/A',
    paymentStatus: paymentObj.paymentStatus,
    bookingStatus: paymentObj.bookingStatus,
  });
};
export const revenueTrackerObj = (revenueArray) => {
    return revenueArray?.map(data => ({
      bookingRef: data.reportData.bookingId,
      category: data.reportData.category,
      activity: data.reportData.activity,
      guestName: data.reportData.guestName,
      guestEmail: data.reportData.guestEmail,
      hostName: data.reportData.hostName,
      hostEmail: data.reportData.hostEmail,
      spaceId: data.reportData.spaceId,
      spaceName: data.reportData.spaceName,
      spaceCountry: data.reportData.spaceCountry,
      bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT): 'N/A',
      paymentDate: data.reportData.paymentDate  !== null ? moment(data.reportData.paymentDate).format(DATE_FORMAT): 'N/A',
      eventStartDate: data.reportData.eventStartDate  !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT): 'N/A',
      eventEndDate: data.reportData.eventEndDate  !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT): 'N/A',
      month: data.reportData.month,
      year: data.reportData.year,
      baseBookingValue: data.reportData.baseBookingValue,
      hostDiscountValue: data.reportData.hostDiscountValue,
      bookingValueAfterHostDiscount: data.reportData.bookingValueAfterHostDiscount,
      taxPayableToHost: data.reportData.taxPayableToHost,
      msDiscountValue: data.reportData.msDiscountValue,
      finalBookingValue: data.reportData.finalBookingValue,
      taxPayableByGuest: data.reportData.taxPayableByGuest,
      finalGuestPaymentAmount: data.reportData.finalGuestPayableAmount,
      transactionFee: data.reportData.transactionFee,
      msCommission: data.reportData.msCommission,
      commissionValue: data.reportData.commissionValue,
      finalCommissionValue: data.reportData.finalCommissionValue,
      totalPayableToHost: data.reportData.totalPayableToHost,
      grossProfit: data.reportData.grossProfit,
      paymentStatus: data.reportData.paymentStatus,
      bookingStatus: data.reportData.bookingStatus,
    }))
}


export const revenueTrackerObjList = (revenueArrayList) => {
  
  return revenueArrayList?.map(data => ({
    bookingRef: data.bookingId,
    category: data.category,
    activity: data.activity,
    guestName: data.guestName,
    guestEmail: data.guestEmail,
    hostName: data.hostName,
    hostEmail: data.hostEmail,
    spaceId: data.spaceId,
    spaceName: data.spaceName,
    spaceCountry: data.spaceCountry,
    bookingDate: data.bookingDate !== null ? moment(data.bookingDate).format(DATE_FORMAT): 'N/A',
    paymentDate: data.paymentDate  !== null ? moment(data.paymentDate).format(DATE_FORMAT): 'N/A',
    eventStartDate: data.eventStartDate  !== null ? moment(data.eventStartDate).format(DATE_FORMAT): 'N/A',
    eventEndDate: data.eventEndDate  !== null ? moment(data.eventEndDate).format(DATE_FORMAT): 'N/A',
    month: data.month,
    year: data.year,
    baseBookingValue: data.baseBookingValue,
    hostDiscountValue: data.hostDiscountValue,
    bookingValueAfterHostDiscount: data.bookingValueAfterHostDiscount,
    taxPayableToHost: data.taxPayableToHost,
    msDiscountValue: data.msDiscountValue,
    finalBookingValue: data.finalBookingValue,
    taxPayableByGuest: data.taxPayableByGuest,
    finalGuestPaymentAmount: data.finalGuestPayableAmount,
    transactionFee: data.transactionFee,
    msCommission: data.msCommission,
    commissionValue: data.commissionValue,
    finalCommissionValue: data.finalCommissionValue,
    totalPayableToHost: data.totalPayableToHost,
    grossProfit: data.grossProfit,
    paymentStatus: data.paymentStatus,
    bookingStatus: data.bookingStatus,
  }))
}

export const revenueTrackerSearchResultObj = (revenueObj) => {
  const paymentDate = revenueObj.paymentDate;
  const bookingDate = revenueObj.bookingDate;
  const eventStartDate = revenueObj.eventStartDate
  const  eventEndDate = revenueObj.eventEndDate
  return  ({
    bookingRef: revenueObj.bookingId,
    category: revenueObj.category,
    activity: revenueObj.activity,
    guestName: revenueObj.guestName,
    guestEmail: revenueObj.guestEmail,
    hostName: revenueObj.hostName,
    hostEmail: revenueObj.hostEmail,
    spaceId: revenueObj.spaceId,
    spaceName: revenueObj.spaceName,
    spaceCountry: revenueObj.spaceCountry,
    bookingDate: bookingDate !== null && moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    paymentDate:  paymentDate !== null && moment(paymentDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(paymentDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: eventStartDate !== null && moment(eventStartDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: eventEndDate !== null && moment(eventEndDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(eventEndDate).format(DATE_FORMAT) : 'N/A',
    year: revenueObj.year,
    baseBookingValue: revenueObj.baseBookingValue,
    hostDiscountValue: revenueObj.hostDiscountValue,
    bookingValueAfterHostDiscount:revenueObj.bookingValueAfterHostDiscount,
    taxPayableToHost: revenueObj.taxPayableToHost,
    msDiscountValue: revenueObj.msDiscountValue,
    finalBookingValue: revenueObj.finalBookingValue,
    taxPayableByGuest: revenueObj.taxPayableByGuest,
    finalGuestPaymentAmount: revenueObj.finalGuestPayableAmount,
    transactionFee: revenueObj.transactionFee,
    msCommission: revenueObj.msCommission,
    commissionValue: revenueObj.commissionValue,
    finalCommissionValue: revenueObj.finalCommissionValue,
    totalPayableToHost: revenueObj.totalPayableToHost,
    grossProfit: revenueObj.grossProfit,
    paymentStatus: revenueObj.paymentStatus,
    bookingStatus: revenueObj.bookingStatus,
  })
}

export const spaceTrackerObj = (spaceArray) => {
  return spaceArray?.map((data) => ({
    category: data.reportData.categories?.map(category => category),
    activity: data.reportData.activities?.map(activity => activity),
    organizationName: data.reportData.organizationName,
    listedDate: data.reportData.listedDate !== null ? moment(data.reportData.listedDate).format(DATE_FORMAT) : 'N/A',
    listedMonth: data.reportData.month,
    listedYear: data.reportData.year,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    hostNumber: data.reportData.hostTelephone?.map(number => number),
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceAddress: data.reportData.spaceAddress,
    spaceCity: data.reportData.spaceCity,
    spaceCountry: data.reportData.spaceCountry,
    maxSpaceCapacity: data.reportData.spaceCapacity?.map(capacity => capacity),
    nameOnAccount: data.reportData.nameOfAccount,
    bank: data.reportData.bank,
    swiftCode: data.reportData.swiftCode,
    bankCode: data.reportData.bankCode,
    branchCode: data.reportData.branchCode,
    accountNumber: data.reportData.accountNumber,
    commission: data.reportData.commission,
    status: data.reportData.spaceStatus,
  }));
};



export const spaceTrackerObjList = (spaceArrayList) => {
  return spaceArrayList?.map((data) => ({
    category: data.categories?.map(category => category),
    activity: data.activities?.map(activity => activity),
    organizationName: data.organizationName,
    listedDate: data.listedDate !== null ? moment(data.listedDate).format(DATE_FORMAT) : 'N/A',
    listedMonth: data.month,
    listedYear: data.year,
    hostName: data.hostName,
    hostEmail: data.hostEmail,
    hostNumber: data.hostTelephone?.map(number => number),
    spaceId: data.spaceId,
    spaceName: data.spaceName,
    spaceAddress: data.spaceAddress,
    spaceCity: data.spaceCity,
    spaceCountry: data.spaceCountry,
    maxSpaceCapacity: data.spaceCapacity?.map(capacity => capacity),
    nameOnAccount: data.nameOfAccount,
    bank: data.bank,
    swiftCode: data.swiftCode,
    bankCode: data.bankCode,
    branchCode: data.branchCode,
    accountNumber: data.accountNumber,
    commission: data.commission,
    status: data.spaceStatus,
  }));
};
export const spaceTrackerResultsObj = (spaceObj) => {
  const listedDate = spaceObj.listedDate;
  
  return ({
    category: spaceObj.categories?.map(category => category),
    activity: spaceObj.activities
    ?.map(activity => activity),
    organizationName: spaceObj.organizationName,
    listedDate: listedDate !== null && moment(listedDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(listedDate).format(DATE_FORMAT) : 'N/A',
    listedMonth: spaceObj.month,
    listedYear: spaceObj.year,
    hostName: spaceObj.hostName,
    hostEmail: spaceObj.hostEmail,
    hostNumber: spaceObj.hostTelephone,
    spaceId: spaceObj.spaceId,
    spaceName: spaceObj.spaceName,
    spaceAddress: spaceObj.spaceAddress,
    spaceCity: spaceObj.spaceCity,
    spaceCountry: spaceObj.spaceCountry,
    maxSpaceCapacity: spaceObj.spaceCapacity?.map(spaceCapacity => spaceCapacity),
    nameOnAccount: spaceObj.nameOfAccount,
    bank: spaceObj.bank,
    swiftCode: spaceObj.swiftCode,
    bankCode: spaceObj.bankCode,
    branchCode: spaceObj.branchCode,
    accountNumber: spaceObj.accountNumber,
    commission: spaceObj.commission,
    status: spaceObj.spaceStatus,
  });
};

export const userTrackerObj = (userArray) => {
  return userArray?.map(data => ({
    firstName: data.reportData.firstName,
    lastName: data.reportData.lastName,
    email: data.reportData.email,
    spaceCountry: data.reportData.country,
    telephoneNumber: data.reportData.phoneNumbers,
    role: data.reportData.roles?.map(role => role),
    host: data.reportData.host,
    signUpVia: data.reportData.signUpVia,
    dateJoined: data.reportData.dateJoined !== null ? moment(data.reportData.dateJoined).format(DATE_FORMAT) : 'N/A',
    status:  data.reportData.status
  }))
}

export const userTrackerObjList = (userArrayList) => {
  return userArrayList?.map(data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    spaceCountry: data.country,
    telephoneNumber: data.phoneNumbers,
    role: data.roles?.map(role => role),
    host: data.host,
    signUpVia: data.signUpVia,
    dateJoined: data.dateJoined !== null ? moment(data.dateJoined).format(DATE_FORMAT) : 'N/A',
    status:  data.status
  }))
}

export const userTrackerResultsObj = (userObj) => {
  const dateJoined = userObj.dateJoined;
  return ({
    firstName: userObj.firstName,
    lastName: userObj.lastName,
    email: userObj.email,
    spaceCountry: userObj.country,
    telephoneNumber: userObj.phoneNumbers,
    role: userObj.roles,
    host: userObj.host,
    signUpVia: userObj.signUpVia,
    dateJoined: dateJoined !== null && moment(dateJoined, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(dateJoined).format(DATE_FORMAT) : 'N/A',
    status: userObj.status
  })
}

export const summaryTrackerObj = (summaryArray) => {
  return summaryArray?.map(data => ({
    spaceCountry: data.reportData.spaceCountry,
    bookingId: data.reportData.bookingId,
    bookingStatus: data.reportData.bookingStatus,
    bookingType: data.reportData.bookingType,
    guestCount: data.reportData.guest,
    paymentStatus: data.reportData.paymentStatus,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(NEW_DATE_FORMAT_FOR_REPORT) : 'N/A',
    eventStartDate: data.reportData.startDate !== null ? moment(data.reportData.eventStartDate).format(NEW_DATE_FORMAT_FOR_REPORT) : 'N?A',
    paymentAmount:data.reportData.paymentAmount,
    cardType: data.reportData.cardType,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    activity: data.reportData.activity,
    spaceName: data.reportData.spaceName,
  }))
}

export const summaryTrackerObjLists = (summaryArrayList) => {
  return summaryArrayList?.map(data => ({
    spaceCountry: data.spaceCountry,
    bookingId: data.bookingId,
    bookingStatus: data.bookingStatus,
    bookingType: data.bookingType,
    guestCount: data.guest,
    paymentStatus: data.paymentStatus,
    bookingDate: data.bookingDate !== null ? moment(data.bookingDate).format(NEW_DATE_FORMAT_FOR_REPORT) : 'N/A',
    eventStartDate: data.startDate !== null ? moment(data.eventStartDate).format(NEW_DATE_FORMAT_FOR_REPORT) : 'N?A',
    paymentAmount:data.paymentAmount,
    cardType: data.cardType,
    guestName: data.guestName,
    guestEmail: data.guestEmail,
    activity: data.activity,
    spaceName: data.spaceName,
  }))
}


export const summaryTrackerResultsObj = (summaryObj) => {
  const bookingDate = summaryObj.bookingDate;
  const eventStartDate = summaryObj.eventStartDate
  
  return ({
    spaceCountry: summaryObj.spaceCountry,
    bookingId: summaryObj.bookingId,
    bookingStatus: summaryObj.bookingStatus,
    bookingType: summaryObj.bookingType,
    guestCount: summaryObj.guest,
    paymentStatus: summaryObj.paymentStatus,
    bookingDate: bookingDate !== null && moment(bookingDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: eventStartDate !== null && moment(eventStartDate, 'YYYY-MM-DDTHH:mm:ss').isValid() ? moment(bookingDate).format(DATE_FORMAT) : 'N/A',
    paymentAmount:summaryObj.paymentAmount,
    cardType: summaryObj.cardType,
    guestName: summaryObj.guestName,
    guestEmail: summaryObj.guestEmail,
    activity: summaryObj.activity,
    spaceName:summaryObj.spaceName,
  })
}

//when the data in the column is a single value
export const searchOnFilter = (value, record, dataIndex) => {
  if (record[dataIndex] === undefined || record[dataIndex] === null) {
    record[dataIndex] = '';
  }
  return value.find(
    (element) =>
      element.toString().trim().toLowerCase().includes(record[dataIndex].trim().toLowerCase()) ||
      (element === 'after' && record[dataIndex] > moment().year().toString()) ||
      (element === 'before' && record[dataIndex] < moment().year().toString())
  );
};

//when the data in the column in an array
export const arrayColumnFilter = (value, record, dataIndex) => {
  return record[dataIndex].find((data,index) => 
      value.find(element => record[dataIndex][index] === element)
    )
}

export const searchFilterForEmptyFields = (value, record, dataIndex) => {
  return value.find(element => record[dataIndex] === element);
}

export const searchTextOnFilter = (value, record, dataIndex) => {
  if(record[dataIndex] === undefined || record[dataIndex] === null) {
    record[dataIndex] = '';
  }
  return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
}

export const formatText = (text) => {
  return text === null || text === '' || text === 0 ? '-' : Array.isArray(text) ? text.length > 1 ? text.map(t => t + ', ') : text : text;
}

export const handleNullValues = text => {
  return <Tooltip title={formatText(text)} overlayClassName="report-tooltip"> {formatText(text)}</Tooltip>;
}

export const addOrUppdateArray = (array, payload, key) => {
  if(Boolean(array.length)){
      if(Boolean(array.filter(value => value[key] === payload[key]).length)){
          return array.map((item) => {
              if (item[key] !== payload[key]) {
                return item
              }
              return {...item, ...payload}
          })
      }else{
          return [...array, payload]
      }
  }else{
      return [payload]
  }
}
import React, { useEffect, useState } from 'react';
import { TextField } from 'react-md';
import Select from 'antd/es/select';
import { BLOCK_OPTION, DEFAULT_DATA_OBJ, ERROR_ONLY_NUMBERS, PRICING_OPTIONS, WEEKLY_OPTION } from '../../../SpaceListingConstants';
import { dailyPricingOptionValidation, showErrorMessage } from '../../../Common/Common';
import FlowHandlingButtons from '../../../Common/HandleNavigationAndSubmit';
import TimePicker from '../../../../Common/TimePicker/TimePicker';
import { getProfileData, setProfileData } from '../../../Common/DataController';
import { spaceListingData } from '../../../Common/ProfileListingDataModal';
import grayPlusIcon from '../../../../../assets/images/common/plus.png';
import moment, { weekdays } from 'moment';
import { TIME_FORMAT } from '../../../../Common/constants';
import { contentIsNotNull } from '../../../Common/Validator';
import optionIcon from '../../../../../assets/images/space-listing/daily-pricing-icon.svg';
import removeIcon from '../../../../../assets/images/space-listing/remove-red.svg';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IMAGES_BASE_URL } from '../../../../../settings';

const { Option } = Select;
const BlockPricingAndAvailabilityDetails = ({ pricingOption }) => {
  const { country } = useParams();
  const [blockPricingDetails, setBlockPricingDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [lastDayArray, setLastDayArray] = useState(blockPricingDetails?.['pricing_options']?.[0]?.[BLOCK_OPTION]?.['pricingDetails']?.['dayTypes']);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const dateArray = useSelector(
    (state) => state?.spaceListing.pricingOptionDaysArray?.find((value) => value?.name?.toLowerCase() === 'block')?.pricingOptions || []
  );
  const lastDayArrayWeekdays = blockPricingDetails?.['pricing_options']?.[0]?.[BLOCK_OPTION]?.['pricingDetails']?.days;
  let weekday = [];
  const Weekdays = []
  useEffect(() => {
    setIsNextBtnActive(
      contentIsNotNull(getBlockValue()?.dayTypes, 0) &&
        getBlockValue()?.days?.filter((value) => {
          return (
            value?.slots?.filter((slot) => {
              return [slot?.startTime.length, slot?.endTime.length].includes(0);
            })?.length > 0
          );
        })?.length === 0
    );
  }, [blockPricingDetails]);

  useEffect(() => {});

 

  const addMoreDays = (type) => {
  
    const tempObj = Object.assign({}, blockPricingDetails, {
      ...blockPricingDetails,
      pricing_options: blockPricingDetails?.['pricing_options'].map((value) => {
        if (type === 'Weekdays') {
          value[BLOCK_OPTION]['pricingDetails']['days'] = [
            ...value[BLOCK_OPTION]['pricingDetails']['days'],
            {
              type: 'Monday',
              slots: [{ startTime: '8:30 am', endTime: '5:30 pm' }],
              // key:'Weekday',
            
             
            },
            {
              type: 'Tuesday',
              slots: [{ startTime: '8:30 am', endTime: '5:30 pm' }],
              // key:'Weekday'
             
            },
            {
              type: 'Wednesday',
              slots: [{ startTime: '8:30 am', endTime: '5:30 pm' }],
              // key:'Weekday'
             
            },
            {
              type: 'Thursday',
              slots: [{ startTime: '8:30 am', endTime: '5:30 pm' }],
              // key:'Weekday'
            
            },
            {
              type: 'Friday',
              slots: [{ startTime: '8:30 am', endTime: '5:30 pm' }],
              // key:'Weekday'
              
            },
          ];
          return value;
        } else {
          value[BLOCK_OPTION]['pricingDetails']['days'] = [
            ...value[BLOCK_OPTION]['pricingDetails']['days'],
            {
              type: type,
              slots: [{ startTime: '', endTime: '' }],
            },
          ];
          return value;
        }
      }),
    });
    setProfileData(tempObj);
    setBlockPricingDetails(tempObj);
  };
  const removeDays = (type) => {
  let dayArray = blockPricingDetails?.['pricing_options']?.[0]?.[BLOCK_OPTION]?.['pricingDetails']?.days;
    const tempObj = Object.assign({}, blockPricingDetails, {
      ...blockPricingDetails,
      pricing_options: blockPricingDetails?.['pricing_options'].map((value) => {
        value[BLOCK_OPTION]['pricingDetails']['days'] = value?.[BLOCK_OPTION]?.['pricingDetails']?.['days']?.filter((value) => {
          if(type === "Weekdays" && !value?.key){
            dayArray.length = 0;
          }else{
            return value?.type !== type;
          }
          
        });
        return value;
      }),
    });

    setProfileData(tempObj);
    setBlockPricingDetails(tempObj);

  
  };

  const addSlot = (dayIndex) => {
    const tempObj = Object.assign({}, blockPricingDetails, {
      ...blockPricingDetails,
      pricing_options: blockPricingDetails?.['pricing_options'].map((value) => {
        value[BLOCK_OPTION]['pricingDetails']['days'][dayIndex]['slots'] = [
          ...value[BLOCK_OPTION]['pricingDetails']['days'][dayIndex]['slots'],
          {
            startTime: '',
            endTime: '',
          },
        ];
        return value;
      }),
    });
    setProfileData(tempObj);
    setBlockPricingDetails(tempObj);
  };

  const removeSlot = (dayIndex, slotIndex) => {
    const tempObj = Object.assign({}, blockPricingDetails, {
      ...blockPricingDetails,
      pricing_options: blockPricingDetails?.['pricing_options'].map((value) => {
        value[BLOCK_OPTION]['pricingDetails']['days'] = value[BLOCK_OPTION]['pricingDetails']['days'].map((daysElement, dayElementIndex) => {
          if (dayElementIndex === dayIndex) {
            return {
              type: daysElement.type,
              slots: daysElement?.slots.filter((slotElement, slotElementIndex) => slotElementIndex !== slotIndex),
            };
          } else {
            return daysElement;
          }
        });
        return value;
      }),
    });
    setProfileData(tempObj);
    setBlockPricingDetails(tempObj);
  };

  const removeDay = (removableDate, type) => {
    type.map((val) => {
      if (
        val === 'Weekdays' &&
        (removableDate === 'Monday' ||
          removableDate === 'Tuesday' ||
          removableDate === 'Wednesday' ||
          removableDate === 'Thursday' ||
          removableDate === 'Friday')
      ) {
      
        weekday.push(removableDate);
        const remainArray = weekday?.filter((value) => value !== removableDate);
        const blockData = blockPricingDetails?.['pricing_options']?.[0]?.[BLOCK_OPTION]?.['pricingDetails']?.days;
        blockData.map((val) => {
          val['key'] = 'Weekdays';
        });
        let differentValue = weekday.filter((value) => !remainArray.includes(value));
        for (let x = 0; x < differentValue?.length; x++) {
          removeDays(differentValue[x], val);
        }
      } else {
        updateDateType(lastDayArray?.filter((value) => value !== removableDate, removableDate));
      }
    });
  };

  const updateDateType = (selectedArray) => {
    if (selectedArray) {
      if (lastDayArray.length < selectedArray.length) {
        let differentValue = selectedArray.filter((value) => !lastDayArray.includes(value));
        for (let x = 0; x < differentValue?.length; x++) {
          addMoreDays(differentValue[x]);
        }
      } else {
       
        let differentValue = lastDayArray.filter((value) => !selectedArray.includes(value));
       
        for (let x = 0; x < differentValue?.length; x++) {
          removeDays(differentValue[x]);
        }
      
      }
      setLastDayArray(selectedArray);
      const tempObj = Object.assign({}, blockPricingDetails, {
        ...blockPricingDetails,
        pricing_options: blockPricingDetails?.['pricing_options'].map((value) => {
          value[BLOCK_OPTION]['pricingDetails']['dayTypes'] = selectedArray;
          return value;
        }),
      });
      setProfileData(tempObj);
      setBlockPricingDetails(tempObj);
    }
  };

  const handleTimeChange = (newTime, type, dayIndex, slotIndex) => {
    const tempObj = Object.assign({}, blockPricingDetails, {
      ...blockPricingDetails,
      pricing_options: blockPricingDetails?.[PRICING_OPTIONS].map((value) => {
        value[BLOCK_OPTION]['pricingDetails']['days'] = value[BLOCK_OPTION]['pricingDetails']['days'].map((value, index) => {
          if (dayIndex === index) {
            value.slots[slotIndex][type] = newTime;
          }
          return value;
        });
        return value;
      }),
    });
    setProfileData(tempObj);
    setBlockPricingDetails(tempObj);
    // }
  };

  const getBlockValue = () => {
    return blockPricingDetails?.[PRICING_OPTIONS]?.[0]?.[BLOCK_OPTION]?.['pricingDetails'];
  };

  const handleTimeFormatting = (step, time) => {
    return step === 30
      ? time
        ? moment(time, TIME_FORMAT).add(30, 'minutes').format(TIME_FORMAT)
        : '8:00 am'
      : time
      ? moment(time, TIME_FORMAT).add(1, 'hour').format(TIME_FORMAT)
      : '8:00 am';
  };

  const handlePricingDetails = (type, price, onlyNumbers = false) => {
    if (onlyNumbers) {
      price = price.replace(/^0+/, '');
    }
    if (onlyNumbers && !/^[0-9.]{0,60}$/i.test(price) && price !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {
      const tempObj = Object.assign({}, blockPricingDetails, {
        ...blockPricingDetails,
        pricing_options: blockPricingDetails?.['pricing_options'].map((value, index) => {
          value[BLOCK_OPTION]['pricingDetails'][type] = price;
          return value;
        }),
      });
      setProfileData(tempObj);
      setBlockPricingDetails(tempObj);
    }
  };

  const getBlockComponents = () => {
    return getBlockValue()?.days?.map((value, dayIndex) => (
      <div className="content-wrapper sm-input-height">
        <div className="card-wrapper">
          <div className="card-border">
            <div className="title-btn-wrapper">
              <span className="title">{value?.type}</span>
              <span className="remove-btn" onClick={() => removeDay(value?.type, getBlockValue().dayTypes)}>
                X
              </span>
            </div>

            {value?.slots.map((slot, slotIndex) => (
              <div className="card">
                {console.log('slot', slot?.startTime)}
                <div className="pickers-wrapper">
                  <TimePicker
                    label={'Start Time'}
                    className="input-margin-wrapper"
                    id="startTime"
                    endLimit={'11:00pm'}
                    value={slot?.startTime}
                    step={30}
                    onChange={(newTime) => handleTimeChange(newTime, 'startTime', dayIndex, slotIndex)}
                  />
                  <TimePicker
                    label={'End Time'}
                    className="input-margin-wrapper end-time"
                    id="endTime"
                    value={slot?.endTime}
                    step={30}
                    beginLimit={handleTimeFormatting(60, slot?.startTime)}
                    endLimit={'11:00pm'}
                    onChange={(newTime) => handleTimeChange(newTime, 'endTime', dayIndex, slotIndex)}
                  />
                  <div className="minus-btn" onClick={() => removeSlot(dayIndex, slotIndex)}>
                    <img className="icon" src={removeIcon} />
                  </div>
                </div>
              </div>
            ))}

            <div className="link-text-wrapper">
              <span className="link-text" onClick={() => addSlot(dayIndex)}>
                + Add More Slots
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="left-side-inner-wrapper availability-detais-wrapper">
        <div className="pricing-option-label">
          <img
            className="icon"
            src={
              IMAGES_BASE_URL +
              getProfileData()?.seating_and_common?.[0]?.pricingOptionRaw?.filter((value) => value?.name?.toLowerCase() === pricingOption)?.[0]?.icon
            }
            alt={'Option Icon'}
          />
          <div className="title">Block pricing</div>
        </div>
        <h1 className="listing-page-title">Block pricing & availability details</h1>
        <div className="content-wrapper sm-input-height">
          {/* <p className="gray-text">Please set the price per block</p> */}
          <TextField
            className="input-margin-wrapper"
            label={`Price (${getProfileData()?.tax_information?.[0]?.currency})`}
            lineDirection="center"
            value={getBlockValue()?.price}
            onChange={(price) => handlePricingDetails('price', price, true)}
          />
        </div>
        <div className="content-wrapper sm-input-height">
          <p className="gray-text">Please provide the days and times that your space can be accessed:</p>
          <Select
            className={`input-margin-wrapper ${getBlockValue()?.dayTypes?.length > 0 ? 'placeHolderTop' : ''}`}
            dropdownClassName="space-listing-drop-down"
            mode="multiple"
            showArrow="true"
            allowClear
            placeholder="Available On"
            onChange={(value) => updateDateType(value)}
            value={getBlockValue()?.dayTypes}
          >
            {dailyPricingOptionValidation(dateArray, getBlockValue()?.dayTypes)?.map((value) => (
              <Option value={value}>{value}</Option>
            ))}
          </Select>
        </div>

        {getBlockComponents()}
      </div>

      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default BlockPricingAndAvailabilityDetails;

export const FETCH_TYPES = 'FETCH_TYPES';
export const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS';
export const FETCH_TYPES_FAILS = 'FETCH_TYPES_FAILS';


export const FETCH_COUNTRY_WISE_EVENT_TYPE = 'FETCH_COUNTRY_WISE_EVENT_TYPE';
export const FETCH_COUNTRY_WISE_EVENT_TYPE_SUCCESS = 'FETCH_COUNTRY_WISE_EVENT_TYPE_SUCCESS';
export const FETCH_COUNTRY_WISE_EVENT_TYPE_FAILS = 'FETCH_COUNTRY_WISE_EVENT_TYPE_FAILS';

export const FETCH_SL_TAX_DETAILS = 'FETCH_SL_TAX_DETAILS';
export const FETCH_SL_TAX_DETAILS_SUCCESS = 'FETCH_SL_TAX_DETAILS_SUCCESS';
export const FETCH_SL_TAX_DETAILS_FAILS = 'FETCH_SL_TAX_DETAILS_FAILS';

export const FETCH_LOCATION_STREET_NAME = 'FETCH_LOCATION_STREET_NAME';
export const FETCH_LOCATION_STREET_NAME_SUCCESS = 'FETCH_LOCATION_STREET_NAME_SUCCESS';
export const FETCH_LOCATION_STREET_NAME_FAILS = 'FETCH_LOCATION_STREET_NAME_FAILS';

export const FETCH_LOCATION_ADDRESS_LINE1 = 'FETCH_LOCATION_ADDRESS_LINE1';
export const FETCH_LOCATION_ADDRESS_LINE1_SUCCESS = 'FETCH_LOCATION_ADDRESS_LINE1_SUCCESS';
export const FETCH_LOCATION_ADDRESS_LINE1_FAILS = 'FETCH_LOCATION_ADDRESS_LINE1_FAILS';

export const FETCH_LOCATION_ADDRESS_LINE2 = 'FETCH_LOCATION_ADDRESS_LINE2';
export const FETCH_LOCATION_ADDRESS_LINE2_SUCCESS = 'FETCH_LOCATION_ADDRESS_LINE2_SUCCESS';
export const FETCH_LOCATION_ADDRESS_LINE2_FAILS = 'FETCH_LOCATION_ADDRESS_LINE2_FAILS';

export const FETCH_LOCATION_ADDRESS_LINE3 = 'FETCH_LOCATION_ADDRESS_LINE3';
export const FETCH_LOCATION_ADDRESS_LINE3_SUCCESS = 'FETCH_LOCATION_ADDRESS_LINE3_SUCCESS';
export const FETCH_LOCATION_ADDRESS_LINE3_FAILS = 'FETCH_LOCATION_ADDRESS_LINE3_FAILS';

export const FETCH_SG_TAX_DETAILS = 'FETCH_SG_TAX_DETAILS';
export const FETCH_SG_TAX_DETAILS_SUCCESS = 'FETCH_SG_TAX_DETAILS_SUCCESS';
export const FETCH_SG_TAX_DETAILS_FAILS = 'FETCH_SG_TAX_DETAILS_FAILS';

export const FETCH_PRICING_OPTIONS = 'FETCH_PRICING_OPTIONS';
export const FETCH_PRICING_OPTIONS_SUCCESS = 'FETCH_PRICING_OPTIONS_SUCCESS';
export const FETCH_PRICING_OPTIONS_FAILS = 'FETCH_PRICING_OPTIONS_FAILS';

export const FETCH_PRICING_OPTIONS_DAYS_ARRAY = 'FETCH_PRICING_OPTIONS_DAYS_ARRAY';
export const FETCH_PRICING_OPTIONS_DAYS_ARRAY_SUCCESS = 'FETCH_PRICING_OPTIONS_DAYS_ARRAY_SUCCESS';
export const FETCH_PRICING_OPTIONS_DAYS_ARRAY_FAILS = 'FETCH_PRICING_OPTIONS_DAYS_ARRAY_FAILS';

export const FETCH_CANCELLATION_POLICY = 'FETCH_CANCELLATION_POLICY';
export const FETCH_CANCELLATION_POLICY_SUCCESS = 'FETCH_CANCELLATION_POLICY_SUCCESS';
export const FETCH_CANCELLATION_POLICY_FAILS = 'FETCH_CANCELLATION_POLICY_FAILS';

export const FETCH_AMENITIES = 'FETCH_AMENITIES';
export const FETCH_AMENITIES_SUCCESS = 'FETCH_AMENITIES_SUCCESS';
export const FETCH_AMENITIES_FAILS = 'FETCH_AMENITIES_FAILS';

export const FETCH_ADDONS = 'FETCH_ADDONS';
export const FETCH_ADDONS_SUCCESS = 'FETCH_ADDONS_SUCCESS';
export const FETCH_ADDONS_FAILS = 'FETCH_ADDONS_FAILS';

export const FETCH_RULES = 'FETCH_RULES';
export const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS';
export const FETCH_RULES_FAILS = 'FETCH_RULES_FAILS';

export const FETCH_MRT_STATIONS = 'FETCH_MRT_STATIONS';
export const FETCH_MRT_STATIONS_SUCCESS = 'FETCH_MRT_STATIONS_SUCCESS';
export const FETCH_MRT_STATIONS_FAILS = 'FETCH_MRT_STATIONS_FAILS';

export const FETCH_PACKAGE = 'FETCH_PACKAGE';
export const FETCH_PACKAGE_SUCCESS = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILS = 'FETCH_PACKAGE_FAILS';

export const FETCH_DATA_OBJECT = 'FETCH_DATA_OBJECT';
export const FETCH_DATA_OBJECT_SUCCESS = 'FETCH_DATA_OBJECT_SUCCESS';
export const FETCH_DATA_OBJECT_FAILS = 'FETCH_DATA_OBJECT_FAILS';

export const FETCH_SEATING_ARRANGEMENT = 'FETCH_SEATING_ARRANGEMENT';
export const FETCH_SEATING_ARRANGEMENT_SUCCESS = 'FETCH_SEATING_ARRANGEMENT_SUCCESS';
export const FETCH_SEATING_ARRANGEMENT_FAILS = 'FETCH_SEATING_ARRANGEMENT_FAILS';

export const FETCH_SAVINGS = 'FETCH_SAVINGS';
export const FETCH_SAVINGS_SUCCESS = 'FETCH_SAVINGS_SUCCESS';
export const FETCH_SAVINGS_FAILS = 'FETCH_SAVINGS_FAILS';

export const FETCH_DATA_SUBMIT = 'FETCH_DATA_SUBMIT';
export const FETCH_DATA_SUBMIT_SUCCESS = 'FETCH_DATA_SUBMIT_SUCCESS';
export const FETCH_DATA_SUBMIT_FAILS = 'FETCH_DATA_SUBMIT_FAILS';
export const CLEAR_DATA_SUBMIT = 'CLEAR_DATA_SUBMIT';

export const FETCH_CONVERT_TO_HOST = 'FETCH_CONVERT_TO_HOST';
export const FETCH_CONVERT_TO_HOST_SUCCESS = 'FETCH_CONVERT_TO_HOST_SUCCESS';
export const FETCH_CONVERT_TO_HOST_FAILS = 'FETCH_CONVERT_TO_HOST_FAILS';
export const CLEAR_CONVERT_TO_HOST = 'CLEAR_CONVERT_TO_HOST';

export const SET_TOKEN_UPDATE_STATUS = 'SET_TOKEN_UPDATE_STATUS';
export const REMOVE_TOKEN_UPDATE_STATUS = 'REMOVE_TOKEN_UPDATE_STATUS';

export const MAP_PIN_UPDATED = 'MAP_PIN_UPDATED';
export const MAP_ZOOM_UPDATED = 'MAP_ZOOM_UPDATED';

export const GET_LAT_LONG_BY_ADDRESS_SUCCESS = 'GET_LAT_LONG_BY_ADDRESS_SUCCESS';
export const GET_LAT_LONG_BY_ADDRESS_FAILS = 'GET_LAT_LONG_BY_ADDRESS_FAILS';

export const GET_NEAREST_TRAIN_STATION_BY_LAT_LNG='GET_NEAREST_TRAIN_STATION_BY_LAT_LNG'
export const GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_SUCCESS ='GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_SUCCESS';
export const GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_FAILS ='GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_FAILS';

export const SYNC_WITH_CALENDAR='SYNC_WITH_CALENDAR';
export const SYNC_WITH_CALENDAR_SUCCESS='SYNC_WITH_CALENDAR_SUCCESS';
export const SYNC_WITH_CALENDAR_FAILS='SYNC_WITH_CALENDAR_FAILS';

export const GET_GOOGLE_TOKEN_DETAILS_SUCCESS='GET_GOOGLE_TOKEN_DETAILS_SUCCESS';
export const GET_GOOGLE_TOKEN_DETAILS_FAILS='GET_GOOGLE_TOKEN_DETAILS_FAILS';

export const GET_EMAIL_SUCCESS='GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAILS='GET_EMAIL_FAILS';

export const CALENDAR_AUTH_DATA='CALENDAR_AUTH_DATA';
export const OUTLOOK_STATUS='OUTLOOK_STATUS';



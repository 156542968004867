import {
  FETCH_HOME_PAGE_DATA,
  FETCH_HOME_PAGE_DATA_SUCCESS,
  FETCH_HOME_PAGE_DATA_FAILS,
  FETCH_SEARCH_ITEMS,
  FETCH_SEARCH_ITEMS_SUCCESS,
  FETCH_SEARCH_ITEMS_FAILS,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILS,
  POST_VENUE_VALUE,
  SUBMIT_SUBSCRIBER_DETAILS,
  SUBMIT_SUBSCRIBER_DETAILS_SUCCESS,
  SUBMIT_SUBSCRIBER_DETAILS_FAILS,
  CLEAR_SUBSCRIBER_DETAILS,
  CLEAR_SUBSCRIBER_DETAILS_SUCCESS,
  FETCH_PLACE_LAT_LONG,
  FETCH_PLACE_LAT_LONG_SUCCESS,
  FETCH_PLACE_LAT_LONG_FAILS,
  FETCH_SEARCH_AUTO_COMPLETE_DATA,
  FETCH_SEARCH_AUTO_COMPLETE_DATA_SUCCESS,
  FETCH_SEARCH_AUTO_COMPLETE_DATA_FAILS,
  UPDATE_COUNTRY_LONG_CODE,
  CLEAR_SEARCH_AUTO_COMPLETE_DATA,
  FETCH_META_DATA_SUCCESS,
  FETCH_META_DATA_FAILS,
  FETCH_OBJECT_HOME_OBJ_DATA,
  FETCH_OBJECT_HOME_OBJ_DATA_SUCCESS,
  FETCH_OBJECT_HOME_OBJ_DATA_FAILS,
} from '../../redux/actionTypes';
import { updateHomeDataObject, updateHomePageDataObj } from '../SpaceListing/Common/Common';
import {
  FETCH_PROMO_CODE_UPDATE,
  FETCH_PROMO_CODE_UPDATE_FAILS,
  FETCH_PROMO_CODE_UPDATE_SUCCESS,
  PROMO_CODE_UPDATE,
  PROMO_CODE_UPDATE_FAILS,
  PROMO_CODE_UPDATE_SUCCESS,
  GET_CITY,
  GET_CITY_FAILS
} from './HomeConstant';

export const spaceReducer = (
  state = {
    fetching: false,
    error: { status: false, message: '' },
    data: [],
    search: {
      activity: null,
      venue: null,
      address: null,
      eventType: '',
      spaceCategory: '',
    },
  },
  action
) => {
  switch (action.type) {
    case POST_VENUE_VALUE:
      return {
        ...state,
        search: {
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };

    case FETCH_SEARCH_ITEMS:
      return Object.assign({}, state, { fetching: true });

    case FETCH_SEARCH_ITEMS_SUCCESS:
      return {
        ...state,
        fetching: false,
        searchItems: {
          data: action.payload,
        },
      };

    case FETCH_SEARCH_ITEMS_FAILS:
      return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: '' } });

    case FETCH_LOCATION:
      return Object.assign({}, state, { fetching: true });

    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        location: {
          data: action.payload,
        },
      };
    case FETCH_LOCATION_FAILS:
      return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: '' } });

    case FETCH_HOME_PAGE_DATA:
      return Object.assign({}, state, { fetching: true });

    case FETCH_HOME_PAGE_DATA_SUCCESS:
      //Training activity removed temporarily
      action.payload.spaceActivities = action?.payload?.spaceActivities?.filter((category) => category.name !== 'Train') || [];
      return {
        ...state,
        homepage: {
          ...state.homePageData,
          fetching: false,
          data: action.payload,
        },
      };

    case FETCH_HOME_PAGE_DATA_FAILS:
      return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: '' } });

    case FETCH_OBJECT_HOME_OBJ_DATA:
      return Object.assign({}, state, { fetching: true });

    case FETCH_OBJECT_HOME_OBJ_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        homePageData: updateHomeDataObject(state, action),
      };

    case FETCH_OBJECT_HOME_OBJ_DATA_FAILS:
      return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: '' } });

    case SUBMIT_SUBSCRIBER_DETAILS:
      return {
        ...state,
        subscriberDetails: {
          ...state.subscriberDetails,
          fetching: true,
          subscriberDetails_error: null,
          subscriberDetails: null,
          success: false,
        },
      };
    case SUBMIT_SUBSCRIBER_DETAILS_SUCCESS:
      return {
        ...state,
        subscriberDetails: {
          ...state.subscriberDetails,
          fetching: false,
          subscriberDetails_error: null,
          subscriberDetails: action.payload,
          success: true,
        },
      };
    case SUBMIT_SUBSCRIBER_DETAILS_FAILS:
      return {
        ...state,
        subscriberDetails: {
          ...state.subscriberDetails,
          fetching: false,
          subscriberDetails_error: action.payload.response,
          subscriberDetails: null,
          success: false,
        },
      };

    case FETCH_PLACE_LAT_LONG:
      return {
        ...state,
        placeLatLng: {
          fetching: true,
          error: { status: false, message: '' },
          data: null,
        },
      };
    case FETCH_PLACE_LAT_LONG_SUCCESS:
      return {
        ...state,
        placeLatLng: {
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_PLACE_LAT_LONG_FAILS:
      return {
        ...state,
        placeLatLng: {
          fetching: false,
          error: { status: false, message: action.payload?.response?.message },
          data: null,
        },
      };

    case PROMO_CODE_UPDATE:
      return {
        ...state,
        promoCodeUpdateRes: {
          fetching: true,
          error: { status: false, message: '' },
          data: null,
        },
      };
    case PROMO_CODE_UPDATE_SUCCESS:
      return {
        ...state,
        promoCodeUpdateRes: {
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case PROMO_CODE_UPDATE_FAILS:
      return {
        ...state,
        promoCodeUpdateRes: {
          fetching: false,
          error: { status: false, message: action.payload?.response?.message },
          data: null,
        },
      };

    case FETCH_PROMO_CODE_UPDATE:
      return {
        ...state,
        promoCode: {
          fetching: true,
          error: { status: false, message: '' },
          data: null,
        },
      };
    case FETCH_PROMO_CODE_UPDATE_SUCCESS:
      return {
        ...state,
        promoCode: {
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_PROMO_CODE_UPDATE_FAILS:
      return {
        ...state,
        promoCode: {
          fetching: false,
          error: { status: false, message: action.payload?.response?.message },
          data: null,
        },
      };

    case CLEAR_SUBSCRIBER_DETAILS:
      return {
        ...state,
        subscriberDetails: {
          ...state.subscriberDetails,
          fetching: false,
          subscriberDetails_error: null,
          subscriberDetails: null,
          success: false,
        },
      };
    case CLEAR_SUBSCRIBER_DETAILS_SUCCESS:
      return {
        ...state,
        subscriberDetails: {
          ...state.subscriberDetails,
          fetching: false,
          subscriberDetails_error: null,
          subscriberDetails: null,
          success: false,
        },
      };

    case FETCH_SEARCH_AUTO_COMPLETE_DATA:
      return {
        ...state,
        autocompleteSuggestions: {
          ...state.autocompleteSuggestions,
          fetching: true,
          error: { status: false, message: '' },
        },
      };
    case FETCH_SEARCH_AUTO_COMPLETE_DATA_SUCCESS:
      return {
        ...state,
        autocompleteSuggestions: {
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_SEARCH_AUTO_COMPLETE_DATA_FAILS:
      return {
        ...state,
        autocompleteSuggestions: {
          ...state.autocompleteSuggestions,
          fetching: false,
          error: { status: false, message: action.payload?.response?.message },
        },
      };
    case CLEAR_SEARCH_AUTO_COMPLETE_DATA:
      return {
        ...state,
        autocompleteSuggestions: {
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_META_DATA_FAILS:
      return {
        ...state,
        error: { status: false, message: '' },
        fetchingMeta: false,
      };
    case FETCH_META_DATA_SUCCESS:
      return {
        ...state,
        fetchingMeta: true,
        data: action.payload,
      };
    case UPDATE_COUNTRY_LONG_CODE:
      return {
        ...state,
        searchCountry: action.payload,
      };
    case GET_CITY:
      return {
        ...state,
        city: action.payload,
      };

    default:
      return state;
  }
};

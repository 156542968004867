import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal} from 'antd';
import { checkUserAuthorization, fetchSpacesStatus, getCalenderList, getFilteredSpaces, setSelectedCalendar, unSyncGoogleCalendarFromHost } from './HostDashboardActions';
import decode from "jwt-decode";
import { Grid, Cell } from 'react-md';
import {
    Link, Switch,
    Route, useRouteMatch, Redirect
} from "react-router-dom";

import Spaces from './Spaces';
import { ADMIN, USER } from '../Common/constants';
import SpaceDetails from './SpaceDetails';
import { showErrorMessageCalendar, showSuccessMessageCalendar } from '../SpaceListing/Common/Common';
import info from "../../assets/images/calendarSync/info-sync.png"
const SpaceContainer = () => {
    const isGuest = (authDetails) => authDetails ? decode(authDetails)?.authorities?.[0] === USER : true;
    const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
    const authToken = useSelector(state => state?.auth?.loginDetails?.data?.token);
    const calendarAuthStatus = useSelector(state => state?.hostDashboard?.calendar?.authStatus || {})
    const [isUnlinkModalVisible, setIsUnlinkModalVisible] = useState(false);

    const { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    const status = ['ALL', 'ACTIVE', 'PENDING', 'INACTIVE', 'DECLINED'];

    const [visibleComingSoon, setVisibleComingSoon] = useState(false);
    const [userType, setUserType] = useState(null);
    const [statusAllSelected, setStatusAllSelected] = useState(true);
    const [bookingStatus, setBookingStatus] = useState('');
    const [clickedStatus, setClickedStatus] = useState('ALL');
    const [pageCount, setPageCount] = useState(1);
    const [divFocus, setDivFocus] = useState(false);
    
    const [scrolledPosition, setScrolledPosition] = useState(0);
    const [isBackClicked, handleBackClicked] = useState(false)
    const pageSize = 10;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': authToken
    }

    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(getFilteredSpaces({ page: 1, size: pageSize }, headers))
        dispatch(fetchSpacesStatus());
        dispatch(checkUserAuthorization())
    }, []);

    useEffect(()=>{
        if(calendarAuthStatus?.status){
        dispatch(getCalenderList())
    }
    },[calendarAuthStatus?.status])

    const handleStatusSelection = (status) => {
        if (status) {
            setPageCount(value => 1);
            dispatch(getFilteredSpaces({ page: 1, size: pageSize, status: status }, headers));
        }
        else
            dispatch(getFilteredSpaces({ page: 1, size: pageSize }, headers));
    };
    const showUnlinkModal = () => {
        setIsUnlinkModalVisible(true);
      };
    
      const handleSaveOk = () => {
        dispatch(unSyncGoogleCalendarFromHost((success)=>{
            if(success){
                dispatch(setSelectedCalendar(null))
                setIsUnlinkModalVisible(false);
                dispatch(checkUserAuthorization())
                showSuccessMessageCalendar('Calendar unlinked successfully!')
            }else{
                showErrorMessageCalendar('Calendar unlinked UnSuccessfully!')
            }
        }))
      };
    
      const handleSaveCancel = () => {
        setIsUnlinkModalVisible(false);
      };

    return (
      <div>
        <div className={divFocus && 'space-main-wrap'} onClick={() => setDivFocus(false)}></div>
        <div className={`my-space host-dashboard ${isGuest(authDetails) ? 'guest-dashboard' : ''}`}>
          <div className="header">
            <div className="container custom-container flex-wrap">
              <h2 className="title">{userType === ADMIN ? '' : 'My '}Spaces</h2>
              <button className="btn-teal-outline btn-list" onClick={() => setVisibleComingSoon(true)}>
                List a space
              </button>
              {calendarAuthStatus?.status && (
                <button className="unlink-btn" onClick={showUnlinkModal}>
                  UNLINK CALENDAR
                </button>
              )}
            </div>
          </div>
          <div className="content">
            <div className="container custom-container">
              <Grid className="space-grid">
                <Cell size={4} tabletSize={2} phoneHidden className="spacy-left">
                  <div className="list">
                    {statusAllSelected ? (
                      <>
                        {status.map((status, index) => (
                          <Link to={`${url}/${status.toLowerCase()}`}>
                            <span
                              id={status}
                              key={index}
                              className={`list-items ${clickedStatus === status ? 'active' : ''}`}
                              hidden={status === 'REQUESTED'}
                              onClick={(e) => {
                                setClickedStatus(status);
                                setBookingStatus(status !== 'ALL' ? status : '');
                                handleStatusSelection(status !== 'ALL' ? status : '');
                              }}
                            >
                              {status}
                            </span>
                          </Link>
                        ))}
                      </>
                    ) : (
                      <span className="list-items" onClick={() => handleStatusSelection()}>
                        All
                      </span>
                    )}
                  </div>
                </Cell>
                <Cell size={8} tabletSize={6} phoneSize={12} className="spacy-right">
                  <Switch>
                    <Route path={`${path}/:status/:spaceId`}>
                      <SpaceDetails handleBackClicked={handleBackClicked} />
                    </Route>
                    <Route path={`${path}/:status`}>
                      <Spaces
                        divFocus={divFocus}
                        setDivFocus={setDivFocus}
                        bookingStatus={bookingStatus}
                        setClickedStatus={setClickedStatus}
                        scrolledPosition={scrolledPosition}
                        setScrolledPosition={setScrolledPosition}
                        handleBackClicked={handleBackClicked}
                        isBackClicked={isBackClicked}
                      />
                    </Route>
                    <Route path={`*`}>
                      <Redirect to={`${url}/all`} />
                    </Route>
                  </Switch>
                </Cell>
              </Grid>
            </div>
          </div>
        </div>
        <Modal
          title="Unlink Calendar?"
          wrapClassName="custom-ant-space-modal-assign custom-ant-space-modal-assign-unlink"
          visible={isUnlinkModalVisible}
          onOk={handleSaveOk}
          onCancel={handleSaveCancel}
          okText="UNLINK"
          cancelText="CANCEL"
          centered
          // okButtonProps={{ className: 'custom-ok-button' }}
          cancelButtonProps={{ className: 'custom-cancel-button-assign' }}
        >
            
           
            <p className="modal-text-save-head custom-modal-text unlink-head">
            {' '}
            Are you sure want to unlink your Google Calendar from Millionspaces? 
          </p>
          
         
          <div className='green-content'>
            <div className='icon-conetnt'>
            <img src={info} alt="img" className="info-green" />
            <p className='unlinking-text-text'>By unlinking your Google Calendar:</p>
            </div>
         
          <ul>
            <li className='unlink-points'>Event synchronization between all the spaces and your Google Calendars will stop.</li>
            <li className='unlink-points'>Existing events in your Google Calendar will remain unchanged.</li>
            <li className='unlink-points'>You will need to resync from scratch if you wish to connect the calendars again.</li>
          </ul>
          </div>
          
        </Modal>
      </div>
    );
};

export default SpaceContainer;
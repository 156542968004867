import { googleAutoCompleteApi } from '../../api/googleAutoCompleteApi';
import { getDetailsFromPlaceID } from '../../api/getDetailsFromPlaceID';
import {
  FETCH_HOME_PAGE_DATA,
  FETCH_HOME_PAGE_DATA_SUCCESS,
  FETCH_HOME_PAGE_DATA_FAILS,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILS,
  POST_VENUE_VALUE,
  SUBMIT_SUBSCRIBER_DETAILS,
  SUBMIT_SUBSCRIBER_DETAILS_SUCCESS,
  SUBMIT_SUBSCRIBER_DETAILS_FAILS,
  FETCH_PLACE_LAT_LONG,
  FETCH_PLACE_LAT_LONG_SUCCESS,
  FETCH_PLACE_LAT_LONG_FAILS,
  FETCH_SEARCH_AUTO_COMPLETE_DATA,
  FETCH_SEARCH_AUTO_COMPLETE_DATA_SUCCESS,
  FETCH_SEARCH_AUTO_COMPLETE_DATA_FAILS,
  CLEAR_SUBSCRIBER_DETAILS,
  CLEAR_SUBSCRIBER_DETAILS_SUCCESS,
  UPDATE_COUNTRY_LONG_CODE,
  CLEAR_SEARCH_AUTO_COMPLETE_DATA,
  FETCH_OBJECT_HOME_OBJ_DATA,
  FETCH_OBJECT_HOME_OBJ_DATA_SUCCESS,
  FETCH_OBJECT_HOME_OBJ_DATA_FAILS,



} from '../../redux/actionTypes';
import { get ,post, getLatLong } from '../../api/commonAPI';
import { home } from '../../api/resources';
import { googleMapAPIKey } from '../../settings';
import {
  FETCH_PROMO_CODE_UPDATE, FETCH_PROMO_CODE_UPDATE_FAILS,
  FETCH_PROMO_CODE_UPDATE_SUCCESS,
  PROMO_CODE_UPDATE,
  PROMO_CODE_UPDATE_FAILS,
  PROMO_CODE_UPDATE_SUCCESS,
  GET_CITY,
  GET_CITY_FAILS
} from './HomeConstant';
import { getLocationDetails } from '../../api/getLocationDetails';

export const getHomePageData = (countryShortCode) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: FETCH_HOME_PAGE_DATA
    });

    axiosInstance.get(`/ui/config/${countryShortCode}?code=HOME_CONFIG`)
      .then(res => {
        dispatch({
          type: FETCH_HOME_PAGE_DATA_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_HOME_PAGE_DATA_FAILS,
          payload: error
        });
      });
  };
};

export const getLocation = data => {
  return dispatch => {
    dispatch({
      type: FETCH_LOCATION_SUCCESS,
      payload: data
    });

};
}


export const postVenue = venue => {
  return {
    type: POST_VENUE_VALUE,
    payload: venue
  };
};

export const getCity=(lat,lng)=>{
  return (dispatch) => {
    getLocationDetails(lat,lng)
      .then((res) =>
     
        dispatch({
          type: GET_CITY,
          payload: res?.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: GET_CITY_FAILS,
          payload: error,
        })
      );
  };
}

export const postSubscriberDetails = (data, headers,url) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: SUBMIT_SUBSCRIBER_DETAILS,

    });
    axiosInstance.post(url.link, data,headers)
        .then(res => {
          dispatch({
            type: SUBMIT_SUBSCRIBER_DETAILS_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: SUBMIT_SUBSCRIBER_DETAILS_FAILS,
            payload: error
          });
        })
    ;

  }
}

export const clearSubscriberDetails = () => {
  return {
    type: CLEAR_SUBSCRIBER_DETAILS,

  };
};

export const clearNewsletteretails = () => {
  return{
    type:CLEAR_SUBSCRIBER_DETAILS_SUCCESS,

  }
}


export const  getData = (url, orderNumber) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: FETCH_OBJECT_HOME_OBJ_DATA
    });

    axiosInstance.get(url)
      .then(res => {
        dispatch({
          type: FETCH_OBJECT_HOME_OBJ_DATA_SUCCESS,
          payload: res.data,
          orderNumber: orderNumber
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_OBJECT_HOME_OBJ_DATA_FAILS,
          payload: error
        });
      });
  };
};

export const getPlacesLatLong = (data) => {
  return dispatch => {
    dispatch({
      type: FETCH_PLACE_LAT_LONG,
    });
    getLatLong(`?place_id=${data}&key=${googleMapAPIKey}`)
      .then(res => {
        dispatch({
          type: FETCH_PLACE_LAT_LONG_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_PLACE_LAT_LONG_FAILS,
          payload: error
        });
      });
  }
}


export const getAutocompleteSuggestions = (keyWord, country, eventType) => {
  return dispatch => {
    dispatch({
      type: FETCH_SEARCH_AUTO_COMPLETE_DATA,
    });

    let url = `search/auto-complete?keyWord=${keyWord}&country=${country}`;
    if(eventType){
      url = `search/auto-complete?keyWord=${keyWord}&country=${country}&eventType=${eventType}`;
    }
    get(url)
      .then(res => {
        dispatch({
          type: FETCH_SEARCH_AUTO_COMPLETE_DATA_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_SEARCH_AUTO_COMPLETE_DATA_FAILS,
          payload: error
        });
      });
  }
}


export const fetchPromoCodes = ( country) => {
  return dispatch => {
    dispatch({
      type: FETCH_PROMO_CODE_UPDATE,
    });
    get(`booking/promo-code-promotion?country=`+country)
      .then(res => {
        dispatch({
          type: FETCH_PROMO_CODE_UPDATE_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_PROMO_CODE_UPDATE_FAILS,
          payload: error
        });
      });
  }
}

export const addPromoCode = (email, country) => {
  return dispatch => {
    dispatch({
      type: PROMO_CODE_UPDATE,
    });
    post(`booking/promo-code-request`, {email: email, country: country})
      .then(res => {
        dispatch({
          type: PROMO_CODE_UPDATE_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: PROMO_CODE_UPDATE_FAILS,
          payload: error
        });
      });
  }
}

export const clearAutocompleteSuggestions = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SEARCH_AUTO_COMPLETE_DATA,
      payload: [],
    });
  }
};

export const updateCountryCode = (result) => {
  return dispatch => {
      dispatch({
          type: UPDATE_COUNTRY_LONG_CODE,
          payload: result
      });
  }
}

import {googleMapAPIKey} from '../settings'
import axiosInstance from './axiosInstance';

export function getLocationDetails(lat, long) {
    try {
        const res = axiosInstance({
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleMapAPIKey}`,
            method: 'GET',
        });
        return res;
    } catch (e) {
        throw e;
    }
}



export async function getIPLocation() {

    try {
        const res = await axiosInstance({
            url: `https://www.googleapis.com/geolocation/v1/geolocate?key=${googleMapAPIKey}`,
            method: 'POST',
        });
        return res;
    } catch (e) {
        throw e;
    }
}

export async function getLocationByAddress(address) {
  try {
        const res = await axiosInstance({
            url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleMapAPIKey}`,
            method: 'GET',
        });
        return res;
    } catch (e) {
        throw e;
    }
}

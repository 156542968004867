import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-md';
import Select from 'antd/es/select';
import FlowHandlingButtons from '../../../Common/HandleNavigationAndSubmit';
import {
  DAILY_OPTION,
  DEFAULT_DATA_OBJ, ERROR_ONLY_NUMBERS,
  MONTHLY_OPTION,
  PRICING_OPTIONS, TAX_INFORMATION,
  WEEKLY_OPTION
} from '../../../SpaceListingConstants';
import { getProfileData, setProfileData } from '../../../Common/DataController';
import { spaceListingData } from '../../../Common/ProfileListingDataModal';
import { useParams } from 'react-router-dom';
import { showErrorMessage } from '../../../Common/Common';
import { contentIsNotNull } from '../../../Common/Validator';
import { commafy } from '../../../../../helper/Commafy';
import TimePicker from '../../../../Common/TimePicker/TimePicker';
import monthlyPricingIcon from '../../../../../assets/images/space-listing/monthlyPricing.svg';

const { Option } = Select;
const MonthlyPricingAvailablity = () => {
  const { country } = useParams();
  const [monthlyDetails, setMonthlyDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));


  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  useEffect(() => {
    setIsNextBtnActive(
        getBlockValue()?.fullTimeAccess || getBlockValue()?.startTime?.length>0 && getBlockValue()?.endTime?.length>0
    );
  }, [monthlyDetails]);

  const handleDataInput = (type, newValue, onlyNumbers = false) => {
    if(onlyNumbers){
      newValue = newValue.replace(/^0+/, '');
    }
    if (onlyNumbers && !(/^[0-9.]{0,60}$/i).test(newValue) && newValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {
      const tempObj = Object.assign({}, monthlyDetails, {
        ...monthlyDetails,
        pricing_options: monthlyDetails?.['pricing_options'].map((value) => {
          value[MONTHLY_OPTION]['availabilityDetails'][type] = newValue;
          return value;
        }),
      });
      setProfileData(tempObj)
      setMonthlyDetails(tempObj);
    }
  };


  const getBlockValue = () => {
    return monthlyDetails?.[PRICING_OPTIONS]?.[0]?.[MONTHLY_OPTION]?.['availabilityDetails'];
  };

  return (
    <>
      <div className="left-side-inner-wrapper">
        <div className="price-type"><img className="img" src={monthlyPricingIcon} alt="icon" />Monthly pricing</div>
        <h1 className="listing-page-title">Monthly availability details</h1>
        <div>
          <Select  className={`input-margin-wrapper pricing-options ${(getBlockValue()?.fullTimeAccess)?.toString()?.length>0 ? 'placeHolderTop' : ''}`} 
          placeholder={"Allow 24/7 access"} onSelect={(value) => handleDataInput('fullTimeAccess', value)} value={getBlockValue()?.fullTimeAccess}>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>

          {!getBlockValue()?.fullTimeAccess && (<div>
            <div className="monthly-text">Please provide the time that your space can be accessed:</div>

            <div className="date-wrapper">
              <TextField
                value={"Daily"}
                disabled={true}
                className="input-margin-wrapper availability-time"
                required
                errorText="This field is required."
              />

              <TimePicker
                value={getBlockValue()?.startTime}
                label={'Start Time'}
                className="input-margin-wrapper"
                id="startTime"
                endLimit={'11:00pm'}
                step={60}
                onChange={(newTime) => handleDataInput('startTime', newTime)}
              />
              <TimePicker
                value={getBlockValue()?.endTime}
                label={'End Time'}
                className="input-margin-wrapper end-time"
                id="endTime"
                step={60}
                endLimit={'11:00pm'}
                onChange={(newTime) => handleDataInput('endTime', newTime)}
              />
            </div>
          </div>)}

        </div>
      </div>

      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default MonthlyPricingAvailablity;

// Page List Content
export const DEFAULT_DATA_OBJ = 'organization_details';
export const WELCOME = 'welcome';
export const ORGANIZATION_DETAILS = 'organization_details';
export const SPACE_LOCATION = 'space_location';
export const SAVINGS = 'savings';
export const AVAILABILITY = 'availability';
export const PACKAGES = 'packages';
export const AMENITIES = 'amenities';
export const ADDONS = 'addons';
export const RULES = 'rules';
export const PAYOUT_DETAILS = 'payout_details';
export const COMMISSION = 'commission';
export const AGREEMENT = 'agreement';
export const CONGRATULATIONS = 'congratulations';

// Country Details.
export const SRI_LANKA = 'SRI_LANKA';
export const SINGAPORE = 'SINGAPORE';
export const AUSTRALIA = 'AUSTRALIA';
export const SINGAPORE_URL = 'Singapore';
export const SRI_LANKA_URL = 'Sri-Lanka';
export const AUSTRALIA_URL = 'Australia';

// Error Messages.
export const ERROR_CHAR_LIMIT = 'Character limit is exceeded!';
export const ERROR_SPECIAL_CHAR = 'No special characters are allowed!';
export const ERROR_WHITE_SPACE = 'White spaces are not allowed!';
export const ERROR_ONLY_NUMBERS = 'Only numbers allowed!';
export const ERROR_NO_NUMBERS_ALLOWED = 'Numbers are not allowed!';

// Variable constants.
export const AUTHORITIES = 'authorities';
export const EMAIL = "email";
export const COUNTRY = 'country';

// Common constants.
export const BACK_BTN = 'BACK';
export const BACK_TO_HOME = 'BACK TO HOME';
export const LETS_START = "LET'S START";

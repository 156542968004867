import React, { useCallback, useState } from 'react';
import { TextField } from 'react-md';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import Select from 'antd/es/select';
import { useDispatch } from 'react-redux';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { DEFAULT_DATA_OBJ } from '../SpaceListingConstants';
import { useParams } from 'react-router-dom';

const ExternalSystem = ({ currentBlock }) => {
  const { country } = useParams();
  const [externalSystemDetails, setExternalSystemDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));

  const handleIntegrationDetails = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, externalSystemDetails, {
      ...externalSystemDetails,
      processStarted: true,
      [currentBlock]: externalSystemDetails?.[currentBlock].map((value, index) => {
        value[name] = newValue;
        return value;
      }),
    });
    setExternalSystemDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const getFieldCurrentValue = (type) => {
    return externalSystemDetails?.[currentBlock]?.[0]?.[type];
  };

  return (
    <>
      <div className="left-side-inner-wrapper external-system-wrapper">
        <h1 className="listing-page-title">External system integrations</h1>
        <p>Do you operate an external system that is integrated with MillionSpaces?</p>
        <div className="btn-wrapper">
          <button className={`btn border-btn ${getFieldCurrentValue('status')?"active":"" }`} onClick={() => handleIntegrationDetails('status',true)}>Yes</button>
          <button className={`btn border-btn ${!getFieldCurrentValue('status')?"active":"" }`} onClick={() => handleIntegrationDetails('status',false)}>No</button>
        </div>
          {getFieldCurrentValue('status') && (
            <div className="input-wrapper">
              <hr className="divider"/>
              <Select
                className={`input-margin-wrapper ${getFieldCurrentValue('systemName')?.length > 0 ? 'placeHolderTop' : ''}`}
                dropdownClassName="space-listing-drop-down"
                placeholder="System name"
                value={undefined}
                onChange={(value) => handleIntegrationDetails('systemName', value)}
              >
              </Select>
              <TextField
                className="input-margin-wrapper"
                id="spaceReferenceId"
                label="Space reference ID"
                lineDirection="center"
                required
                errorText="This field is required."
                onChange={(value) => handleIntegrationDetails('spaceReferenceId', value)}
                value={getFieldCurrentValue('spaceReferenceId')}
              />
            </div>
          )}
      </div>
      <FlowHandlingButtons/>
    </>
  );
};

export default ExternalSystem;

import React, { useCallback, useEffect, useState } from 'react';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { TextField } from 'react-md';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import {
  AUSTRALIA,
  DEFAULT_DATA_OBJ,
  ERROR_CHAR_LIMIT,
  ERROR_NO_NUMBERS_ALLOWED,
  ERROR_ONLY_NUMBERS,
  ERROR_SPECIAL_CHAR,
  ERROR_WHITE_SPACE,
} from '../SpaceListingConstants';
import { contentIsNotNull, noSpecialCharIncluded } from '../Common/Validator';
import informationIcon from '../../../assets/images/space-listing/informationIcon.svg';
import { showErrorMessage } from '../Common/Common';
import { useParams } from 'react-router-dom';

const PayoutDetails = ({ currentBlock }) => {
  const { country } = useParams();
  const [payoutDetails, setPayoutDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const profileListingData = JSON.parse(sessionStorage.getItem('profileListingData'));

  useEffect(() => {
    profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA
      ? setIsNextBtnActive(
          contentIsNotNull(getFieldCurrentValue('name'), 5, 100) &&
            contentIsNotNull(getFieldCurrentValue('bank'), 5, 100) &&
            contentIsNotNull(getFieldCurrentValue('bsb'), 2, 100) &&
            contentIsNotNull(getFieldCurrentValue('accountNumber'), 2, 100)
        )
      : setIsNextBtnActive(
          contentIsNotNull(getFieldCurrentValue('name'), 5, 100) &&
            contentIsNotNull(getFieldCurrentValue('bank'), 5, 100) &&
            contentIsNotNull(getFieldCurrentValue('swiftCode'), 2, 100) &&
            contentIsNotNull(getFieldCurrentValue('bankCode'), 2, 100) &&
            contentIsNotNull(getFieldCurrentValue('branchCode'), 2, 100) &&
            contentIsNotNull(getFieldCurrentValue('accountNumber'), 2, 100)
        );
  }, [payoutDetails]);

  const handlePayoutDetails = useCallback(
    (name, newValue, max = 5000, noSpecialChar = false, noWhiteSpaces = false, onlyNumbers = false, noNumbersAllowed = false) => {
      if (newValue?.length > max) {
        showErrorMessage(ERROR_CHAR_LIMIT);
      } else if (noSpecialChar && !noSpecialCharIncluded(newValue.toString())) {
        showErrorMessage(ERROR_SPECIAL_CHAR);
      } else if (noWhiteSpaces && /\s/g.test(newValue)) {
        showErrorMessage(ERROR_WHITE_SPACE);
      } else if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== '') {
        showErrorMessage(ERROR_ONLY_NUMBERS);
      } else if (noNumbersAllowed && /[^a-zA-Z ]/g.test(newValue) && newValue !== '') {
        showErrorMessage(ERROR_NO_NUMBERS_ALLOWED);
      } else {
        const tempObj = Object.assign({}, payoutDetails, {
          ...payoutDetails,
          processStarted: true,
          [currentBlock]: payoutDetails?.[currentBlock].map((value) => {
            value[name] = newValue;
            return value;
          }),
        });
        setPayoutDetails(tempObj);
        setProfileData(tempObj);
      }
    },
    []
  );

  const getFieldCurrentValue = (type) => {
    return payoutDetails?.[currentBlock]?.[0]?.[type];
  };

  return (
    <>
      <div className="left-side-inner-wrapper">
        <h1 className="listing-page-title">Payout details</h1>
        <div className="icon-sentense-wrapper">
          <div className="icon-sentense-inner-wrapper">
            <img className="icon" src={informationIcon} alt={'Icon'} />
            <p className="green-text">MillionSpaces proceeds to the payout every 14 working days.</p>
          </div>
        </div>
        <TextField
          className="input-margin-wrapper"
          id="name"
          label="Name on account"
          lineDirection="center"
          required
          errorText="Field is required."
          value={getFieldCurrentValue('name')}
          onChange={(value) => handlePayoutDetails('name', value, 50, true, false, false, true)}
        />
        <TextField
          className="input-margin-wrapper"
          id="bank"
          label="Bank"
          lineDirection="center"
          required
          errorText="Field is required."
          value={getFieldCurrentValue('bank')}
          onChange={(value) => handlePayoutDetails('bank', value)}
        />
        {profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA ? (
          <TextField
            className="input-margin-wrapper"
            id="bsb"
            label="BSB"
            lineDirection="center"
            required
            errorText="Field is required."
            value={getFieldCurrentValue('bsb')}
            onChange={(value) => handlePayoutDetails('bsb', value)}
          />
        ) : (
          <>
            <TextField
              className="input-margin-wrapper"
              id="swiftCode"
              label="Swift Code"
              lineDirection="center"
              required
              errorText="Field is required."
              value={getFieldCurrentValue('swiftCode')}
              onChange={(value) => handlePayoutDetails('swiftCode', value, 30, false, true)}
            />
            <TextField
              className="input-margin-wrapper"
              id="bankCode"
              label="Bank Code"
              lineDirection="center"
              required
              errorText="Field is required."
              value={getFieldCurrentValue('bankCode')}
              onChange={(value) => handlePayoutDetails('bankCode', value, 30, false, true)}
            />
            <TextField
              className="input-margin-wrapper"
              id="branchCode"
              label="Branch Code"
              lineDirection="center"
              required
              errorText="Field is required."
              value={getFieldCurrentValue('branchCode')}
              onChange={(value) => handlePayoutDetails('branchCode', value, 30, false, true)}
            />
          </>
        )}
        <TextField
          className="input-margin-wrapper"
          id="accountNumber"
          label="Account Number"
          lineDirection="center"
          required
          errorText="Field is required."
          value={getFieldCurrentValue('accountNumber')}
          onChange={(value) => handlePayoutDetails('accountNumber', value, 50, false, true)}
        />
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default PayoutDetails;

import { get, post, put, put_listing } from '../../api/commonAPI';
import { getLocationByAddress } from '../../api/getLocationDetails';
import { get_tokens, getAuthorizedEmail } from '../../calendarEventsConfig';
import {
  FETCH_ADDONS,
  FETCH_ADDONS_FAILS,
  FETCH_ADDONS_SUCCESS,
  FETCH_AMENITIES,
  FETCH_AMENITIES_FAILS,
  FETCH_AMENITIES_SUCCESS,
  FETCH_CANCELLATION_POLICY,
  FETCH_CANCELLATION_POLICY_FAILS,
  FETCH_CANCELLATION_POLICY_SUCCESS,
  FETCH_CONVERT_TO_HOST,
  FETCH_CONVERT_TO_HOST_FAILS,
  FETCH_CONVERT_TO_HOST_SUCCESS,
  FETCH_COUNTRY_WISE_EVENT_TYPE,
  FETCH_COUNTRY_WISE_EVENT_TYPE_FAILS,
  FETCH_COUNTRY_WISE_EVENT_TYPE_SUCCESS,
  FETCH_DATA_OBJECT,
  FETCH_DATA_OBJECT_FAILS,
  FETCH_DATA_OBJECT_SUCCESS,
  FETCH_DATA_SUBMIT,
  FETCH_DATA_SUBMIT_FAILS,
  FETCH_DATA_SUBMIT_SUCCESS,
  FETCH_LOCATION_STREET_NAME,
  FETCH_LOCATION_STREET_NAME_FAILS,
  FETCH_LOCATION_STREET_NAME_SUCCESS,
  FETCH_LOCATION_ADDRESS_LINE3,
  FETCH_LOCATION_ADDRESS_LINE3_SUCCESS,
  FETCH_LOCATION_ADDRESS_LINE3_FAILS,
  FETCH_MRT_STATIONS,
  FETCH_MRT_STATIONS_FAILS,
  FETCH_MRT_STATIONS_SUCCESS,
  FETCH_PACKAGE,
  FETCH_PACKAGE_FAILS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_PRICING_OPTIONS,
  FETCH_PRICING_OPTIONS_DAYS_ARRAY,
  FETCH_PRICING_OPTIONS_DAYS_ARRAY_FAILS,
  FETCH_PRICING_OPTIONS_DAYS_ARRAY_SUCCESS,
  FETCH_PRICING_OPTIONS_FAILS,
  FETCH_PRICING_OPTIONS_SUCCESS,
  FETCH_RULES,
  FETCH_RULES_FAILS,
  FETCH_RULES_SUCCESS,
  FETCH_SAVINGS,
  FETCH_SAVINGS_FAILS,
  FETCH_SAVINGS_SUCCESS,
  FETCH_SEATING_ARRANGEMENT,
  FETCH_SEATING_ARRANGEMENT_FAILS,
  FETCH_SEATING_ARRANGEMENT_SUCCESS,
  FETCH_SG_TAX_DETAILS,
  FETCH_SG_TAX_DETAILS_FAILS,
  FETCH_SG_TAX_DETAILS_SUCCESS,
  FETCH_SL_TAX_DETAILS,
  FETCH_SL_TAX_DETAILS_FAILS,
  FETCH_SL_TAX_DETAILS_SUCCESS,
  FETCH_TYPES,
  FETCH_TYPES_FAILS,
  FETCH_TYPES_SUCCESS,
  GET_LAT_LONG_BY_ADDRESS_SUCCESS,
  GET_LAT_LONG_BY_ADDRESS_FAILS,
  GET_NEAREST_TRAIN_STATION_BY_LAT_LNG,
  GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_SUCCESS,
  GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_FAILS,
  SYNC_WITH_CALENDAR,
  SYNC_WITH_CALENDAR_SUCCESS,
  SYNC_WITH_CALENDAR_FAILS,
  GET_GOOGLE_TOKEN_DETAILS_SUCCESS,
  GET_GOOGLE_TOKEN_DETAILS_FAILS,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILS,
  CALENDAR_AUTH_DATA,
  OUTLOOK_STATUS
} from './ListingActionTypes';
import { SRI_LANKA_CURRENCY, SRI_LANKA_SHORT_CODE } from './SpaceListingConstants';

export const getCategoriesAndActivityTypes = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TYPES,
    });

    // get(`space/space-activities`)
    //   .then(res => {
    //     console.log(res.data);
    //     dispatch({
    //       type: FETCH_TYPES_SUCCESS,
    //       payload: res.data
    //     });
    //   })
    //   .catch(error => {
    //     dispatch({
    //       type: FETCH_TYPES_FAILS,
    //       payload: error
    //     });
    //   });
  };
};

export const getCountryWiseEventType = (country) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TYPES,
    });

    get(`space/event-type-map?country=` + country)
      .then((eventTypeResponse) => {
        get(`space/space-category-map?country=` + country)
          .then((spaceCategoryResponse) => {
            const data = spaceCategoryResponse?.data.spaceCategories.map((value) => {
              return {
                ...value,
                venueType: eventTypeResponse?.data?.eventTypes?.filter((eventValue) => eventValue?.spaceCategory?.id === value?.id),
              };
            });
            dispatch({
              type: FETCH_TYPES_SUCCESS,
              payload: data,
            });
          })
          .catch((error) => {
            dispatch({
              type: FETCH_TYPES_FAILS,
              payload: error,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_COUNTRY_WISE_EVENT_TYPE_FAILS,
          payload: error,
        });
      });
  };
};

export const getPricingOptions = (countryShortCode) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRICING_OPTIONS,
    });

    get(`space/charging-type-map?country=` + countryShortCode)
      .then((res) => {
        dispatch({
          type: FETCH_PRICING_OPTIONS_SUCCESS,
          payload: res.data?.chargingType,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRICING_OPTIONS_FAILS,
          payload: error,
        });
      });
  };
};

export const getPricingOptionsDayArray = (idList) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PRICING_OPTIONS_DAYS_ARRAY,
    });

    get(`space/pricing-options-map?chargingTypes=` + idList?.map((value) => value?.id).toString())
      .then((res) => {
        dispatch({
          type: FETCH_PRICING_OPTIONS_DAYS_ARRAY_SUCCESS,
          payload: res?.data?.map((value) => {
            return {
              ...value,
              unit: idList?.find((itemListIndex) => itemListIndex?.id === value?.chargingType)?.unit,
              name: idList?.find((itemListIndex) => itemListIndex?.id === value?.chargingType)?.name,
            };
          }),
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRICING_OPTIONS_DAYS_ARRAY_FAILS,
          payload: error,
        });
      });
  };
};

export const getCancellationPolicyDetails = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_CANCELLATION_POLICY,
    });

    get(`space/cancellation-policy`)
      .then((res) => {
        dispatch({
          type: FETCH_CANCELLATION_POLICY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CANCELLATION_POLICY_FAILS,
          payload: error,
        });
      });
  };
};

export const getTaxDetails = (country) => {
  if (country === SRI_LANKA_SHORT_CODE) {
    return (dispatch) => {
      dispatch({
        type: FETCH_SL_TAX_DETAILS,
      });

      get(`booking/tax?country=` + country)
        .then((res) => {
          dispatch({
            type: FETCH_SL_TAX_DETAILS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: FETCH_SL_TAX_DETAILS_FAILS,
            payload: error,
          });
        });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: FETCH_SG_TAX_DETAILS,
      });

      get(`booking/tax?country=` + country)
        .then((res) => {
          dispatch({
            type: FETCH_SG_TAX_DETAILS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: FETCH_SG_TAX_DETAILS_FAILS,
            payload: error,
          });
        });
    };
  }
};

export const getAmenities = (country) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_AMENITIES,
    });

    get(`space/amenity-map?country=${country}`)
      .then((res) => {
        dispatch({
          type: FETCH_AMENITIES_SUCCESS,
          payload: res?.data?.amenities || [],
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_AMENITIES_FAILS,
          payload: error,
        });
      });
  };
};

export const getAddOns = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADDONS,
    });

    get(`space/add-on`)
      .then((res) => {
        dispatch({
          type: FETCH_ADDONS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ADDONS_FAILS,
          payload: error,
        });
      });
  };
};

export const getRules = (country) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_RULES,
    });

    get(`space/space-rules-map?country=${country}`)
      .then((res) => {
        dispatch({
          type: FETCH_RULES_SUCCESS,
          payload: res?.data?.rules || [],
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_RULES_FAILS,
          payload: error,
        });
      });
  };
};

export const getMrtStations = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_MRT_STATIONS,
    });

    get(`space/nearby-locations?country=SG`)
      .then((res) => {
        dispatch({
          type: FETCH_MRT_STATIONS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_MRT_STATIONS_FAILS,
          payload: error,
        });
      });
  };
};

export const getPackageTypes = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PACKAGE,
    });

    get(`space/package-type`)
      .then((res) => {
        dispatch({
          type: FETCH_PACKAGE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PACKAGE_FAILS,
          payload: error,
        });
      });
  };
};

export const getSeatingArrangements = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SEATING_ARRANGEMENT,
    });

    get(`space/seat-arrangement`)
      .then((res) => {
        dispatch({
          type: FETCH_SEATING_ARRANGEMENT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SEATING_ARRANGEMENT_FAILS,
          payload: error,
        });
      });
  };
};

export const submitWorkSpaceDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_DATA_SUBMIT,
    });

    post(`space`, payload)
      .then((res) => {
        dispatch({
          type: FETCH_DATA_SUBMIT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_DATA_SUBMIT_FAILS,
          payload: error,
        });
      });
  };
};

export const submitMeetSpaceDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_DATA_SUBMIT,
    });

    post(`space/meet`, payload)
      .then((res) => {
        dispatch({
          type: FETCH_DATA_SUBMIT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_DATA_SUBMIT_FAILS,
          payload: error,
        });
      });
  };
};

export const getSavings = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SAVINGS,
    });

    get(`booking/saving`)
      .then((res) => {
        dispatch({
          type: FETCH_SAVINGS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SAVINGS_FAILS,
          payload: error,
        });
      });
  };
};

export const getLocations = (keyword, countryCode) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_LOCATION_STREET_NAME,
    });

    get(`search/auto-complete/location?keyWord=${keyword}&country=` + countryCode)
      .then((res) => {
        dispatch({
          type: FETCH_LOCATION_STREET_NAME_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_LOCATION_STREET_NAME_FAILS,
          payload: error,
        });
      });
  };
};

export const getLocationsAddress3 = (keyword, countryCode) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_LOCATION_ADDRESS_LINE3,
    });

    get(`search/auto-complete/region?keyWord=${keyword}&country=` + countryCode)
      .then((res) => {
        dispatch({
          type: FETCH_LOCATION_ADDRESS_LINE3_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_LOCATION_ADDRESS_LINE3_FAILS,
          payload: error,
        });
      });
  };
};

export const updateToHost = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_CONVERT_TO_HOST,
    });

    put_listing(`auth/user/host`, {})
      .then((res) => {
        dispatch({
          type: FETCH_CONVERT_TO_HOST_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CONVERT_TO_HOST_FAILS,
          payload: error,
        });
      });
  };
};

export const updateDataObject = (object) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_DATA_OBJECT,
    });
    try {
      dispatch({
        type: FETCH_DATA_OBJECT_SUCCESS,
        payload: object,
      });
    } catch (e) {
      dispatch({
        type: FETCH_DATA_OBJECT_FAILS,
        payload: e,
      });
    }
  };
};

export const getLocationDetailsByAddress = (address) => {
  return (dispatch) => {
    getLocationByAddress(address)
      .then((res) =>
        dispatch({
          type: GET_LAT_LONG_BY_ADDRESS_SUCCESS,
          payload: res?.data?.results.map((item) => item?.geometry)?.map((item) => item?.location)?.[0],
        })
      )
      .catch((error) =>
        dispatch({
          type: GET_LAT_LONG_BY_ADDRESS_FAILS,
          payload: error,
        })
      );
  };
};

export const getNearestLocationDetailsByLatLng = (lat, lng) => {
  return (dispatch) => {
    dispatch({
      type: GET_NEAREST_TRAIN_STATION_BY_LAT_LNG,
    });
    get(`space/nearest/station/${lat}/${lng}`)
      .then((res) => {
        dispatch({
          type: GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: GET_NEAREST_TRAIN_STATION_BY_LAT_LNG_FAILS,
          payload: e,
        });
      });
  };
};

export const syncWithCalendars = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SYNC_WITH_CALENDAR,
    });
    post('space/authenticate-calendar', payload)
      .then((res) => {
        dispatch({
          type: SYNC_WITH_CALENDAR_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: SYNC_WITH_CALENDAR_FAILS,
          payload: e,
        });
      });
  };
};

export const setGoogleTokens = (code) => {
  return (dispatch) => {
    get_tokens(code)
      .then((res) => {
        dispatch({
          type: GET_GOOGLE_TOKEN_DETAILS_SUCCESS,
          payload: { ...res.data, googleStatus: 1 },
        });
      })
      .catch((e) => {
        dispatch({
          type: GET_GOOGLE_TOKEN_DETAILS_FAILS,
          payload: { e, googleStatus: 0 },
        });
      });
  };
};

export const getEmail = (accessToken) => {
  return (dispatch) => {
    getAuthorizedEmail(accessToken)
      .then((res) => {
        dispatch({
          type: GET_EMAIL_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) =>
        dispatch({
          type: GET_EMAIL_FAILS,
          payload: e,
        })
      );
  };
};

export const calendarAuthData = (result) => ({
  type: CALENDAR_AUTH_DATA,
  result,
});


export const outlookStatus = (result) => ({
  type: OUTLOOK_STATUS,
  result,
  
});




import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Button } from 'antd';
import InfiniteTable from '../Common/Table/InfiniteTable';
import { clearvailableReports, fetchAllReports, downloadCSV } from './ReportAction';

const ReportDownload = () => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState({});
    const authToken  = useSelector((state) => state?.auth?.loginDetails?.data?.token);  
    const downloadData =  useSelector((state) => state?.report.availableReports?.data || [] );

    useEffect(() => {
        dispatch(clearvailableReports())
    }, []);

    const downloadHeaders = [
        {
            key: 'reportName',
            title: 'Report Name',
            dataIndex: 'reportName',
            className: 'custom-width',
            width:113
        },{
            key: 'taskStatus',
            title: 'Status',
            dataIndex: 'taskStatus',
            className: 'custom-width',
            width:113
        },{
            key: 'createdDate',
            title: 'Created Date',
            dataIndex: 'createdDate',
            className: 'custom-width',
            width:113
        },{
            key: 'id',
            title: 'Download',
            dataIndex: 'id',
            className: 'custom-width',
            width:113,
            render: (text, data) => {
                return data?.taskStatus === 'COMPLETED' ? <Button onClick={()=> dispatch(downloadCSV(text, data.reportName))}>Download</Button> : null;
            }
        }
    ];

    return (
        <div className="admin-wrapper reporting-wrap">
            <div className="container">
                <p className="title">Download</p>           
                <InfiniteTable
                    columns={downloadHeaders}
                    dataSource={downloadData}
                    tableDispatch={fetchAllReports}
                    props={{ increaseValue: 10, token: authToken }}
                    tableData={setTableData}
                    scroll={{ y: 330, x: 500 }}
                />
            </div>
        </div>
    )
};

export default ReportDownload;


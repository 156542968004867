import React, { useCallback, useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { TextField } from "react-md";
import { showErrorMessage } from '../Common/Common';
import { DEFAULT_DATA_OBJ, ERROR_CHAR_LIMIT, ERROR_SPECIAL_CHAR, ERROR_WHITE_SPACE, ERROR_ONLY_NUMBERS } from '../SpaceListingConstants';
import { spaceListingData } from "../Common/ProfileListingDataModal";
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { getProfileData, setProfileData } from "../Common/DataController";
import { contentIsNotNull, noSpecialCharIncluded } from "../Common/Validator";
import Select from 'antd/es/select';
import { IMAGES_BASE_URL } from '../../../settings';
import { useParams } from 'react-router-dom';


const SpaceDetails = ({ currentBlock }) => {
  const { Option } = Select;
  const [spaceDetails, setSpaceDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  const { country } = useParams();
  const profileListingData = JSON.parse(sessionStorage.getItem('profileListingData'));

  useEffect(() => {
    profileListingData?.space_location?.[0]?.spaceCountry == "Australia" || profileListingData?.space_location?.[0]?.spaceCountry == "AUSTRALIA"
    ? setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue("spaceTitle"), 4, 60) &&
      contentIsNotNull(getFieldCurrentValue("organizationName"), 2, 60) &&
      contentIsNotNull(getFieldCurrentValue("abnNumber"), 13) &&
      contentIsNotNull(getFieldCurrentValue("description"), 2)

    ) :
    setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue("spaceTitle"), 4, 60) &&
      contentIsNotNull(getFieldCurrentValue("organizationName"), 2, 60) &&
      contentIsNotNull(getFieldCurrentValue("description"), 2)
    );
  }, [spaceDetails]);

  const handleSpaceDetails = useCallback((name, newValue, max=5000, noSpecialChar=false, noWhiteSpaces=false, specialChatCheck=false, onlyNumbers=false) => {
    if (newValue?.length > max){
      showErrorMessage(ERROR_CHAR_LIMIT)
    } else if(noSpecialChar && !noSpecialCharIncluded(newValue.toString())){
      showErrorMessage(ERROR_SPECIAL_CHAR)
    } else if(noWhiteSpaces && /\s/g.test(newValue)){
      showErrorMessage(ERROR_WHITE_SPACE)
    } else if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {

      if(name === "description"){
        let descriptionValue = newValue
        const cleanText = descriptionValue?.replace(/<\/?[^>]+(>|$)/g, "") || '';
        if(!cleanText) newValue = ''
      }

      if (name === "abnNumber"){
        let formattedValue = newValue.replace(/\D/g, '');     
        formattedValue = formattedValue.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{3})?/, '$1 $2 $3 $4').trim(); 
        newValue = formattedValue;
      }
      
      const tempObj = Object.assign({}, spaceDetails, {
        ...spaceDetails,
        processStarted: true,
        [currentBlock]: spaceDetails?.[currentBlock].map((value) => {
          value[name] = newValue;
          return value;
        }),
      });
      setSpaceDetails(tempObj);
      setProfileData(tempObj);
    }
  }, []);

  const getFieldCurrentValue = (type) => {
    return spaceDetails?.[currentBlock]?.[0]?.[type];
  };
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link',{ 'color': [] }],
      [{'size' : []}]
    ],
  }
  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'link','color', 'size'
  ]


  return (
    <>
      <div className="left-side-inner-wrapper space-details-wrapper">
        <h1 className="listing-page-title">Space details</h1>
        <div>
          <TextField
            className="input-margin-wrapper"
            id="organizationName"
            label="Organization Name"
            placeholder="Organization or Individual"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleSpaceDetails("organizationName", value, 60, false,false, true, false)}
            value={getFieldCurrentValue("organizationName")}
          />

          <TextField
            className="input-margin-wrapper"
            id="spaceTitle"
            label="Space Title"
            lineDirection="center"
            errorText="This field is required."
            onChange={(value) => handleSpaceDetails("spaceTitle", value,60, false,false, true, false)}
            value={getFieldCurrentValue("spaceTitle")}
          />

          {profileListingData?.space_location?.[0]?.spaceCountry == "Australia" || profileListingData?.space_location?.[0]?.spaceCountry == "AUSTRALIA" ? (
            <TextField
              className="input-margin-wrapper"
              id="abnNumber"
              label="ABN (Must be 11 digits)"
              lineDirection="center"
              errorText="This field is required."
              onChange={(value) => handleSpaceDetails("abnNumber", value,14, true,false, false, false)}
              value={getFieldCurrentValue("abnNumber")}
            />
          ):(
            ""
          )}

          <ReactQuill
            value={getFieldCurrentValue("description")}
            placeholder={"Your space description"}
            modules={modules}
            formats={formats} theme={'snow'}
            onChange={(value) => handleSpaceDetails("description", value)}
          />
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default SpaceDetails;

export const FETCH_BOOKING_USER_STATUS = 'FETCH_BOOKING_USER_STATUS';
export const FETCH_BOOKING_USER_STATUS_SUCCESS = 'FETCH_BOOKING_USER_STATUS_SUCCESS';
export const FETCH_BOOKING_USER_STATUS_FAIL = 'FETCH_BOOKING_USER_STATUS_FAIL';

export const FETCH_BOOKING_SPACES_LIST = 'FETCH_BOOKING_SPACES_LIST';
export const FETCH_BOOKING_SPACES_LIST_SUCCESS = 'FETCH_BOOKING_SPACES_LIST_SUCCESS';
export const FETCH_BOOKING_SPACES_LIST_FAIL = 'FETCH_BOOKING_SPACES_LIST_FAIL';

export const FETCH_SELECTED_SPACE_DETAILS = 'FETCH_SELECTED_SPACE_DETAILS';
export const FETCH_SELECTED_SPACE_DETAILS_SUCCESS = 'FETCH_SELECTED_SPACE_DETAILS_SUCCESS';
export const FETCH_SELECTED_SPACE_DETAILS_FAIL = 'FETCH_SELECTED_SPACE_DETAILS_FAIL';

export const FETCH_BOOKING_RECEIPT_DATA = 'FETCH_BOOKING_RECEIPT_DATA';
export const FETCH_BOOKING_RECEIPT_DATA_SUCCESS = 'FETCH_BOOKING_RECEIPT_DATA_SUCCESS';
export const FETCH_BOOKING_RECEIPT_DATA_FAIL = 'FETCH_BOOKING_RECEIPT_DATA_FAIL';

export const CANCEL_SELECTED_BOOKING = 'CANCEL_SELECTED_BOOKING';
export const CANCEL_SELECTED_BOOKING_SUCCESS = 'CANCEL_SELECTED_BOOKING_SUCCESS';
export const CANCEL_SELECTED_BOOKING_FAIL = 'CANCEL_SELECTED_BOOKING_FAIL';

export const CLEAR_BOOKING_SPACES_LIST = 'CLEAR_BOOKING_SPACES_LIST';
export const CLEAR_BOOKING_SPACE_DETAILS = 'CLEAR_BOOKING_SPACE_DETAILS';

export const ADMIN_DASHBOARD_NAVIGATOR_SUCCESS = 'ADMIN_DASHBOARD_NAVIGATOR_SUCCESS';
export const ADMIN_DASHBOARD_NAVIGATOR_FAIL = 'ADMIN_DASHBOARD_NAVIGATOR_FAIL';

export const ADMIN_VIEW_ON_NAV_SUCCESS = 'ADMIN_VIEW_ON_NAV_SUCCESS';
export const ADMIN_VIEW_ON_NAV_FAIL = 'ADMIN_VIEW_ON_NAV_FAIL';

import {
  FETCH_BOOKING_USER_STATUS,
  FETCH_BOOKING_USER_STATUS_SUCCESS,
  FETCH_BOOKING_USER_STATUS_FAIL,
  FETCH_BOOKING_SPACES_LIST,
  FETCH_BOOKING_SPACES_LIST_SUCCESS,
  FETCH_BOOKING_SPACES_LIST_FAIL,
  FETCH_SELECTED_SPACE_DETAILS,
  FETCH_SELECTED_SPACE_DETAILS_SUCCESS,
  FETCH_SELECTED_SPACE_DETAILS_FAIL,
  FETCH_BOOKING_RECEIPT_DATA,
  FETCH_BOOKING_RECEIPT_DATA_SUCCESS,
  FETCH_BOOKING_RECEIPT_DATA_FAIL,
  CANCEL_SELECTED_BOOKING,
  CANCEL_SELECTED_BOOKING_SUCCESS,
  CANCEL_SELECTED_BOOKING_FAIL,
  CLEAR_BOOKING_SPACES_LIST,
  CLEAR_BOOKING_SPACE_DETAILS,
  ADMIN_DASHBOARD_NAVIGATOR_SUCCESS,
  ADMIN_DASHBOARD_NAVIGATOR_FAIL,
  ADMIN_VIEW_ON_NAV_SUCCESS,
  ADMIN_VIEW_ON_NAV_FAIL
} from './BookingCancellationActionTypes';

export const BookingCancelReducer = (
  state = {
    userStatus: {
      fetching: false,
      error: { status: false, message: '' },
      data: [],
    },
    spaceList: {
      fetching: false,
      error: { status: false, message: '' },
      data: [],
    },
    spaceDetails: {
      fetching: false,
      error: { status: false, message: '' },
      data: [],
    },
    receiptDetails: {
      fetching: false,
      error: { status: false, message: '' },
      data: [],
    },
    bookingCancellation: {
      status: false,
    },
    adminDashboardNavigator: {
      status: false,
    },
  },

  action
) => {
  switch (action.type) {
    case FETCH_BOOKING_USER_STATUS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          fetching: true,
          error: { status: false, message: '' },
          data: [],
        },
      };
    case FETCH_BOOKING_USER_STATUS_SUCCESS:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_BOOKING_USER_STATUS_FAIL:
      return {
        ...state,
        userStatus: {
          ...state.userStatus,
          fetching: false,
          error: { status: true, message: '' },
          data: action.payload,
        },
      };
    case FETCH_BOOKING_SPACES_LIST:
      return {
        ...state,
        spaceList: {
          ...state.spaceList,
          fetching: true,
          error: { status: false, message: '' },
        },
      };

    case FETCH_BOOKING_SPACES_LIST_SUCCESS:
      return {
        ...state,
        spaceList: {
          ...state.spaceList,
          fetching: false,
          error: { status: false, message: '' },
          data: state.spaceList.data.concat(action.payload),
        },
      };
    case FETCH_BOOKING_SPACES_LIST_FAIL:
      return {
        ...state,
        spaceList: {
          ...state.spaceList,
          fetching: false,
          error: { status: true, message: 'No Bookings Found' },
        },
      };
    case CLEAR_BOOKING_SPACES_LIST:
      return {
        ...state,
        spaceList: {
          ...state.spaceList,
          fetching: false,
          error: { status: false, message: '' },
          data: [],
        },
      };

    case FETCH_SELECTED_SPACE_DETAILS:
      return {
        ...state,
        spaceDetails: {
          ...state.spaceDetails,
          fetching: true,
          error: { status: false, message: '' },
          data: [],
        },
      };
    case FETCH_SELECTED_SPACE_DETAILS_SUCCESS:
      return {
        ...state,
        spaceDetails: {
          ...state.spaceDetails,
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_SELECTED_SPACE_DETAILS_FAIL:
      return {
        ...state,
        spaceDetails: {
          ...state.spaceDetails,
          fetching: false,
          error: { status: true, message: '' },
          data: [],
        },
      };
    case CLEAR_BOOKING_SPACE_DETAILS:
      return {
        ...state,
        spaceDetails: {
          ...state.spaceDetails,
          fetching: false,
          error: { status: false, message: '' },
          data: [],
        },
      };
    case FETCH_BOOKING_RECEIPT_DATA:
      return {
        ...state,
        receiptDetails: {
          ...state.receiptDetails,
          fetching: true,
          error: { status: false, message: '' },
          data: [],
        },
      };
    case FETCH_BOOKING_RECEIPT_DATA_SUCCESS:
      return {
        ...state,
        receiptDetails: {
          ...state.receiptDetails,
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload,
        },
      };
    case FETCH_BOOKING_RECEIPT_DATA_FAIL:
      return {
        ...state,
        receiptDetails: {
          ...state.receiptDetails,
          fetching: false,
          error: { status: true, message: '' },
          data: [],
        },
      };
    case ADMIN_DASHBOARD_NAVIGATOR_SUCCESS:
      return {
        ...state,
        adminDashboardNavigator: {
          status: true,
        },
      };
    case ADMIN_DASHBOARD_NAVIGATOR_FAIL:
      return {
        ...state,
        adminDashboardNavigator: {
          status: false,
        },
      };
    case ADMIN_VIEW_ON_NAV_SUCCESS:
      return {
        ...state,
        adminViewOnNavigation: {
          status: true,
        },
      };
    case ADMIN_VIEW_ON_NAV_FAIL:
      return {
        ...state,
        adminViewOnNavigation: {
          status: false,
        },
      };

    case CANCEL_SELECTED_BOOKING:
      return {
        ...state,
        bookingCancellation: {
          status: false,
        },
      };
    case CANCEL_SELECTED_BOOKING_SUCCESS:
      return {
        ...state,
        bookingCancellation: {
          status: true,
        },
      };
    case CANCEL_SELECTED_BOOKING_FAIL:
      return {
        ...state,
        bookingCancellation: {
          status: false,
        },
      };

    default:
      return state;
  }
};

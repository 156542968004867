import React  from "react";
import { notification } from "antd";
import { getProfileData, setProfileData } from './DataController';
import { noSpecialCharIncluded } from "./Validator";
import { redClose } from "../../BookingCancelation/BookingCancellationAssets";
import { ERROR_CHAR_LIMIT, ERROR_NO_NUMBERS_ALLOWED, ERROR_ONLY_NUMBERS, ERROR_SPECIAL_CHAR, ERROR_WHITE_SPACE } from "../HostOnboardingConstants";

export const showErrorMessage = (message) => {
  notification.open({
    className: "booking-cancellation-notification",
    message: (
      <span className="title">
        {" "} <img src={redClose} alt="arrow" className="icon" /> <span className="text">{message}</span>
      </span>
    ),
  });
};

export const handleDataObject = (name, newValue, currentBlock, currentDataObj, setDataObject, max = 5000, noSpecialChar = false, noWhiteSpaces = false, onlyNumbers = false, noNumbersAllowed = false) => {
  if (newValue?.length > max) {
    showErrorMessage(ERROR_CHAR_LIMIT);
  } else if (noSpecialChar && !noSpecialCharIncluded(newValue.toString())) {
    showErrorMessage(ERROR_SPECIAL_CHAR);
  } else if (noWhiteSpaces && /\s/g.test(newValue)) {
    showErrorMessage(ERROR_WHITE_SPACE);
  } else if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== "") {
    showErrorMessage(ERROR_ONLY_NUMBERS);
  } else if (noNumbersAllowed && /[^a-zA-Z ]/g.test(newValue) && newValue !== "") {
    showErrorMessage(ERROR_NO_NUMBERS_ALLOWED);
  } else {
    const tempObj = Object.assign({}, currentDataObj, {
      ...currentDataObj,
      processStarted: true,
      [currentBlock]: currentDataObj?.[currentBlock].map((value, index) => {
        value[name] = newValue;
        return value;
      }),
    });
    setDataObject(tempObj);
    setProfileData(tempObj);
  }
};

export const getFieldCurrentValue = (currentBlock, type) => getProfileData()?.[currentBlock]?.[0]?.[type];

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Icon } from 'antd';
import { getPaymentReportData, getSearchResults, getSearchResultsAsLists } from '../ReportAction';
import {
  paymentTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  searchFilterForEmptyFields,
  paymentTrackerSearchResultsObj,
  paymentTrackerObjList,
  setToolTip,
  revenueTrackerObj,
} from '../../../helper/ReportingHelper';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import YearMonthFilter from '../common/YearMonthFilter';
import DateFilter from '../common/DateFilter';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import { IMAGES_BASE_URL } from '../../../settings';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';
import decode from 'jwt-decode';

const PaymentTracker = ({ handleDownload, status }) => {
  const dispatch = useDispatch();
  const [searchingKey, setSearchingKey] = useState('');
  const [searchingStatus, setSearchingStatus] = useState(false);
  const [searchStatusList, setSearchStatusList] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourcesLists, setDataSourcesLists] = useState([]);
  const [tableData, setTableData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const paymentReportData = useSelector((state) => state?.report?.reports?.payment?.data);
  const dataloading = useSelector((state) => state?.report?.reports?.payment?.loading);
  const authToken = useSelector((state) => state?.auth?.loginDetails?.data?.token);

  const paymentTrackerSearchResult = useSelector((state) => state?.report?.searchResult || {});
  const loggedUser = authToken ? decode(authToken) : '';
  const isSuperAdmin = loggedUser.authorities && (loggedUser.authorities[0] === 'role.suadmin') ? true : false;
  const paymentTrackerSearchResultList = useSelector((state) => state?.report?.searchResultList || []);
  const isLoading = useSelector((state) => state?.report?.reports?.payment?.fetching || false);

  const AuthTokenData = decode(authToken);
  const userCountry = AuthTokenData?.location ?.country;

  useEffect(() => {
      setDataLoading(dataloading);
  }, [dataloading]);

  useEffect(() => {
    let formattedData = paymentTrackerObj(paymentReportData);
    formattedData?.length && setDataSource(formattedData);
  }, [paymentReportData]);

  useEffect(() => {
    if (paymentTrackerSearchResultList.length) {
      const formattedDataLists = paymentTrackerObjList(paymentTrackerSearchResultList);

      formattedDataLists?.length && setDataSourcesLists(formattedDataLists);
    }
  }, [paymentTrackerSearchResultList]);

  const handleSearch = (setSelectedKeys, confirm, searchText, type, mainType, searchTextKey) => {
    const startDate = searchText[0];
    const endDate = searchText[searchText.length - 1];
    setSearchingKey(searchTextKey);
    dispatch(getSearchResults(searchText, type, mainType, startDate, endDate));
    setSearchingStatus(true);
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSearchingStatus(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleSearchList = (setSelectedKeys, confirm, searchText, type, mainType) => {
    let startDate = '';
    let endDate = '';
    if (type === 'bookingDate' || type === 'paymentDate') {
      startDate = searchText[0];
      endDate = searchText[searchText.length - 1];
    }
    setSearchingKey(type);
    dispatch(getSearchResultsAsLists(searchText, type, mainType, startDate, endDate));
    setSearchStatusList(true);
  };

  const handleResetList = (setSelectedKeys, clearFilters) => {
    setSearchStatusList(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleFilterSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleFilterReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const checkObj = (searchingKey) => {
    let searchText1 = searchingKey;

    let res = [];
    if (paymentTrackerSearchResult.hasOwnProperty(searchText1) && paymentTrackerSearchResult[searchText1] === null) {
      res = []; 
    } else {
      res = [paymentTrackerSearchResultsObj(paymentTrackerSearchResult)];
    }

    return res;
  };

  const paymentTrackerHeader = [
    {
      key: 'bookingRef',
      title: 'Booking Ref. #',
      dataIndex: 'bookingRef',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearch(setSelectedKeys, confirm, searchText, (type = 'booking'), (mainType = 'payment-tracker'), (searchTextKey = 'bookingId'))
          }
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'bookingRef');
      },
    },
    {
      key: 'paymentRef',
      title: 'Payment Ref. #',
      dataIndex: 'paymentRef',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearch(setSelectedKeys, confirm, searchText, (type = 'paymentId'), (mainType = 'payment-tracker'), (searchTextKey = 'paymentId'))
          }
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'paymentRef');
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="category"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '68px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'category');
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '60px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'activity');
      },
    },
    {
      key: 'guestName',
      title: 'Guest Name',
      dataIndex: 'guestName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, mainType, type, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'guestName'),
              (mainType = 'payment-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '47px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'guestName');
      },
    },
    {
      key: 'guestEmail',
      title: 'Guest Email',
      dataIndex: 'guestEmail',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },

    {
      key: 'hostName',
      title: 'Host Name',
      dataIndex: 'hostName',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostEmail',
      title: 'Host Email',
      dataIndex: 'hostEmail',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceId',
      title: 'Space ID',
      dataIndex: 'spaceId',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, searchTextKey, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'spaceId'),
              (mainType = 'payment-tracker'),
              (startDate = ''),
              (endDate = ''),
              (searchTextKey = 'spaceId')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceId');
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        mainType = 'payment-tracker',
        type = 'spaceName',
        startDate = '',
        endDate = '',
      }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'spaceName'),
              (mainType = 'booking-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return text === 'LK' ? 'SL' : handleNullValues(text);
      },
      filterDropdown: isSuperAdmin ?  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ):null,
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'bookingDate',
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      width: 113,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        mainType = 'payment-tracker',
        type = 'bookingDate',
        startDate,
        endDate,
      }) => (
        <DateFilter
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
          onSearch={(searchText) =>
            handleSearchList(setSelectedKeys, confirm, searchText, (type = 'bookingDate'), (mainType = 'payment-tracker'), startDate, endDate)
          }
        />
      ),
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'bookingDate');
      },
    },
    {
      key: 'paymentDate',
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      className: 'custom-width',
      width: 113,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <DateFilter
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
          onSearch={(searchText) =>
            handleSearchList(setSelectedKeys, confirm, searchText, (type = 'paymentDate'), (mainType = 'payment-tracker'), startDate, endDate)
          }
        />
      ),
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'paymentDate');
      },
    },
    {
      key: 'eventStartDate',
      title: 'Event Start Date',
      dataIndex: 'eventStartDate',
      width: 113,
    },
    {
      key: 'eventEndDate',
      title: 'Event End Date',
      dataIndex: 'eventEndDate',
      width: 113,
    },
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="month"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '50px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'month');
      },
    },
    {
      key: 'year',
      title: 'Year',
      dataIndex: 'year',
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="year"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '38px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'year');
      },
    },
    {
      key: 'paymentAmount',
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'transactionFee',
      title: 'Transaction Fee',
      dataIndex: 'transactionFee',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'cardType',
      title: 'Card Type',
      dataIndex: 'cardType',
      className: 'custom-width',
      width: 150,
      render: (text) => {
        let cardName;
        switch (text) {
          case 'visa':
            cardName = 'VISA';
            return (
              <div>
                <img className="img" src={`${IMAGES_BASE_URL}image/upload/v1594788153/ms-singapore/Icons/visaCard.svg`} /> {cardName}
              </div>
            );
          case 'mastercard':
            cardName = 'MASTERCARD';
            return (
              <div>
                <img className="img" src={`${IMAGES_BASE_URL}image/upload/v1594788153/ms-singapore/Icons/masterCard.svg`} /> {cardName}
              </div>
            );
          case 'amex':
            cardName = 'AMEX';
            return (
              <div>
                <img className="img" src={`${IMAGES_BASE_URL}image/upload/v1594788153/ms-singapore/Icons/amexCard.svg`} /> {cardName}
              </div>
            );
          default:
            return handleNullValues(text);
        }
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="cardType"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '80px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'cardType');
      },
    },
    {
      key: 'cardNumber',
      title: 'Card Number',
      dataIndex: 'cardNumber',
      className: 'custom-width',
      width: 150,
      render: (text) => {
        return text ? `•••• •••• •••• ${text}` : handleNullValues(text);
      },
    },
    {
      key: 'cardExpDate',
      title: 'Card Expiry Date',
      dataIndex: 'cardExpDate',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'paymentStatus',
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="paymentStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'paymentStatus');
      },
    },
    {
      key: 'bookingStatus',
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="bookingStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'bookingStatus');
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'payment-tracker', totalDataLength: dataSource?.length, token: authToken, spaceCountry: userCountry });
  };
  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={paymentTrackerHeader}
        dataSource={searchingStatus ? checkObj(searchingKey) : searchStatusList ? dataSourcesLists : dataSource}
        tableDispatch={getPaymentReportData}
        props={{ increaseValue: 20, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
        searchStatus={searchingStatus}
        searchStatusList={searchStatusList}
        loading={dataLoading}
      />
    </div>
  );
};

export default withTracker('PAYMENT_TRACKER')(PaymentTracker);

import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Select } from 'antd';
import { Autocomplete, TextField } from 'react-md';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAmenities,
  getCountryWiseEventType,
  getLocationDetailsByAddress,
  getLocations,
  getLocationsAddress3,
  getPricingOptions,
  getRules,
  getTaxDetails,
  getNearestLocationDetailsByLatLng,
} from '../ListingAction';
import { showErrorMessage } from '../Common/Common';
import { googleMapAPIKey } from '../../../settings';
import PlacesAutocomplete from 'react-places-autocomplete';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { getLocationDetails, getNearLocation, getLaLong, getLocationByAddress } from '../../../api/getLocationDetails';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { getProfileData, setProfileData } from '../Common/DataController';
import { contentIsNotNull, noSpecialCharIncluded } from '../Common/Validator';
import {
  AUSTRALIA,
  AUSTRALIA_SHORT_CODE,
  AUSTRALIA_CURRENCY,
  DEFAULT_DATA_OBJ,
  ERROR_CHAR_LIMIT,
  ERROR_SPECIAL_CHAR,
  ERROR_WHITE_SPACE,
  SINGAPORE,
  SINGAPORE_CURRENCY,
  SINGAPORE_SHORT_CODE,
  SRI_LANKA,
  SRI_LANKA_CURRENCY,
  SRI_LANKA_SHORT_CODE,
} from '../SpaceListingConstants';
import { useParams } from 'react-router-dom';

let previousCountry = null;

const { Option } = Select;
const SpaceLocation = ({ currentBlock, handleMapSet, handleNearestlocation }) => {
  const [disable, setDisable] = useState(true);
  const { country } = useParams();
  const dispatch = useDispatch();
  const locationSuggestions = useSelector((state) => state?.spaceListing?.locationSearch || []);
  const locationSuggestionsAdd3 = useSelector((state) => state?.spaceListing?.locationSearchAddress3 || []);
  const mapPinChanged = useSelector((state) => state?.spaceListing?.mapPinUpdatedTime);
  const mapZoomUpdated = useSelector((state) => state?.spaceListing?.mapZoomUpdated);
  const mrtSections = useSelector((state) => state?.spaceListing?.mrtStations?.locations) || [];
  const rules = useSelector((state) => state?.spaceListing?.rules || []);
  const amenities = useSelector((state) => state?.spaceListing?.amenities || []);
  const [spaceLocation, setSpaceLocation] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const coordinates = useSelector((state) => state?.spaceListing?.coordinates);
  const [stateAndPostal, setStateAndPostal] = useState();
  const countryShortCode = useSelector((state) => state?.locationMeta?.data?.country_shortCode || 'SG');
  const countryLongCode = useSelector((state) => state?.locationMeta?.data?.country_longCode || 'SINGAPORE');
  const nearestLocations = useSelector((state) => state?.spaceListing?.nearestLocations || []);
  const distance = useSelector((state) => state?.spaceListing?.nearestLocations?.distanceToStation || []);
  const subwayname = useSelector((state) => state?.spaceListing?.nearestLocations?.railwayName || []);
  const latitude = useSelector((state) => state?.spaceListing?.nearestLocations?.lat2 || []);
  const longitude = useSelector((state) => state?.spaceListing?.nearestLocations?.lng2 || []);

  useEffect(() => {
    if (countryShortCode === 'AUS' || getFieldCurrentValue('spaceCountry') === AUSTRALIA) {
      setIsNextBtnActive(contentIsNotNull(getFieldCurrentValue('addressLine1'), 0) && contentIsNotNull(getFieldCurrentValue('addressLine3'), 0));
    } else {
      setIsNextBtnActive(
        contentIsNotNull(getFieldCurrentValue('streetName'), 0) &&
          contentIsNotNull(getFieldCurrentValue('postalCode'), 0, 15) &&
          contentIsNotNull(getFieldCurrentValue('city'), 0, 100) &&
          contentIsNotNull(getFieldCurrentValue('streetName'), 8, 500) &&
          contentIsNotNull(getFieldCurrentValue('postalAddress'), 8) &&
          (getFieldCurrentValue('spaceCountry') === SINGAPORE
            ? !getFieldCurrentValue('mrtStatus') ||
              (contentIsNotNull(getFieldCurrentValue('mrtStatus')?.toString(), 0) && getFieldCurrentValue('mrtSections')?.length > 0)
            : contentIsNotNull(getFieldCurrentValue('isParkingAvailable')?.toString(), 0))
      );
    }
  }, [spaceLocation]);

  useEffect(() => {
    dispatch(getNearestLocationDetailsByLatLng(spaceLocation?.[currentBlock]?.[0]?.latitude, spaceLocation?.[currentBlock]?.[0]?.longitude));
  }, [spaceLocation?.[currentBlock]?.[0]?.latitude, spaceLocation?.[currentBlock]?.[0]?.longitude]);

  useEffect(() => {
    handleSpaceLocation('subwayname', subwayname);
    handleSpaceLocation('publicTransportAccess', distance);
    handleNearestlocation(latitude, longitude);
  }, [nearestLocations]);

  useEffect(() => {
    const tempObj = Object.assign({}, spaceLocation, {
      ...spaceLocation,
      processStarted: true,
      rules: [{ data: rules }],
      amenities: [{ data: amenities }],
    });
    setSpaceLocation(() => tempObj);
    setProfileData(tempObj);
  }, [rules, amenities]);

  useEffect(() => {
    if (previousCountry !== countryShortCode) {
      const result = countryShortCode === 'SG' ? SINGAPORE : countryShortCode === 'AUS' ? AUSTRALIA : SRI_LANKA;
      handleCountryAndMap(result);

      previousCountry = countryShortCode;
    }
  }, [countryShortCode]);

  useEffect(() => {
    if (coordinates) {
      handleSpaceLocation('latitude', coordinates?.lat);
      handleSpaceLocation('longitude', coordinates?.lng);
    }
  }, [coordinates]);

  const getFieldCurrentValue = (type) => {
    return spaceLocation?.[currentBlock]?.[0]?.[type];
  };

  const filteredLocations = Array.from(new Set(locationSuggestions.map(option => option.title)));

  const address = () => {
    switch (getFieldCurrentValue('spaceCountry')) {
      case AUSTRALIA:
        return (
          getFieldCurrentValue('addressLine1') +
          ' ' +
          getFieldCurrentValue('addressLine2') +
          ' ' +
          getFieldCurrentValue('addressLine3') +
          ' ' +
          getFieldCurrentValue('postcode') +
          ' ' +
          'Australia'
        );
      case SINGAPORE:
        return getFieldCurrentValue('postalCode') + ' ' + getFieldCurrentValue('city') + ' ' + getFieldCurrentValue('streetName') + ' ' + 'Singapore';
      default:
        return getFieldCurrentValue('postalCode') + ' ' + getFieldCurrentValue('city') + ' ' + getFieldCurrentValue('streetName') + ' ' + 'Sri Lanka';
    }
  };

  const handleSpaceLocation = (name, newValue, max = 5000, noSpecialChar = false, noWhiteSpaces = false) => {
    if (newValue.toString()?.length > max) {
      showErrorMessage(ERROR_CHAR_LIMIT);
    } else if (noSpecialChar && !noSpecialCharIncluded(newValue.toString())) {
      showErrorMessage(ERROR_SPECIAL_CHAR);
    } else if (noWhiteSpaces && /\s/g.test(newValue)) {
      showErrorMessage(ERROR_WHITE_SPACE);
    } else {
      const tempObj = Object.assign({}, spaceLocation, {
        ...spaceLocation,
        processStarted: true,
        [currentBlock]: spaceLocation?.[currentBlock].map((value) => {
          value[name] = newValue;
          return value;
        }),
      });

      if (name === 'longitude' || name === 'latitude') {
        handleMapSet(tempObj);
      }
      setSpaceLocation(tempObj);
      setProfileData(tempObj);
    }
  };

  const handleCommissionDetails = (name, newValue) => {
    const tempObj = Object.assign({}, spaceLocation, {
      ...spaceLocation,
      processStarted: true,
      commission: spaceLocation?.commission.map((value) => {
        value[name] = newValue;
        return value;
      }),
    });
    setSpaceLocation(tempObj);
    setProfileData(tempObj);
  };

  const handleTaxDetails = (name, newValue) => {
    const tempObj = Object.assign({}, spaceLocation, {
      ...spaceLocation,
      processStarted: true,
      tax_information: spaceLocation?.tax_information.map((value) => {
        value[name] = newValue;
        return value;
      }),
    });
    setSpaceLocation(tempObj);
    setProfileData(tempObj);
  };

  const handleCountryAndMap = (value) => {
    const routingObj = Object.assign({}, spaceLocation, {
      ...spaceLocation,
      processStarted: true,
      routeList: spaceLocation?.routeList.filter((value) => {
        return !value?.includes('addons');
      }),
    });

    handleSpaceLocation('streetName', '');
    handleSpaceLocation('city', '');
    handleSpaceLocation('postalAddress', '');
    handleSpaceLocation('spaceCountry', value);
    if (value === SRI_LANKA) {
      let value = routingObj?.routeList.splice(
        routingObj?.routeList?.indexOf('/dashboard/list-a-space/amenities') + 1,
        0,
        '/dashboard/list-a-space/addons'
      );
      dispatch(getTaxDetails(SRI_LANKA_SHORT_CODE));
      dispatch(getAmenities(SRI_LANKA_SHORT_CODE));
      dispatch(getRules(SRI_LANKA_SHORT_CODE));
      handleTaxDetails('currency', SRI_LANKA_CURRENCY);
      handleCommissionDetails('percentage', 20);
      handleSpaceLocation('latitude', 7.8731, 25);
      handleSpaceLocation('longitude', 80.7718, 25);
      handleSpaceLocation('countryCode', SRI_LANKA_SHORT_CODE);
      handleSpaceLocation('mapZoom', 8);
      dispatch(getCountryWiseEventType(SRI_LANKA_SHORT_CODE));
      dispatch(getPricingOptions(SRI_LANKA_SHORT_CODE));
    } else if (value === AUSTRALIA) {
      dispatch(getTaxDetails(AUSTRALIA_SHORT_CODE));
      dispatch(getAmenities(AUSTRALIA_SHORT_CODE));
      dispatch(getRules(AUSTRALIA_SHORT_CODE));
      handleTaxDetails('currency', AUSTRALIA_CURRENCY);
      handleCommissionDetails('percentage', 10);
      handleSpaceLocation('latitude', -37.971237, 25);
      handleSpaceLocation('longitude', 144.4926947, 25);
      handleSpaceLocation('countryCode', AUSTRALIA_SHORT_CODE);
      handleSpaceLocation('mapZoom', 8);
      dispatch(getCountryWiseEventType(AUSTRALIA_SHORT_CODE));
      dispatch(getPricingOptions(AUSTRALIA_SHORT_CODE));

      handleSpaceLocation('addressLine1', '');
      handleSpaceLocation('addressLine2', '');
      handleSpaceLocation('addressLine3', '');
      handleSpaceLocation('publicTransportAccess', 0.1);
    } else {
      dispatch(getTaxDetails(SINGAPORE_SHORT_CODE));
      dispatch(getAmenities(SINGAPORE_SHORT_CODE));
      dispatch(getRules(SINGAPORE_SHORT_CODE));
      handleTaxDetails('currency', SINGAPORE_CURRENCY);
      handleCommissionDetails('percentage', 10);
      handleSpaceLocation('latitude', 1.3521, 25);
      handleSpaceLocation('longitude', 103.8198, 25);
      handleSpaceLocation('countryCode', SINGAPORE_SHORT_CODE);
      handleSpaceLocation('mapZoom', 8);
      dispatch(getCountryWiseEventType(SINGAPORE_SHORT_CODE));
      dispatch(getPricingOptions(SINGAPORE_SHORT_CODE));
    }
    setSpaceLocation(routingObj);
    setProfileData(routingObj);
  };

  const handleSearch = (value, name) => {
    switch (name) {
      case 'streetName':
        handleSpaceLocation('streetName', value);
        dispatch(getLocations(value, getFieldCurrentValue('countryCode')));
        break;

      case 'addressLine3':
        handleSpaceLocation('addressLine3', value);
        dispatch(getLocationsAddress3(value, getFieldCurrentValue('countryCode')));
        break;

      default:
        handleSpaceLocation('streetName', value);
        dispatch(getLocations(value, getFieldCurrentValue('countryCode')));
        break;
    }
  };

  useEffect(() => {
    if (mapPinChanged?.time) {
      handleSpaceLocation('latitude', Number(mapPinChanged?.latitude), 25);
      handleSpaceLocation('longitude', Number(mapPinChanged?.longitude), 25);
    }
  }, [mapPinChanged]);

  useEffect(() => {
    if (mapZoomUpdated) {
      handleSpaceLocation('mapZoom', Number(mapZoomUpdated?.mapZoom), 25);
    }
  }, [mapZoomUpdated]);

  useEffect(() => {
    if (
      contentIsNotNull(getFieldCurrentValue('addressLine1'), 3) &&
      contentIsNotNull(getFieldCurrentValue('addressLine3'), 3) &&
      contentIsNotNull(getFieldCurrentValue('postcode'), 3)
    ) {
      dispatch(getLocationDetailsByAddress(address()));
    }
  }, [getFieldCurrentValue('postcode')]);

  const getLocationAddressCompleted = () => {
    if (
      contentIsNotNull(getFieldCurrentValue('streetName'), 0) &&
      contentIsNotNull(getFieldCurrentValue('postalCode'), 0, 15) &&
      contentIsNotNull(getFieldCurrentValue('city'), 0, 100) &&
      contentIsNotNull(getFieldCurrentValue('postalAddress'), 8)
    ) {
      dispatch(getLocationDetailsByAddress(address()));
    }
    if (
      contentIsNotNull(getFieldCurrentValue('addressLine1'), 3) &&
      contentIsNotNull(getFieldCurrentValue('addressLine3'), 3) &&
      contentIsNotNull(getFieldCurrentValue('postcode'), 3)
    ) {
      dispatch(getLocationDetailsByAddress(address()));
    }
  };


  const __renderAusAddressFields = () => {
    return (
      <>
        <TextField
          className="input-margin-wrapper"
          id="addressLine1"
          label="Address Line 1"
          lineDirection="center"
          errorText="This field is required."
          onChange={(value) => {
            handleSpaceLocation('addressLine1', value);
            getLocationAddressCompleted();
          }}
          value={getFieldCurrentValue('addressLine1')}
          // onBlur={() => getLocationAddressCompleted()}
        />

        <TextField
          className="input-margin-wrapper"
          id="addressLine2"
          label="Address Line 2 (Optional)"
          lineDirection="center"
          onChange={(value) => {
            handleSpaceLocation('addressLine2', value);
            getLocationAddressCompleted();
          }}
          value={getFieldCurrentValue('addressLine2')}
          // onBlur={() => getLocationAddressCompleted()}
        />

        <AutoComplete
          className={`street-name-selector ${getFieldCurrentValue('addressLine3')?.length > 0 ? 'placeHolderTop' : ''}`}
          defaultValue={getFieldCurrentValue('addressLine3')}
          onSearch={(val) => handleSearch(val, 'addressLine3')}
          placeholder="Suburb"
          onSelect={(values) => {
            setStateAndPostal(values);
            handleSpaceLocation('addressLine3', values?.split(',')?.[0]);
            handleSpaceLocation('postcode', values?.split(',')?.[1]);
          }}
        >
          {locationSuggestionsAdd3?.map((location) => (
            <Option value={location?.title}>{location?.title?.split(',')?.[0]}</Option>
          ))}
        </AutoComplete>

        <TextField
          className={`input-margin-wrapper ${getFieldCurrentValue('postcode')?.length > 0 ? 'placeHolderTop' : ''}`}
          id="postcode"
          label="State/Postcode"
          lineDirection="center"
          value={getFieldCurrentValue('postcode')}
          onChange={(value) => {
            handleSpaceLocation('postcode', value);
            getLocationAddressCompleted();
          }}
          defaultValue={getFieldCurrentValue('postcode')}
          // onBlur={() => getLocationAddressCompleted()}
        />
      </>
    );
  };

  const __renderPublicAccess = () => {
    return (
      <>
        <div className="flex-wrap mb-15">
          <span className="gray-text custom-gray-txt">PUBLIC TRANSPORT ACCESS</span>
          <hr className="divider cutome-gray-divider" />
        </div>
        <TextField
          className="input-margin-wrapper"
          id="subway"
          label="Nearest Railway Station"
          lineDirection="center"
          disabled={disable}
          onChange={(subwayname) => handleSpaceLocation('subwayname', subwayname)}
          value={getFieldCurrentValue('subwayname')}
        />
        <div className="position-relative">
          <TextField
            className="input-margin-wrapper"
            id="publicTransportAccess"
            type={'number'}
            disabled={disable}
            label="Distance to Nearest Public Transport (km)"
            lineDirection="center"
            errorText="This field is required."
            step="0.1"
            onChange={(value) => handleSpaceLocation('publicTransportAccess', distance)}
            value={getFieldCurrentValue('publicTransportAccess')}
          />
          <span className="superfix">km</span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="left-side-inner-wrapper space-location-wrapper">
        <h1 className="listing-page-title">Space Location</h1>
        <Select
          className={`input-margin-wrapper ${getFieldCurrentValue('spaceCountry')?.length > 0 ? 'placeHolderTop' : ''} country-disable `}
          value={getFieldCurrentValue('spaceCountry')}
          placeholder={'Country'}
          dropdownClassName="space-listing-drop-down "
          onSelect={(value) => handleCountryAndMap(value)}
          defaultValue={countryLongCode}
          disabled
        >
          <Option value={SRI_LANKA}>Sri Lanka</Option>
        </Select>

        
        {getFieldCurrentValue('spaceCountry') === AUSTRALIA && __renderAusAddressFields()}

        {getFieldCurrentValue('spaceCountry') !== AUSTRALIA && (
          <div>
            <TextField
              className="input-margin-wrapper"
              id="city"
              label="City"
              lineDirection="center"
              errorText="This field is required."
              onChange={(value) => handleSpaceLocation('city', value)}
              value={getFieldCurrentValue('city')}
              onBlur={() => getLocationAddressCompleted()}
            />

            <TextField
              className="input-margin-wrapper"
              id="city"
              label="Postal Code"
              lineDirection="center"
              errorText="This field is required."
              onChange={(value) => handleSpaceLocation('postalCode', value)}
              value={getFieldCurrentValue('postalCode')}
              onBlur={() => getLocationAddressCompleted()}
            />

            <AutoComplete
              className={`street-name-selector ${getFieldCurrentValue('streetName')?.length > 0 ? 'placeHolderTop' : ''}`}
              defaultValue={getFieldCurrentValue('streetName')}
              onSearch={(val) => handleSearch(val, 'streetName')}
              placeholder="Street Name"
              value={getFieldCurrentValue('streetName')}
              onSelect={(values) => {
                handleSpaceLocation('streetName', values, 500);
                getLocationAddressCompleted();
              }}
            >
              {filteredLocations.map((location) => (
                <Option value={location}>
                  {location}
                </Option>
              ))}
            </AutoComplete>

            <hr className="divider" />
            <TextField
              className="input-margin-wrapper"
              id="postalAddress"
              label="Postal Address"
              lineDirection="center"
              errorText="This field is required."
              onChange={(value) => handleSpaceLocation('postalAddress', value)}
              value={getFieldCurrentValue('postalAddress')}
              onBlur={() => getLocationAddressCompleted()}
            />
          </div>
        )}

        {/*<hr className="divider" />
          <TextField
            className="input-margin-wrapper"
            id="latitude"
            label="Latitude"
            lineDirection="center"
            type={"number"}
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("latitude", Number(value), 25)}
            value={getFieldCurrentValue("latitude")}
          />

          <TextField
            className="input-margin-wrapper"
            id="longitude"
            label="Longitude"
            lineDirection="center"
            type={"number"}
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("longitude", Number(value), 25)}
            value={getFieldCurrentValue("longitude")}
          /> */}

        {getFieldCurrentValue('spaceCountry') === AUSTRALIA ? __renderPublicAccess() : <hr className="divider" />}

        {getFieldCurrentValue('spaceCountry') === SINGAPORE ? (
          <div>
            <Select
              className={`input-margin-wrapper pricing-options input-mrt ${getFieldCurrentValue('mrtStatus')?.length > 0 ? 'placeHolderTop' : ''}`}
              dropdownClassName="space-listing-drop-down"
              placeholder="MRT Status"
              onChange={(value) => handleSpaceLocation('mrtStatus', value)}
              value={getFieldCurrentValue('mrtStatus')}
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>

            {getFieldCurrentValue('mrtStatus') && (
              <Select
                className={`input-margin-wrapper pricing-options ${getFieldCurrentValue('mrtSections')?.length > 0 ? 'placeHolderTop' : ''}`}
                dropdownClassName="space-listing-drop-down"
                mode="multiple"
                allowClear
                placeholder="MRT Locations"
                onChange={(value) => handleSpaceLocation('mrtSections', value)}
                value={getFieldCurrentValue('mrtSections')}
              >
                {mrtSections.map((value) => (
                  <Option key={value || 'Error Occurred'}>{value}</Option>
                ))}
              </Select>
            )}
          </div>
        ) : (
          <div>
            {getFieldCurrentValue('spaceCountry') !== AUSTRALIA && (
              <Select
                className={`input-margin-wrapper parking-available-input ${
                  getFieldCurrentValue('isParkingAvailable')?.length > 0 ? 'placeHolderTop' : ''
                }`}
                dropdownClassName="space-listing-drop-down"
                placeholder="Parking Available"
                onChange={(value) => handleSpaceLocation('isParkingAvailable', value)}
                value={getFieldCurrentValue('isParkingAvailable')}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            )}
          </div>
        )}
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default SpaceLocation;

import {
  FETCH_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILS,
  REMOVE_AUTH_DETAILS,
  CHANGE_LOGIN_VISIBILITY,
  TOKEN_SUBMIT,
  TOKEN_SUBMIT_ERROR,
  TOKEN_SUBMIT_SUCCESS,
  SEND_FORGOT_PASSWORD_REQUEST_ERROR,
  SEND_FORGOT_PASSWORD_REQUEST_SUCCESS,
  SEND_VERIFY_PASSWORD_ERROR,
  SEND_VERIFY_PASSWORD_SUCCESS,
  COUNTRYCODE_SUBMIT_SUCCESS,
  MOBILE_NUMBER_SUBMIT,
  MOBILE_NUMBER_SUBMIT_ERROR,
  MOBILE_NUMBER_SUBMIT_SUCCESS,
  OTP_SUBMIT,
  OTP_SUBMIT_ERROR,
  OTP_SUBMIT_SUCCESS,
  GET_USER_DETAIL_BY_TOKEN_SUCCESS,
  GET_USER_DETAIL_BY_TOKEN_ERROR,
  UPDATE_USER_DETAILS,
  UPDATE_FORGOT_PASSWORD_TOKEN,
  SUBMIT_USER_DETAILS_EMAIL_SENT,
  TOKEN_ERROR_REMOVE,
  RESEND_EMAIL_VERIFICATION,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_ERROR,
  REMOVE_RESEND_EMAIL_VERIFICATION_DETAILS,
  REMOVE_LOGIN_FAILS, REMOVE_FORGOT_PASSWORD_TOKEN, SKIP_MOBILE_NUMBER, CLEAR_TOKEN, CLEAR_VERIFY_PASSWORD
} from './authActionTypes';

import { SUBMIT_USER_DETAILS, SUBMIT_USER_DETAILS_SUCCESS, SUBMIT_USER_DETAILS_FAILS } from '../actionTypes';

export const authReducer = (
  state = {
    loginDetails: {
      isDataAvailable:false,
      fetching: false,
      error: { status: false, message: '',errorCode:'',errorData:''},
      data: [],
      isRemoveAuth: false,
      isLoginModalVisible: false
    },
    registrationDetails: {
      fetching: false,
      error: { status: false, message: '' },
      data: [],
      isRemoveAuth: false,
      isEmailSent:false
    },
    tokenDetails: {
      token:'',
      isTokenDataAvailable:false,
      isTokenFetching: false,
      tokenError: {message: ""},
      tokenData: [],
      tokenError_Status: false,
    },
    resendEmailDetails:{
      message:'',
      isResend:false,
      error:false,
    },
    forgotPasswordDetails: {
      forgotPasswordToken:'',
      isResetEmailSent: false,
      resetEmailError: '',
      isPasswordChanged: false,
      passwordChangeError: '',
    },
    otpDetails: {
      isFetchingOTP: false,
      isFetchingMobile: false,
      isOtpSuccess: false,
      isMobileSuccess:false,
      otpError: [],
      mobileError: [],
      otpData: null,
      mobileData: [],
    },
    countryListDetails:{
      countryList: [],
    },
    userDetailsByToken:{
      details:{}
    },
    userDetails:{
      details:{}
    },
    mobile : {
      isSkipMobileNumberVerification : false
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_LOGIN:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          fetching: true,
          error: { status: false, message: '',errorCode:'' },
          isRemoveAuth: false
        }
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          isDataAvailable:true,
          fetching: false,
          error: { status: false, message: '',errorCode:'' },
          data: action.payload,
        }
      };

    case LOGIN_FAILS:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          isDataAvailable:false,
          fetching: false,
          error: { status: true, message: action.payload.response.data.message ,errorCode:action.payload.response.data.errorCode, errorData: action.payload.response.data}
        }
      };
    case REMOVE_LOGIN_FAILS:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          error: { status: false, message: '' ,errorCode:'', errorData: {}}
        },
        forgotPasswordDetails: {}
      };

    case CHANGE_LOGIN_VISIBILITY:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          isLoginModalVisible: !state.loginDetails.isLoginModalVisible
        }
      };

    case REMOVE_AUTH_DETAILS:
      return {
        ...state,
        loginDetails: {
          ...state.loginDetails,
          isDataAvailable:false,
          fetching: false,
          error: { status: false, message: '' },
          data: [],
          isRemoveAuth: true
        },
        tokenDetails: {
          ...state.tokenDetails,
          isDataAvailable:false,
          fetching: false,
          error: { status: false, message: '' },
          data: [],
          isRemoveAuth: true,
          isLoginModalVisible: false
        },
        userDetailsByToken:{
          details:{}
        },
      };

    case SUBMIT_USER_DETAILS:
      return {
        ...state,
        registrationDetails: {
          ...state.registrationDetails,
          fetching: true,
          error: { status: false, message: '' },
          data: []
        }
      };

    case SUBMIT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        registrationDetails: {
          ...state.registrationDetails,
          fetching: false,
          error: { status: false, message: '' },
          data: action.payload
        }
      };

    case SUBMIT_USER_DETAILS_FAILS:
      return {
        ...state,
        registrationDetails: {
          ...state.registrationDetails,
          fetching: false,
          error: { status: false, message: action.payload },
          data: []
        }
      };
    case SUBMIT_USER_DETAILS_EMAIL_SENT:
      return{
        ...state,
        registrationDetails: {
          ...state.registrationDetails,
          isEmailSent: true,
        }
      };
    case TOKEN_SUBMIT:
      return {
        ...state,
        tokenDetails: {
          ...state.tokenDetails,
          token:action.payload,
          isTokenFetching: true,
          tokenError: {message: ""},
          tokenData: [],
          tokenError_Status: false,
        }
      };
    case TOKEN_SUBMIT_SUCCESS:
      return {
        ...state,
        tokenDetails: {
          ...state.tokenDetails,
          isTokenDataAvailable:true,
          isTokenFetching: false,
          tokenError: {message: ""},
          tokenData:  action.payload,
          tokenError_Status: false,
        }
      };
    case TOKEN_SUBMIT_ERROR:
      return {
        ...state,
        tokenDetails: {
          isTokenDataAvailable:false,
          isTokenFetching: false,
          tokenError: action?.payload?.response?.data,
          tokenData: [],
          tokenError_Status: true
        }
      };
    case TOKEN_ERROR_REMOVE:
      return {
        ...state,
        tokenDetails: {
          tokenError: {},
          tokenError_Status: false
        }
      };
    case CLEAR_TOKEN:
      return {
        ...state,
        tokenDetails: {
          token:'',
          isTokenDataAvailable:false,
          isTokenFetching: false,
          tokenError: {message: ""},
          tokenData: [],
          tokenError_Status: false,
        }
      };
    case SEND_FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          isResetEmailSent: action.payload ? true : false,
          resetEmailError: '',
          isPasswordChanged: false,
          passwordChangeError: '',
        }
      };
    case SEND_FORGOT_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          isResetEmailSent: false,
          resetEmailError: {...action?.payload?.response?.data,time:new Date()},
          isPasswordChanged: false,
          passwordChangeError: '',
        }
      };
    case UPDATE_FORGOT_PASSWORD_TOKEN:
      return {
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          forgotPasswordToken : action.payload,
          isResetEmailSent: false,
          resetEmailError: action?.payload?.response?.data,
          isPasswordChanged: false,
          passwordChangeError: '',
        }
      };
    case SEND_VERIFY_PASSWORD_SUCCESS:
      return{
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          isResetEmailSent: false,
          resetEmailError: '',
          isPasswordChanged: true,
          passwordChangeError: '',
        },
      };
    case SEND_VERIFY_PASSWORD_ERROR:
      return{
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          isResetEmailSent: false,
          resetEmailError: '',
          isPasswordChanged: false,
          passwordChangeError: action?.payload?.response?.data,
        }
      };

    case MOBILE_NUMBER_SUBMIT:
      return {
        ...state,
        otpDetails: {
          ...state.otpDetails,
          isFetchingOTP: false,
          isFetchingMobile: true,
          isOtpSuccess: false,
          isMobileSuccess:false,
          otpError: [],
          mobileError: [],
          otpData: [],
          mobileData: [],
        }
      };
    case MOBILE_NUMBER_SUBMIT_SUCCESS:
      return {
        ...state,
        otpDetails: {
          ...state.otpDetails,
          isFetchingOTP: false,
          isFetchingMobile: false,
          isOtpSuccess: false,
          isMobileSuccess:true,
          otpError: [],
          mobileError: [],
          otpData: [],
          mobileData: action.payload,
        }
      };
    case MOBILE_NUMBER_SUBMIT_ERROR:
      return {
        ...state,
        otpDetails: {
          isFetchingOTP: false,
          isFetchingMobile: false,
          isOtpSuccess: false,
          isMobileSuccess:false,
          otpError: [],
          mobileError: action.payload.response.data,
          otpData: [],
          mobileData: [],
        }
      };
    case OTP_SUBMIT:
      return{
        ...state,
        otpDetails: {
          isFetchingOTP: true,
          isFetchingMobile: false,
          isOtpSuccess: false,
          otpError: [],
          mobileError: [],
          otpData: [],
          mobileData: [],
        }
      };
    case OTP_SUBMIT_SUCCESS:
      return{
        ...state,
        otpDetails: {
          isFetchingOTP: false,
          isFetchingMobile: false,
          isOtpSuccess: true,
          otpError: [],
          mobileError: [],
          otpData: action.payload,
          mobileData: [],
        }
      };
    case OTP_SUBMIT_ERROR:
      return{
        ...state,
        otpDetails: {
          isFetchingOTP: false,
          isFetchingMobile: false,
          isOtpSuccess: false,
          otpError: action?.payload?.response?.data,
          mobileError: [],
          otpData: [],
          mobileData: [],
        }
      };

    case COUNTRYCODE_SUBMIT_SUCCESS:
      return{
        ...state,
        countryListDetails:{
          ...state.countryList,
          countryList: action.payload,
        }
      };

    case GET_USER_DETAIL_BY_TOKEN_SUCCESS:
      return{
        ...state,
        userDetailsByToken: {
          ...state.userDetailsByToken,
          details: action.payload
        }
      };

    case GET_USER_DETAIL_BY_TOKEN_ERROR:
      return{
        ...state,
        userDetailsByToken: {
          ...state.userDetailsByToken,
          email: null,
          hasPhoneNumber: null,
          hasVerified: null
        }
      };
    case UPDATE_USER_DETAILS:
      return{
        ...state,
        userDetails: {
          ...state.userDetails,
          details:action.payload,
        }
      };
    case RESEND_EMAIL_VERIFICATION :
      return{
        ...state,
        resendEmailDetails: {
          ...state.resendEmailDetails,
          message:'',
          error:false,
        }
      };
    case RESEND_EMAIL_VERIFICATION_SUCCESS :
      return{
        ...state,
        resendEmailDetails: {
          ...state.resendEmailDetails,
          message:action.payload.message,
          isResend:true,
          error:false,
        }
      };
    case RESEND_EMAIL_VERIFICATION_ERROR :
      return{
        ...state,
        resendEmailDetails: {
          ...state.resendEmailDetails,
          message:'',
          isResend:false,
          error:true,
        }
      };
    case REMOVE_RESEND_EMAIL_VERIFICATION_DETAILS:
      return{
        ...state,
        resendEmailDetails: {
          ...state.resendEmailDetails,
          message:'',
          isResend:false,
          error:false,
        }
      };

    case REMOVE_FORGOT_PASSWORD_TOKEN:
      return{
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          forgotPasswordToken:''
        },
      };
    case SKIP_MOBILE_NUMBER:
      return{
        ...state,
        mobile: {
          ...state.mobile,
          isSkipMobileNumberVerification: action.payload
        },
      };
    case CLEAR_VERIFY_PASSWORD:
      return{
        ...state,
        forgotPasswordDetails: {
          ...state.forgotPasswordDetails,
          isPasswordChanged: false,
          forgotPasswordToken:''
        },
      };
    default:
      return state;
  }
};

export const FETCH_FEATURED_SPACES = 'FETCH_FEATURED_SPACES';
export const FETCH_FEATURED_SPACES_SUCCESS = 'FETCH_FEATURED_SPACES_SUCCESS';
export const FETCH_FEATURED_SPACES_FAILS = 'FETCH_FEATURED_SPACES_FAILS';
export const SET_FILTERS = 'SET_FILTERS';

export const PROFILE_SUBMIT = 'PROFILE_SUBMIT';

export const FETCH_SPACES_DATA = 'FETCH_SPACES_DATA';
export const FETCH_SPACES_DATA_SUCCESS = 'FETCH_SPACES_DATA_SUCCESS';
export const FETCH_SPACES_DATA_FAILS = 'FETCH_SPACES_DATA_FAILS';

export const FETCH_ALL_SPACES = 'FETCH_ALL_SPACES';
export const FETCH_ALL_SPACES_SUCCESS = 'FETCH_ALL_SPACES_SUCCESS';
export const FETCH_ALL_SPACES_FAILS = 'FETCH_ALL_SPACES_FAILS';

export const FETCH_SEARCH_ITEMS = 'FETCH_SEARCH_ITEMS';
export const FETCH_SEARCH_ITEMS_SUCCESS = 'FETCH_SEARCH_ITEMS_SUCCESS';
export const FETCH_SEARCH_ITEMS_FAILS = 'FETCH_SEARCH_ITEMS_FAILS';

export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAILS = 'FETCH_LOCATION_FAILS';

export const POST_VENUE_VALUE = 'POST_VENUE_VALUE';

export const FETCH_SEARCH_RESULT_FILTER_DATA = 'FETCH_SEARCH_RESULT_FILTER_DATA';
export const FETCH_SEARCH_RESULT_FILTER_DATA_SUCCESS = 'FETCH_SEARCH_RESULT_FILTER_DATA_SUCCESS';
export const FETCH_SEARCH_RESULT_FILTER_DATA_FAILS = 'FETCH_SEARCH_RESULT_FILTER_DATA_FAILS';

export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';
export const UPDATE_MORE_SEARCH_FILTER = 'UPDATE_MORE_SEARCH_FILTER';

export const SET_MORE_FILTERS = 'SET_MORE_FILTERS';

export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const SET_MORE_SEARCH_FILTER = 'SET_MORE_SEARCH_FILTER';

export const FETCH_SEARCH_RESULTS_DATA = 'FETCH_SEARCH_RESULTS_DATA';
export const FETCH_SEARCH_RESULTS_DATA_SUCCESS = 'FETCH_SEARCH_RESULTS_DATA_SUCCESS';
export const FETCH_SEARCH_RESULTS_DATA_FAILS = 'FETCH_SEARCH_RESULTS_DATA_FAILS';

export const FETCH_HOME_PAGE_DATA = 'FETCH_HOME_PAGE_DATA';
export const FETCH_HOME_PAGE_DATA_SUCCESS = 'FETCH_HOME_PAGE_DATA_SUCCESS';
export const FETCH_HOME_PAGE_DATA_FAILS = 'FETCH_HOME_PAGE_DATA_FAILS';
export const FETCH_MY_PROFILE_DATA = 'FETCH_MY_PROFILE_DATA';
export const FETCH_MY_PROFILE_SUCCESS = 'FETCH_MY_PROFILE_SUCCESS';
export const FETCH_MY_PROFILE_FAILS = 'FETCH_MY_PROFILE_FAILS';

export const FETCH_MY_BOOKINGS_DATA = 'FETCH_MY_BOOKINGS_DATA';
export const FETCH_MY_BOOKINGS_SUCCESS = 'FETCH_MY_BOOKINGS_SUCCESS';
export const FETCH_MY_BOOKINGS_FAILS = 'FETCH_MY_BOOKINGS_FAILS';

export const FETCH_MY_FAVOURITES_DATA = 'FETCH_MY_FAVOURITES_DATA';
export const FETCH_MY_FAVOURITES_SUCCESS = 'FETCH_MY_FAVOURITES_SUCCESS';
export const FETCH_MY_FAVOURITES_FAILS = 'FETCH_MY_FAVOURITES_FAILS';

export const TOGGLE_MY_FAVOURITES = 'TOGGLE_MY_FAVOURITES';
export const TOGGLE_MY_FAVOURITES_SUCCESS = 'TOGGLE_MY_FAVOURITES_SUCCESS';
export const TOGGLE_MY_FAVOURITES_FAILS = 'TOGGLE_MY_FAVOURITES_FAILS';

export const FETCH_COUNTRY_DIALING_CODES = 'FETCH_COUNTRY_DIALING_CODES';
export const FETCH_COUNTRY_DIALING_CODES_SUCCESS = 'FETCH_COUNTRY_DIALING_CODES_SUCCESS';
export const FETCH_COUNTRY_DIALING_CODES_FAILS = 'FETCH_COUNTRY_DIALING_CODES_FAILS';

export const REMOVE_COUNTRY_DIALING_CODES = 'REMOVE_COUNTRY_DIALING_CODES';
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER';
export const CLEAR_MORE_SEARCH_FILTER = 'CLEAR_MORE_SEARCH_FILTER';
export const SET_FILTER_OPEN = 'SET_FILTER_OPEN';
export const SET_FILTER_CLOSE = 'SET_FILTER_CLOSE';

export const SUBMIT_USER_DETAILS = 'SUBMIT_USER_DETAILS';
export const SUBMIT_USER_DETAILS_SUCCESS = 'SUBMIT_USER_DETAILS_SUCCESS';
export const SUBMIT_USER_DETAILS_FAILS = 'SUBMIT_USER_DETAILS_FAILS';

export const REFRESH_SPACES_DATA_SUCCESS = 'REFRESH_SPACES_DATA_SUCCESS';
export const SET_CLEAR_ALL_SEARCH_FILTERS = 'SET_CLEAR_ALL_SEARCH_FILTERS';
export const SET_CLEAR_ALL_SEARCH_FILTERS_DONE = 'SET_CLEAR_ALL_SEARCH_FILTERS_DONE';

export const CLEAR_ALL_SEARCH_RESULTS_DATA_SUCCESS = 'CLEAR_ALL_SEARCH_RESULTS_DATA_SUCCESS';

export const FILTER_CHANGE_DONE = 'FILTER_CHANGE_DONE';

export const FETCH_SPACE_ACTIVITIES = 'FETCH_SPACE_ACTIVITIES';
export const FETCH_SPACE_ACTIVITIES_SUCCESS = 'FETCH_SPACE_ACTIVITIES_SUCCESS';
export const FETCH_SPACE_ACTIVITIES_FAILS = 'FETCH_SPACE_ACTIVITIES_FAILS';

export const FETCH_COUNTRY_SHORT_CODE = "FETCH_COUNTRY_SHORT_CODE";
export const FETCH_COUNTRY_SHORT_CODE_SUCCESS = "FETCH_COUNTRY_SHORT_CODE_SUCCESS";
export const FETCH_COUNTRY_SHORT_CODE_FAILS = "FETCH_COUNTRY_SHORT_CODE_FAILS";

export const ASSIGN_COUNTRY_SHORT_CODE = "ASSIGN_COUNTRY_SHORT_CODE";

export const FETCH_PRICE_RANGE = "FETCH_PRICE_RANGE";
export const FETCH_PRICE_RANGE_SUCCESS = "FETCH_PRICE_RANGE_SUCCESS";
export const FETCH_PRICE_RANGE_FAILS = "FETCH_PRICE_RANGE_FAILS";

export const FETCH_STRIPE_CLIENT_SECRET = 'FETCH_STRIPE_CLIENT_SECRET';
export const FETCH_STRIPE_CLIENT_SECRET_SUCCESS = 'FETCH_STRIPE_CLIENT_SECRET_SUCESS';
export const FETCH_STRIPE_CLIENT_SECRET_FAILS = 'FETCH_STRIPE_CLIENT_SECRET_FAILS';

export const FETCH_STRIPE_CARDS = 'FETCH_STRIPE_CARDS';
export const FETCH_STRIPE_CARDS_SUCCESS = 'FETCH_STRIPE_CARDS_SUCCESS';
export const FETCH_STRIPE_CARDS_FAILS = 'FETCH_STRIPE_CARDS_FAILS';

export const SELECT_STRIPE_CARD = 'SELECT_STRIPE_CARD';

export const UPDATE_MOUSE_OVER = 'UPDATE_MOUSE_OVER';

export const SUBMIT_SUBSCRIBER_DETAILS = 'SUBMIT_SUBSCRIBER_DETAILS';
export const SUBMIT_SUBSCRIBER_DETAILS_SUCCESS = 'SUBMIT_SUBSCRIBER_DETAILS_SUCCESS';
export const SUBMIT_SUBSCRIBER_DETAILS_FAILS = 'SUBMIT_SUBSCRIBER_DETAILS_FAILS';
export const CLEAR_SUBSCRIBER_DETAILS = 'CLEAR_SUBSCRIBER_DETAILS';
export const CLEAR_SUBSCRIBER_DETAILS_SUCCESS = 'CLEAR_SUBSCRIBER_DETAILS_SUCCESS';

export const FETCH_PLACE_LAT_LONG = 'FETCH_PLACE_LAT_LONG';
export const FETCH_PLACE_LAT_LONG_SUCCESS = 'FETCH_PLACE_LAT_LONG_SUCCESS';
export const FETCH_PLACE_LAT_LONG_FAILS = 'FETCH_PLACE_LAT_LONG_FAILS';

export const FETCH_OBJECT_HOME_OBJ_DATA = 'FETCH_OBJECT_HOME_OBJ_DATA';
export const FETCH_OBJECT_HOME_OBJ_DATA_SUCCESS = 'FETCH_OBJECT_HOME_OBJ_DATA_SUCCESS';
export const FETCH_OBJECT_HOME_OBJ_DATA_FAILS = 'FETCH_OBJECT_HOME_OBJ_DATA_FAILS';

export const FETCH_SEARCH_AUTO_COMPLETE_DATA = 'FETCH_SEARCH_AUTO_COMPLETE_DATA';
export const FETCH_SEARCH_AUTO_COMPLETE_DATA_SUCCESS = 'FETCH_SEARCH_AUTO_COMPLETE_DATA_SUCCESS';
export const FETCH_SEARCH_AUTO_COMPLETE_DATA_FAILS = 'FETCH_SEARCH_AUTO_COMPLETE_DATA_FAILS';
export const CLEAR_SEARCH_AUTO_COMPLETE_DATA = 'CLEAR_SEARCH_AUTO_COMPLETE_DATA';

export const UPDATE_COUNTRY_LONG_CODE = "UPDATE_COUNTRY_LONG_CODE";

// update the meta data
export const FETCH_META_DATA = "FETCH_META_DATA";
export const FETCH_META_DATA_SUCCESS = "FETCH_META_DATA_SUCCESS";
export const FETCH_META_DATA_FAILS = "FETCH_META_DATA_FAILS";

export const FETCH_MRT = 'FETCH_MRT';
export const FETCH_MRT_SUCCESS = 'FETCH_MRT_SUCCESS';
export const FETCH_MRT_FAILS = 'FETCH_MRT_FAILS';

export const CHANGE_FILTER_USED_STATE = 'CHANGE_FILTER_USED_STATE';

export const CLEAR_ALL = 'CLEAR_ALL';
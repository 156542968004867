export const FETCH_MORE_REVIEW = 'FETCH_MORE_REVIEW';
export const FETCH_MORE_REVIEW_SUCCESS = 'FETCH_MORE_REVIEW_SUCCESS';
export const FETCH_MORE_REVIEW_FAILS = 'FETCH_MORE_REVIEW_FAILS';

export const PUT_FAVORITE_SUCCESS = 'PUT_FAVORITE_SUCCESS';
export const PUT_FAVORITE_FAILS = 'PUT_FAVORITE_FAILS';

export const FETCH_SPACE_PROFILE = 'FETCH_SPACE_PROFILE';
export const FETCH_SPACE_PROFILE_SUCCESS = 'FETCH_SPACE_PROFILE_SUCCESS';
export const FETCH_SPACE_PROFILE_FAILS = 'FETCH_SPACE_PROFILE_FAILS';

export const FETCH_SIMILAR_SPACE = 'FETCH_SIMILAR_SPACE';
export const FETCH_SIMILAR_SPACE_SUCCESS = 'FETCH_SIMILAR_SPACE_SUCCESS';
export const FETCH_SIMILAR_SPACE_FAILS = 'FETCH_SIMILAR_SPACE_FAILS';

export const FETCH_PAYMENT_TOKEN = 'FETCH_PAYMENT_TOKEN';
export const FETCH_PAYMENT_TOKEN_SUCCESS = 'FETCH_PAYMENT_TOKEN_SUCCESS';
export const FETCH_PAYMENT_TOKEN_FAILS = 'FETCH_PAYMENT_TOKEN_FAILS';

export const PAYMENT_SUBMIT = 'PAYMENT_SUBMIT';
export const PAYMENT_SUBMIT_SUCCESS = 'PAYMENT_SUBMIT_SUCCESS';
export const PAYMENT_SUBMIT_FAILS = 'PAYMENT_SUBMIT_FAILS';

export const UPDATE_PACKAGES = 'UPDATE_PACKAGES';


//**This should move to const file
export const SPACE_STATUS = {
    ACTIVE : 'ACTIVE',
    PENDING : 'PENDING',
    INACTIVE : 'INACTIVE',
    DECLINED : 'DECLINED'
}
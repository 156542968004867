import { CLEAR_DATA_SUBMIT_HOST, FETCH_DATA_SUBMIT_FAILS_HOST, FETCH_DATA_SUBMIT_HOST, FETCH_DATA_SUBMIT_SUCCESS_HOST} from './HostOnboardingActionTypes';

export const hostOnBoardingReducer = (
  state = {
    fetching: false,
    error: { status: false, message: '' },
    data: [],
    similarSpaces: []
  },
  action
) => {
  switch (action.type) {
    case FETCH_DATA_SUBMIT_HOST:
      return {
        ...state,
        fetching: true,
        dataObject: {}
      };

    case FETCH_DATA_SUBMIT_SUCCESS_HOST:
      return {
        ...state,
        fetching: false,
        submitResponse: action.payload
      };
    case FETCH_DATA_SUBMIT_FAILS_HOST:
      return {
        ...state,
        fetching: false,
        submitResponse: action.payload
      };
    case CLEAR_DATA_SUBMIT_HOST:
      return {
        ...state,
        fetching: false,
        submitResponse: {}
      };
    default:
      return state;
  }
};

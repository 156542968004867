import React from 'react';
import { useSelector } from 'react-redux';
import NearestSpacesMap from '../../../../Common/NearestSpacesMap';
import { googleMapAPIKey } from '../../../../../settings';
import { MAP_SIZE } from './mapCommonConstant';

const ResultNearestSpaces = () => {
      const loadingAndContainer = <div className="map-container" style={{ height: `100%` }} />;
      const mapElement = <div style={{ height: `100vh`, width:`100%`}} />;
      const spacesData = useSelector((state) =>state?.searchResult?.spaces?.data?.spaces || []);
      const markerData = useSelector((state) => state?.searchResult?.infoWindow || {lng:'', lat:'', currencyType:'', price:''});
      const markerColor = useSelector((state) => state?.searchResult?.colorChange || false);

return (
  <>
      <div className="space-property-wrap nearest-space-wrap">
        <div className="wrap">
          <div className="content" style={{display:"flex", flexDirection:"column"}}>
              <NearestSpacesMap
                markerColor={markerColor}
                markerData={markerData}
                center={{ lat:spacesData[0]?.location?.y , lng: spacesData[0]?.location?.x }}
                mapSize={MAP_SIZE}
                marker={true}
                places={spacesData}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapAPIKey}`}
                loadingElement={loadingAndContainer}
                containerElement={loadingAndContainer}
                mapElement={mapElement}
              />
          </div>
        </div>
      </div>
  </>
  );
};

export default ResultNearestSpaces;
import axios from 'axios';
import { AUTH_URL, BASE_URL } from '../settings';
import { register_user } from './resources';

const URL = login => login ? AUTH_URL : `${BASE_URL}${register_user}`

export const _post = (payload, login) =>
  axios
    .post(URL(login), payload)
    .then(res => {
      return res;
    })
    .catch(error => {
      throw error;
    });


import { filter, spaceActivities, priceRange, filterMap, allMapSpaces, featuredMap } from '../../api/resources';
import * as actions from '../../redux/actionTypes';

export const getSearchResultFilters = (spaceCategory, eventType, countryCode, location) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SEARCH_RESULT_FILTER_DATA
    });

    // backend sends empty array of filters when reqested params are binded.

    axiosInstance.get(`${filter}?spaceCategory=${spaceCategory}&eventType=${eventType}&country=${countryCode}`)
      .then(res => {

        const includesPriceFilter = res.data[0].uiFilters.filter(filter => filter.field == 'price')

        // *** if a price filter is included, fetch pricing for the eventType ***
        if (includesPriceFilter) {
          axiosInstance.get(`${priceRange}?eventType=${eventType}&country=${countryCode ? countryCode : 'SG'}`).then(
            pricingDetails => {
              // *** rounding the min max values ***
              const newMin = Math.round(pricingDetails.data.minPrice) - 1;
              const newMax = Math.round(pricingDetails.data.maxPrice) + 1;

              const uiFilterObject = res.data[0].uiFilters.map(filter => filter.field == 'price' ?
                { ...filter, curreny: pricingDetails.data.currency, value: [newMin, newMax], defaultValue: [newMin, newMax] } : filter)

              // *** update filters with new pricing details ***
              const updatedFilters = { ...res.data[0], uiFilters: uiFilterObject }

              dispatch({
                type: actions.FETCH_SEARCH_RESULT_FILTER_DATA_SUCCESS,
                payload: updatedFilters
              })
            }
          )
        }
        else {

          dispatch({
            type: actions.FETCH_SEARCH_RESULT_FILTER_DATA_SUCCESS,
            payload: res.data[0]
          })
        }
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SEARCH_RESULT_FILTER_DATA_FAILS,
          payload: error
        });
      });


  };
};

export const clearFilteredValue = (newValue, filterField) => {
  return {
    type: actions.CLEAR_SEARCH_FILTER,
    payload: newValue,
    filterField: filterField
  };
};

export const postFilteredValue = (newValue, filterField) => {
  return {
    type: actions.UPDATE_SEARCH_FILTER,
    payload: newValue,
    filterField: filterField
  };
};

export const postFilteredValuefromURL = (newValue, filterField) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    payload: newValue,
    filterField: filterField
  };
};

export const postMoreFilteredValue = (newValue, filterName, indexofMore) => {
  return {
    type: actions.UPDATE_MORE_SEARCH_FILTER,
    payload: newValue,
    filterName: filterName,
    indexofMore: indexofMore
  };
};


export const clearMoreFilteredValue = (newValue, filterName, indexofMore) => {
  return {
    type: actions.CLEAR_MORE_SEARCH_FILTER,
    payload: newValue,
    filterName: filterName,
    indexofMore: indexofMore
  };
};
export const postMoreFilteredValuefromURL = (newValue, filterName, indexofMore) => {
  return {
    type: actions.SET_MORE_SEARCH_FILTER,
    payload: newValue,
    filterName: filterName,
    indexofMore: indexofMore
  };
};



export const setFilters = data => {
  return dispatch => {
    dispatch({
      type: actions.SET_FILTERS,
      payload: data
    });
  };
};
export const setMoreFilters = data => {
  return dispatch => {
    dispatch({
      type: actions.SET_MORE_FILTERS,
      payload: data
    });
  };
};

export const setFilterOpen = () => {
  return {
    type: actions.SET_FILTER_OPEN
  };
};

export const setFilterClosed = () => {
  return {
    type: actions.SET_FILTER_CLOSE
  };
};

export const getSpacesData = (page, count, queryParams, lat, lon) => {
  const reqUrl = `${filterMap}?page=${page}&size=${count}&${queryParams.substring(1)}&x=${lon}&y=${lat}`;

  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SPACES_DATA
    });

    axiosInstance.get(reqUrl)
      .then(res => {
        dispatch({
          type: actions.FETCH_SPACES_DATA_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SPACES_DATA_FAILS,
          payload: error
        });

      });
  };
};

export const refreshSpacesData = (page, count, queryParams, lat, lon) => {
  const reqUrl = `${filterMap}?page=${page}&size=${count}&${queryParams}&x=${lon}&y=${lat}`;

  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SPACES_DATA
    });

    axiosInstance.get(reqUrl)
      .then(res => {
        dispatch({
          type: actions.REFRESH_SPACES_DATA_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SPACES_DATA_FAILS,
          payload: error
        });

      });
  };
};

export const refreshFeaturedSpaces = (country, code) => {
  const reqUrl = `${featuredMap}/${country}?code=${code}`;

  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SPACES_DATA
    });

    axiosInstance.get(reqUrl)
      .then(res => {
        dispatch({
          type: actions.REFRESH_SPACES_DATA_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SPACES_DATA_FAILS,
          payload: error
        });

      });
  };
};

export const getAllSpaces = (page, count, queryParams, lat, lon) => {
  const reqUrl = `${allMapSpaces}?${queryParams}&x=${lon}&y=${lat}`;

  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_ALL_SPACES
    });

    axiosInstance.get(reqUrl)
      .then(res => {
        dispatch({
          type: actions.FETCH_ALL_SPACES_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_ALL_SPACES_FAILS,
          payload: error
        });

      });
  };
};

export const setClearAllFilters = () => {
  return {
    type: actions.SET_CLEAR_ALL_SEARCH_FILTERS
  };
};

export const setClearAllFiltersDone = () => {
  return {
    type: actions.SET_CLEAR_ALL_SEARCH_FILTERS_DONE
  };
};

export const setClearAllSpacesandFilters = () => {
  return {
    type: actions.CLEAR_ALL_SEARCH_RESULTS_DATA_SUCCESS
  };
};

export const setFilterChangeCompleted = () => {
  return {
    type: actions.FILTER_CHANGE_DONE
  };
};

export const getSpaceActivities = (country) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_SPACE_ACTIVITIES
    });
    axiosInstance.get(`${spaceActivities}?country=${country}`)
      .then(res => {
        dispatch({
          type: actions.FETCH_SPACE_ACTIVITIES_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_SPACE_ACTIVITIES_FAILS,
          payload: error
        });
      });
  };
};

export const getMRTValues = (page, count, queryParams, lat, lon) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.FETCH_MRT
    });
    axiosInstance.get(`search/near-mrt?page=${page}&size=${count}&${queryParams}&x=${lon}&y=${lat}`)
      .then(res => {
          dispatch({
            type: actions.FETCH_MRT_SUCCESS,
            payload: res.data,
          });
        
      })
      .catch(error => {
        dispatch({
          type: actions.FETCH_MRT_FAILS,
          payload: error
        });
      });
  };
};
export const changeFilterUsedState = () => {
  return {
    type: actions.CHANGE_FILTER_USED_STATE
  };
};
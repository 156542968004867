import { notification } from 'antd';
import { redClose } from '../../BookingCancelation/BookingCancellationAssets';
import tick from '../../../assets/images/common/tick.svg';
import React from 'react';

export const showErrorMessage = (message) => {
  notification.open({
    className: 'booking-cancellation-notification',
    message: (<span className="title"> <img src={redClose} alt="arrow" className="icon" /> <span className="text">{ message }</span></span>),
   
  });
}
export const showErrorMessageCalendar = (message) => {
  notification.open({
    className:'calender-sync-msg-popup-main',
    message: (<div className='calender-sync-msg-popup' > <img src={redClose} alt="arrow" className="icon-error" /> <span className="title-calnder">{ message }</span></div>),
  });
}

export const showSuccessMessage = (message) => {
  notification.open({
    className: 'booking-cancellation-notification',
    message: (<span className="title"> <img src={tick} alt="arrow" className="icon" /> <span className="text">{ message }</span></span>),
   
  });
}
export const showSuccessMessageCalendar = (message) => {
  notification.open({
    className:'calender-sync-msg-popup-main',
    message: (<div className='calender-sync-msg-popup' > <img src={tick} alt="arrow" className="icon-error"/> <span className="title-calnder">{ message }</span></div>),
  });
}

export const dailyPricingOptionValidation = (days, selectedDays) => {
  if (
    selectedDays?.length === 0 ||
    (selectedDays?.length === 1 && selectedDays?.some(item=> item === "Public Holiday"))
  )
    return days;

  if (selectedDays?.some(item=> item === "Daily"))
    days = days?.filter((opt) => {
      if (opt === "Daily" || opt === "Public Holiday") {
        return opt;
      }
    });

  if (selectedDays?.some(item=> item === "Weekdays") )
    days = days?.filter((opt) => {
      if (opt !== "Monday" && opt !== "Tuesday" && opt !== "Wednesday" &&  opt !=="Thursday" &&  opt !=="Friday") {
        return opt;
      }
    });

  if (selectedDays?.some(item=> item === "Weekends") )
    days = days?.filter((opt) => {
      if (opt !== "Sunday" && opt !== "Saturday") {
        return opt;
      }
    });

  if (
    selectedDays?.some(item=> item === "Monday")||
    selectedDays?.some(item=> item === "Tuesday")||
    selectedDays?.some(item=> item === "Wednesday")||
    selectedDays?.some(item=> item === "Thursday")||
    selectedDays?.some(item=> item === "Friday")
  ) {
    days = days?.filter((opt) => {
      if (opt !== "Daily" && opt !== "Weekdays") {
        return opt;
      }
    });
  }

  if (
    selectedDays?.some(item=> item === "Saturday")||
    selectedDays?.some(item=> item === "Sunday")
  ) {
    days = days?.filter((opt) => {
      if (opt !== "Daily" && opt !== "Weekends") {
        return opt;
      }
    });
  }

  return days;
};

export const updateURLParameter = (url, param, paramVal) => {
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  let baseURL = tempArray[0];
  let additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i=0; i<tempArray.length; i++){
      if(tempArray[i].split('=')[0] != param){
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  let rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

export const updateHomeDataObject = (state, action) => {
  if(state?.homePageData){
    if([].concat(state?.homePageData)?.filter(value => value?.order === action.orderNumber)?.length > 0){
      return [].concat(state?.homePageData)?.map(value => value?.order === action.orderNumber ? {
        order: action.orderNumber,
        data: action.payload
      } : value)
    } else {
      return [{
        order: action.orderNumber,
        data: action.payload
      }].concat(state?.homePageData)
    }
  } else {
    return [{ order: action.orderNumber, data: action.payload }]
  }
}

import {geocodeByPlaceId, getLatLng, geocodeByAddress} from 'react-places-autocomplete';

export function getDetailsFromPlaceID(placeID) {
    return geocodeByPlaceId(placeID)
        .then(res => res)
        .catch(e => {
            throw e;
        });
}

export function getLatLngfromPlaceId (placeId) {
    return geocodeByPlaceId(placeId)
        .then(results => getLatLng(results[0]))
        .then(latLng => latLng)
        .catch(e => {
            throw e;
        });
}

export function getLatLngByAddress (address) {
    return geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => latLng)
        .catch(error => {throw error });
}
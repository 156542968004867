import { post } from '../../api/commonAPI';
import {FETCH_DATA_SUBMIT_FAILS_HOST, FETCH_DATA_SUBMIT_HOST,FETCH_DATA_SUBMIT_SUCCESS_HOST} from './HostOnboardingActionTypes';

export const submitHostOnboard = (payload) => {
  return dispatch => {
    dispatch({
      type: FETCH_DATA_SUBMIT_HOST
    });

    post(`user/host-onboard`,payload)
      .then(res => {
        dispatch({
          type: FETCH_DATA_SUBMIT_SUCCESS_HOST,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_DATA_SUBMIT_FAILS_HOST,
          payload: error
        });
      });
  };
};

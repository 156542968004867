import React, { useState, useEffect } from 'react';
import { DatePicker, Button } from 'antd';
import uuid from 'uuid';
import moment from 'moment';
import { DATE_FORMAT } from '../../Common/constants';

const DateFilter = ({ onReset, onSearch }) => {
  const { RangePicker } = DatePicker;
  const dateContainerId = `searchDateInputWrapperId_${uuid()}`;
  const [selectedDate, setSelectedDate] = useState(null);
  let dateArray = [];

  const getCalendarContainer = () => {
    return document.getElementById(dateContainerId);
  };

  const handleSearch = () => {
    selectedDate && onSearch(dateArray);
  };

  const handleReset = () => {
    setSelectedDate(null);
    onReset();
  };

  useEffect(() => {
    if (selectedDate !== null) {
      let startDate = moment(selectedDate[0]);
      let endDate = moment(selectedDate[1]).toDate();

      while (startDate <= endDate) {
        dateArray.push(moment(startDate).format(DATE_FORMAT));
        startDate = moment(startDate).add(1, 'days');
      }
    }
  }, [selectedDate]);

  return (
    <div className="report-dropdown-input">
      <div>
        <div id={dateContainerId} />
        <RangePicker
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          getPopupContainer={getCalendarContainer}
          format={DATE_FORMAT}
        />
      </div>
      <div className="mt-3 border-top">
        <Button className="btn-reset" onClick={handleReset}>Reset</Button>
        <Button className="btn-search" type="primary" disabled={!selectedDate} onClick={handleSearch}>
          Filter
      </Button>
      </div>
    </div>
  );
};

export default DateFilter;

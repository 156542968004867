import { AUSTRALIA, SINGAPORE, SINGAPORE_CURRENCY, SINGAPORE_SHORT_CODE, SRI_LANKA } from '../SpaceListingConstants';
//   '/all/dashboard/list-a-space/external_systems',
// '/all/dashboard/list-a-space/pricing_options/block/availability',
//   '/all/dashboard/list-a-space/pricing_options/block/packages',
//   '/all/dashboard/list-a-space/pricing_options/hourly/availability',
//   '/all/dashboard/list-a-space/pricing_options/hourly/packages',
//   '/all/dashboard/list-a-space/pricing_options/daily/availability',
//   '/all/dashboard/list-a-space/pricing_options/daily/packages',
//   '/all/dashboard/list-a-space/pricing_options/weekly/availability',
//   '/all/dashboard/list-a-space/pricing_options/weekly/packages',

export const spaceListingData = (country = 'Singapore') => {
  const profileListingData = JSON.parse(sessionStorage.getItem('profileListingData'));
  return {
    currentRoute: '',
    routeList:
      profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA
        ? [
            `/${country}/dashboard/list-a-space/welcome`,
            `/${country}/dashboard/list-a-space/login_details`,
            `/${country}/dashboard/list-a-space/space_details`,
            `/${country}/dashboard/list-a-space/space_location`,
            `/${country}/dashboard/list-a-space/uploading_images`,
            `/${country}/dashboard/list-a-space/space_and_notice`,
            // `/${country}/dashboard/list-a-space/tax_information`,
            `/${country}/dashboard/list-a-space/seating_and_common`,
            `/${country}/dashboard/list-a-space/amenities`,
            // `/${country}/dashboard/list-a-space/addons`,
            `/${country}/dashboard/list-a-space/rules`,
            `/${country}/dashboard/list-a-space/payout_details`,
            // `/${country}/dashboard/list-a-space/commission`,
            // `/${country}/dashboard/list-a-space/calendar_sync`,
            `/${country}/dashboard/list-a-space/agreement`,
            `/${country}/dashboard/list-a-space/Congratulations`,
          ]
        : profileListingData?.space_and_notice?.[0]?.category?.name === 'Shoot' ? 
        [
            `/${country}/dashboard/list-a-space/welcome`,
            `/${country}/dashboard/list-a-space/login_details`,
            `/${country}/dashboard/list-a-space/space_details`,
            `/${country}/dashboard/list-a-space/space_location`,
            `/${country}/dashboard/list-a-space/uploading_images`,
            `/${country}/dashboard/list-a-space/space_and_notice`,
            `/${country}/dashboard/list-a-space/tax_information`,
            `/${country}/dashboard/list-a-space/seating_and_common`,
            // `/${country}/dashboard/list-a-space/amenities`,
            // `/${country}/dashboard/list-a-space/addons`,
            `/${country}/dashboard/list-a-space/rules`,
            `/${country}/dashboard/list-a-space/payout_details`,
            `/${country}/dashboard/list-a-space/commission`,
            // `/${country}/dashboard/list-a-space/calendar_sync`,
            `/${country}/dashboard/list-a-space/agreement`,
            `/${country}/dashboard/list-a-space/Congratulations`,
          ] :
          [
            `/${country}/dashboard/list-a-space/welcome`,
            `/${country}/dashboard/list-a-space/login_details`,
            `/${country}/dashboard/list-a-space/space_details`,
            `/${country}/dashboard/list-a-space/space_location`,
            `/${country}/dashboard/list-a-space/uploading_images`,
            `/${country}/dashboard/list-a-space/space_and_notice`,
            `/${country}/dashboard/list-a-space/tax_information`,
            `/${country}/dashboard/list-a-space/seating_and_common`,
            `/${country}/dashboard/list-a-space/amenities`,
            // `/${country}/dashboard/list-a-space/addons`,
            `/${country}/dashboard/list-a-space/rules`,
            `/${country}/dashboard/list-a-space/payout_details`,
            `/${country}/dashboard/list-a-space/commission`,
            // `/${country}/dashboard/list-a-space/calendar_sync`,
            `/${country}/dashboard/list-a-space/agreement`,
            `/${country}/dashboard/list-a-space/Congratulations`,
          ],
    processStarted: false,
    login_details: [{ firstName: '', lastName: '', email: '', country: '', phoneNumbers: '' }],
    organization_details: [
      { organizationName: '', organizationWebsite: '', businessRegistrationNo: '', country: SINGAPORE, city: '', streetName: '', postalAddress: '' },
    ],
    space_details: [{ spaceTitle: '', abnNumber:'' ,description: '', organizationName: '', requestToBook: true }],
    space_location: [
      {
        spaceCountry: country,
        city: '',
        postalCode: '',
        streetName: '',
        postalAddress: '',
        addressLine3: null,
        postcode:null,
        latitude: 1.3521,
        longitude: 103.8198,
        mapZoom: 8,
        countryCode: SINGAPORE_SHORT_CODE,
        mrtStatus: false,
        mrtSections: [],
        railwayname: '',
        publicTransportAccess: 0.0,
        isParkingAvailable: false,
      },
    ],
    external_systems: [{ status: false, systemName: '', spaceReferenceId: '' }],
    uploading_images: [{ selectedImage: {}, uploadedImages: [] }],
    space_and_notice: [{ category: {}, activity: [], excludedActivities: [], noticePeriod: '' }],
    tax_information: [{ status: false, currency: SINGAPORE_CURRENCY, registrationNumber: '', applicableTaxes: [], LKR: [], SGD: [] }],
    seating_and_common: [
      {
        numberOfSeats: 0,
        pricingOptions: [],
        spaceArea: 0,
        cancellationPolicy: [],
        refundableAgainst: 'false',
        pricingOptionRaw: [],
        cancellationPolicyRaw: [],
        requestToBook: true,
      },
    ],
    seating_arrangement: [{ selectedTypes: [], data: [] }],
    pricing_options: [
      {
        hourly: {
          pricingDetails: {
            halfHourBookings: undefined,
            minimumBookingPeriod: undefined,
            bufferPeriod: undefined,
            discountsTypes: [],
            discounts: [],
            dayTypes: [],
            twentySevenAccess: false,
            days: [],
            price: '',
          },
          packageDetails: { packageStatus: false, packages: [] },
        },
        block: {
          pricingDetails: {
            dayTypes: [],
            days: [],
            price: '',
          },
          packageDetails: { packageStatus: false, packages: [] },
        },
        daily: {
          pricingDetails: {
            price: '',
            twentySevenAccess: true,
            dayTypes: [],
            days: [],
          },
          packageDetails: { packageStatus: false, packages: [] },
        },
        weekly: {
          pricingDetails: {
            price: '',
            twentySevenAccess: false,
            dayTypes: [],
            days: [],
          },
          packageDetails: { packageStatus: false, packages: [] },
        },
        monthly: {
          pricingDetails: {
            price: '',
            refundableDepositAvailable: false,
            refundableDepositDuration: 0,
          },
          packageDetails: { packageStatus: false, packages: [] },
          availabilityDetails: { fullTimeAccess: false, startTime: null, endTime: null },
        },
      },
    ],
    amenities: [{ data: [] }],
    addons: [{ data: [] }],
    rules: [{ data: [] }],
    savings: [{ data: [] }],

    payout_details: [{ name: '', bank: '', bsb: '', swiftCode: '', bankCode: '', branchCode: '', accountNumber: '' }],
    commission: [{ percentage: '10' }],
    agreement: [{ status: false }],
  };
};

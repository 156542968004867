import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import BookingTracker from './BookingTracker/BookingTracker';
import RevenueTracker from './RevenueTracker/RevenueTracker';
import PaymentTracker from './PaymentTracker/PaymentTracker';
import PaymentRefundReport from './PaymentRefunds/PaymentRefund';
import SpaceTracker from './SpaceTracker/SpaceTracker';
import UserTracker from './UserTracker/UserTracker';
import downloadIcon from '../../assets/images/common/downloadGreen.svg';
import { downloadCSV } from './ReportAction';
import SummaryTracker from './SummaryTracker/SummaryTracker';

const { TabPane } = Tabs;

const ReportView = () => {
  const dispatch = useDispatch();
  const [activeTabKey, setActiveTabKey] = useState('booking-tracker')
  const [bookingTrackerCsvData, setBookingTrackerCsvData] = useState({});
  const [revenueTrackerCsvData, setRevenueTrackerCsvData] = useState({});
  const [paymentTrackerCsvData, setPaymentTrackerCsvData] = useState({});
  const [paymentRefundCsvData, setPaymentRefundCsvData] = useState({});
  const [spaceTrackerCsvData, setSpaceTrackerCsvData] = useState({});
  const [userTrackerCsvData, setUserTrackerCsvData] = useState({});

  const handleTabsChange = tab => {
    setActiveTabKey(tab);
  }

  const handleCsvDownloading = () => {
    switch (activeTabKey) {
      case 'booking-tracker':
        dispatch(downloadCSV(bookingTrackerCsvData));
        break;
      case 'revenue-tracker':
        dispatch(downloadCSV(revenueTrackerCsvData));
        break;
      case 'payment-tracker':
        dispatch(downloadCSV(paymentTrackerCsvData));
        break;
      case 'payment-refund':
        dispatch(downloadCSV(paymentRefundCsvData));
        break;
      case 'space-tracker':
        dispatch(downloadCSV(spaceTrackerCsvData));
        break;
      case 'user-Tracker':
        dispatch(downloadCSV(userTrackerCsvData));
        break;
    }
  }

  return (
    <div className="admin-wrapper reporting-wrap">
      <div className="container">
        <p className="title">Reports</p>
        <Tabs
          type="card"
          className="report-tab reporting-inner-wrap"
          activeKey={activeTabKey}
          onChange={handleTabsChange}
        >
          <TabPane tab="Booking Tracker" key='booking-tracker'>
            <BookingTracker/>
          </TabPane>
          <TabPane tab="Revenue Tracker" key='revenue-tracker'>
            <RevenueTracker csvData={setRevenueTrackerCsvData}/>
          </TabPane>
          <TabPane tab="Payment Tracker" key='payment-tracker'>
            <PaymentTracker csvData={setPaymentTrackerCsvData}/>
          </TabPane>
          {/* <TabPane tab="Payment Refunds" key='payment-refund'>
            <PaymentRefundReport csvData={setPaymentRefundCsvData}/>
          </TabPane> */}
          <TabPane tab="Space Tracker" key='space-tracker'>
            <SpaceTracker csvData={setSpaceTrackerCsvData}/>
          </TabPane>
          <TabPane tab='User Tracker' key='user-Tracker'>
            <UserTracker csvData={setUserTrackerCsvData}/>
          </TabPane>
          <TabPane tab='Summary Tracker' key='summary-Tracker'>
            <SummaryTracker/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default ReportView;

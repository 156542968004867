import { _post } from '../../api/authAPI';
import * as actions from './authActionTypes';

import {push} from "connected-react-router";
import {SearchURLGenerator} from "../../helper/Result";
import { CLEAR_ALL } from '../actionTypes';

export const submitUserDetails = userDetails => {
  return dispatch => {
    dispatch({
      type: actions.FETCH_LOGIN
    });

    _post(userDetails, true)
      .then(res => {
        dispatch({
          type: actions.LOGIN_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.LOGIN_FAILS,
          payload: error
        });
      });
  };
};

export const removeAuth = () => {
  return dispatch => {
    dispatch({
      type: actions.REMOVE_AUTH_DETAILS
    });
    dispatch(clearAll())
    // dispatch(push(`/`));
  };
};

export const removeAuthDetails = () => {
  return dispatch => {
    dispatch({
      type: actions.REMOVE_AUTH_DETAILS
    });
  }
}

export const userRegister = userDetails => {
  return dispatch => {
    dispatch({
      type: actions.SUBMIT_USER_DETAILS
    });

    _post(userDetails, false)
      .then(res => {
        dispatch({
          type: actions.SUBMIT_USER_DETAILS_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.SUBMIT_USER_DETAILS_FAILS,
          payload: error
        });
      });
  };
};

export const updateReduxWithAuthDetails = userDetails => {
  return {
    type: actions.LOGIN_SUCCESS,
    payload: userDetails
  };
};

export const changeLoginModalVisibility = () => {
  return {
    type: actions.CHANGE_LOGIN_VISIBILITY,
    payload: null
  };
};

export const sendTokenForVerification = (token) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.TOKEN_SUBMIT,
      payload:token,
    });
    axiosInstance.post(`user/verify-email`, {verificationToken:token.token})
        .then(res => {
          dispatch({
            type: actions.TOKEN_SUBMIT_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.TOKEN_SUBMIT_ERROR,
            payload: error
          });
        })
    ;
  };
};

export const resendEmailVerification = (email) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.RESEND_EMAIL_VERIFICATION
    });
    axiosInstance.post(`user/resend-email`, {email:email})
        .then(res => {
          dispatch({
            type: actions.RESEND_EMAIL_VERIFICATION_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.RESEND_EMAIL_VERIFICATION_ERROR,
            payload: error
          });
        })
    ;
  };
};

export const sendForgotPasswordRequest = (EMAIL) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.SEND_FORGOT_PASSWORD_REQUEST
    });
    axiosInstance.post(`user/forgot-password-request`, {email:EMAIL},{})
        .then(res => {
          dispatch({
            type: actions.SEND_FORGOT_PASSWORD_REQUEST_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.SEND_FORGOT_PASSWORD_REQUEST_ERROR,
            payload: error
          });
        })
    ;
  };
};

export const verifyForgotPassword = (token,newPassword,confirmedPassword) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.SEND_VERIFY_PASSWORD
    });
    axiosInstance.post(`user/verify-forgot-password`, {token:token, newPassword:newPassword, confirmPassword:confirmedPassword})
        .then(res => {
          dispatch({
            type: actions.SEND_VERIFY_PASSWORD_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.SEND_VERIFY_PASSWORD_ERROR,
            payload: error
          });
        })
    ;
  };
};

export const sendMobileNumber = (mobile,token) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.MOBILE_NUMBER_SUBMIT
    });
    axiosInstance.put(`user/send-verify-phone`, mobile)
        .then(res => {
          dispatch({
            type: actions.MOBILE_NUMBER_SUBMIT_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.MOBILE_NUMBER_SUBMIT_ERROR,
            payload: error
          });
        })
  };
};

export const submitMobile = (mobile,token) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.MOBILE_NUMBER_SUBMIT
    });
    axiosInstance.put(`user/phone`, mobile)
        .then(res => {
          dispatch({
            type: actions.MOBILE_NUMBER_SUBMIT_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.MOBILE_NUMBER_SUBMIT_ERROR,
            payload: error
          });
        })
  };
};

export const verifyOTP = (otp,token) => {
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.OTP_SUBMIT
    });
    axiosInstance.put(`user/verify-phone`, {otpToken:otp})
        .then(res => {
          dispatch({
            type: actions.OTP_SUBMIT_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.OTP_SUBMIT_ERROR,
            payload: error
          });
        })
  };
};

export const resendOTPCode = (mobile,token) =>{
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.MOBILE_NUMBER_SUBMIT
    });
    axiosInstance.put(`user/resend-verify-phone`, {number:mobile})
        .then(res => {
          dispatch({
            type: actions.MOBILE_NUMBER_SUBMIT_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.MOBILE_NUMBER_SUBMIT_ERROR,
            payload: error
          });
        })
  };
};

export const getCountryCode = (token) =>{
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.COUNTRYCODE_SUBMIT
    });
    axiosInstance.get(`ui/country`)
        .then(res => {
          dispatch({
            type: actions.COUNTRYCODE_SUBMIT_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.COUNTRYCODE_SUBMIT_ERROR,
            payload: error
          });
        })
  };
};
export const getUserDetailByToken = (token) =>{
  return (dispatch, getState, {axiosInstance}) => {
    dispatch({
      type: actions.GET_USER_DETAIL_BY_TOKEN
    });
    axiosInstance.get(`user/phone`)
        .then(res => {
          dispatch({
            type: actions.GET_USER_DETAIL_BY_TOKEN_SUCCESS,
            payload: res.data
          });
        })
        .catch(error => {
          dispatch({
            type: actions.GET_USER_DETAIL_BY_TOKEN_ERROR,
            payload: error
          });
        })
  };
};
export const updateUserDetails = (userDetails) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_USER_DETAILS,
      payload: userDetails
    });
  };
};
export const updateForgotPasswordToken = (token) => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_FORGOT_PASSWORD_TOKEN,
      payload: token
    });
  };
};
export const updateIsEmailSent = ()=>{
  return {
    type: actions.SUBMIT_USER_DETAILS_EMAIL_SENT,
    payload: null
  };
};
export const removeTokenDetails = () =>{
  return {
    type: actions.TOKEN_ERROR_REMOVE,
    payload: null
  };
};
export const removeResendDetails = () =>{
  return {
    type: actions.REMOVE_RESEND_EMAIL_VERIFICATION_DETAILS,
    payload: null
  };
};
export const removeLoginErrors = () =>{
  return {
    type: actions.REMOVE_LOGIN_FAILS,
    payload: null
  };
};

export const removeForgotPasswordDetails= ()=>{
  return{
    type: actions.REMOVE_FORGOT_PASSWORD_TOKEN,
    payload: null
  };
};

export const skipMobileNumber = (isSkipMobileNumberVerification) => {
  return{
    type: actions.SKIP_MOBILE_NUMBER,
    payload : isSkipMobileNumberVerification
  };
};

export const clearToken = () => {
  return{
    type: actions.CLEAR_TOKEN
  };
};

export const clearVerifiedPassword = () => {
  return{
    type: actions.CLEAR_VERIFY_PASSWORD
  };
}

export const clearAll = () => {
  return{
    type: CLEAR_ALL
  };
};
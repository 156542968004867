import React from 'react';
import { Grid, Cell } from 'react-md';


const SpaceLoader = () => {
    return (
        <section className="loading-wrap">
            <Grid className="result-grid">
                    <Cell size={3} phoneSize={12} tabletSize={4} className="result-cell">
                        <section class="card">
                            <figure class="card-image loading"></figure>
                            <div class="card-detail">
                                <p class="card-description loading"></p>
                                <h3 class="card-title loading"></h3>
                                <p class="card-description loading"></p>
                            </div>
                        </section>
                    </Cell>
                    <Cell size={3} phoneSize={12} tabletSize={4} className="result-cell">
                        <section class="card">
                            <figure class="card-image loading"></figure>
                            <div class="card-detail">
                                <p class="card-description loading"></p>
                                <h3 class="card-title loading"></h3>
                                <p class="card-description loading"></p>
                            </div>
                        </section>
                    </Cell>
                    <Cell size={3} phoneSize={12} tabletSize={4} className="result-cell">
                        <section class="card">
                            <figure class="card-image loading"></figure>
                            <div class="card-detail">
                                <p class="card-description loading"></p>
                                <h3 class="card-title loading"></h3>
                                <p class="card-description loading"></p>
                            </div>
                        </section>
                    </Cell>
                    <Cell size={3} phoneSize={12} tabletSize={4} className="result-cell">
                        <section class="card">
                            <figure class="card-image loading"></figure>
                            <div class="card-detail">
                                <p class="card-description loading"></p>
                                <h3 class="card-title loading"></h3>
                                <p class="card-description loading"></p>
                            </div>
                        </section>
                    </Cell>
                </Grid>
        </section>
    )
}

export default SpaceLoader;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'antd';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import { getUserTrackerData, getSearchResults, getSearchResultsAsLists } from '../ReportAction';
import {
  userTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  arrayColumnFilter,
  searchFilterForEmptyFields,
  userTrackerResultsObj,
  userTrackerObjList,
  setToolTip,
} from '../../../helper/ReportingHelper';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import DateFilter from '../common/DateFilter';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';
import decode from 'jwt-decode';

const UserTracker = ({ handleDownload, status }) => {
  const dispatch = useDispatch();
  const [searchingKey, setSearchingKey] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [dataSourcesLists, setDataSourcesLists] = useState([]);
  const [tableData, setTableData] = useState({});
  const [searchingStatus, setSearchingStatus] = useState(false);
  const [searchStatusList, setSearchStatusList] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const dataloading = useSelector((state) => state?.report?.reports?.user?.loading);
  const authToken = useSelector((state) => state?.auth?.loginDetails?.data?.token);
  const userTrackerData = useSelector((state) => state?.report?.reports?.user?.data);
  const userTrackerSearchResult = useSelector((state) => state?.report?.searchResult || {});
  const userTrackerSearchResultList = useSelector((state) => state?.report?.searchResultList || []); 
  const loggedUser = authToken ? decode(authToken) : '';
  const isSuperAdmin = loggedUser.authorities && (loggedUser.authorities[0] === 'role.suadmin') ? true : false;

  const AuthTokenData = decode(authToken);
  const userCountry = AuthTokenData?.location ?.country;

  useEffect(() => {
    setDataLoading(dataloading);
  }, [dataloading]);

  useEffect(() => {
    const formattedData = userTrackerObj(userTrackerData);
    formattedData?.length && setDataSource(formattedData);
  }, [userTrackerData]);

  useEffect(() => {
    let res = [];
    if (userTrackerSearchResultList.length) {
      const formattedDataLists = userTrackerObjList(userTrackerSearchResultList);

      formattedDataLists?.length && setDataSourcesLists(formattedDataLists);
    }
  }, [userTrackerSearchResultList]);

  const handleSearch = (setSelectedKeys, confirm, searchText, type, mainType, searchTextKey) => {
    const startDate = searchText[0];
    const endDate = searchText[searchText.length - 1];
    setSearchingKey(type);
    dispatch(getSearchResults(searchText, type, mainType, startDate, endDate));
    setSearchingStatus(true);
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSearchingStatus(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleSearchList = (setSelectedKeys, confirm, searchText, type, mainType) => {
    let startDate = '';
    let endDate = '';
    if (type === 'dateJoined') {
      startDate = searchText[0];
      endDate = searchText[searchText.length - 1];
    }
    setSearchingKey(type);
    dispatch(getSearchResultsAsLists(searchText, type, mainType, startDate, endDate));
    setSearchStatusList(true);
  };

  const handleResetList = (setSelectedKeys, clearFilters) => {
    setSearchStatusList(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleFilterSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleFilterReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const checkObj = (searchingKey) => {
    let searchText1 = searchingKey;

    let res = [];
    if (userTrackerSearchResult.hasOwnProperty(searchText1) && userTrackerSearchResult[searchText1] === null) {
      res = []; 
    } else {
      res = [userTrackerResultsObj(userTrackerSearchResult)];
    }

    return res;
  };

  const userTrackerHeader = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'firstName'),
              (mainType = 'user-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'firstName');
      },
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(setSelectedKeys, confirm, searchText, (type = 'lastName'), (mainType = 'user-tracker'), (startDate = ''), (endDate = ''))
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'lastName');
      },
    },
    {
      key: 'email',
      title: 'Email Address',
      dataIndex: 'email',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText, (type = 'email'), (mainType = 'user-tracker'))}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'email');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return text === 'LK' ? 'SL' : handleNullValues(text);
      },
      filterDropdown: isSuperAdmin ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ):null,
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'spaceCountry');
      },
    },
    {
      key: 'telephoneNumber',
      title: 'Telephone Number',
      dataIndex: 'telephoneNumber',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="number"
          onSearch={(searchText) =>
            handleSearchList(setSelectedKeys, confirm, searchText, (type = 'phoneNo'), (mainType = 'user-tracker'), (startDate = ''), (endDate = ''))
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'telephoneNumber');
      },
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="role"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return arrayColumnFilter(value, record, 'role');
      },
    },
    {
      key: 'host',
      title: 'Host',
      dataIndex: 'host',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="other"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'host');
      },
    },
    {
      key: 'signUpVia',
      title: 'Sign Up Via',
      dataIndex: 'signUpVia',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="signUpMode"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'signUpVia');
      },
    },
    {
      key: 'dateJoined',
      title: 'Date Joined',
      dataIndex: 'dateJoined',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <DateFilter
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
          onSearch={(searchText) =>
            handleSearchList(setSelectedKeys, confirm, searchText, (type = 'dateJoined'), (mainType = 'user-tracker'), startDate, endDate)
          }
        />
      ),
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'dateJoined');
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="status"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'status');
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'user-tracker', totalDataLength: dataSource?.length, token: authToken, spaceCountry: userCountry });
  };

  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={userTrackerHeader}
        dataSource={searchingStatus ? checkObj(searchingKey) : searchStatusList ? dataSourcesLists : dataSource}
        tableDispatch={getUserTrackerData}
        props={{ increaseValue: 20, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
        searchStatus={searchingStatus}
        searchStatusList={searchStatusList}
        loading={dataLoading}
      />
    </div>
  );
};

export default withTracker('USER_TRACKER')(UserTracker);

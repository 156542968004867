import {
    FETCH_STRIPE_CLIENT_SECRET, FETCH_STRIPE_CLIENT_SECRET_SUCCESS,
    FETCH_STRIPE_CLIENT_SECRET_FAILS,
    FETCH_STRIPE_CARDS, FETCH_STRIPE_CARDS_SUCCESS, FETCH_STRIPE_CARDS_FAILS,
    SELECT_STRIPE_CARD
} from "../../../../../redux/actionTypes";

export const stripeReducer = (
    state = {
        fetching: false,
        error: { status: false, message: "" },
        data: [],
    }, action) => {
    switch (action.type) {



        case FETCH_STRIPE_CLIENT_SECRET:
            return Object.assign({}, state, { fetching: true });

        case FETCH_STRIPE_CLIENT_SECRET_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload
            };

        case FETCH_STRIPE_CLIENT_SECRET_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });

        case FETCH_STRIPE_CARDS:
            return Object.assign({}, state, { fetching: true });

        case FETCH_STRIPE_CARDS_SUCCESS:
            return {
                ...state,
                fetching: false,
                cards: action.payload
            };

        case FETCH_STRIPE_CARDS_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });

        case SELECT_STRIPE_CARD:
            return {
                ...state,
                selectedCard: action.payload
            }
        default:
            return state;
    }
};
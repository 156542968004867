import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  DAILY_OPTION,
  DEFAULT_DATA_OBJ,
  ERROR_ONLY_NUMBERS, HOURLY_OPTION,
  PRICING_OPTIONS,
  WEEKLY_OPTION
} from '../SpaceListingConstants';
import Select from 'antd/es/select';
import { TextField } from 'react-md';
import { contentIsNotNull } from './Validator';
import { TIME_FORMAT } from '../../Common/constants';
import TimePicker from '../../Common/TimePicker/TimePicker';
import { spaceListingData } from './ProfileListingDataModal';
import FlowHandlingButtons from './HandleNavigationAndSubmit';
import { getProfileData, setProfileData } from './DataController';
import { dailyPricingOptionValidation, showErrorMessage } from './Common';
import optionIcon from '../../../assets/images/space-listing/daily-pricing-icon.svg';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IMAGES_BASE_URL } from '../../../settings';

const { Option } = Select;
const PricingAndAvailability = ({pricingOption, chargingType}) => {
  const { country } = useParams();
  const [pricingDetails, setPricingDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [lastDayArray, setLastDayArray] = useState(pricingDetails?.['pricing_options']?.[0]?.[pricingOption]?.['pricingDetails']?.['dayTypes']);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const dateArray = useSelector((state) => state?.spaceListing.pricingOptionDaysArray?.find(value=> value?.name?.toLowerCase() === pricingOption)?.pricingOptions || []);

  useEffect(() => {
    setIsNextBtnActive(
      getBlockValue()?.price > 0 && (
        getBlockValue()?.twentySevenAccess ||
      ((contentIsNotNull(getBlockValue()?.dayTypes, 0)) &&
        getBlockValue()?.days?.filter(value => {
          return value?.slots?.filter(slot => {
            return [slot?.startTime.length, slot?.endTime.length].includes(0);
          })?.length > 0;
        })?.length === 0)
      )
    );
  }, [pricingDetails]);

  const addMoreDays = (type) => {
    const tempObj = Object.assign({}, pricingDetails, {
      ...pricingDetails,
      pricing_options: pricingDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['pricingDetails']['days'] = [...value[pricingOption]['pricingDetails']['days'], {
          type: type,
          slots: [{ startTime: '', endTime: '' }]
        }];
        return value;
      })
    });
    setProfileData(tempObj);
    setPricingDetails(tempObj);
  };

  const removeDays = (type) => {
    const tempObj = Object.assign({}, pricingDetails, {
      ...pricingDetails,
      pricing_options: pricingDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['pricingDetails']['days'] = value?.[pricingOption]?.['pricingDetails']?.['days']?.filter(value => {
          return value?.type !== type;
        });
        return value;
      })
    });
    setProfileData(tempObj);
    setPricingDetails(tempObj);
  };

  const handlePricingDetails = (type,price, onlyNumbers = false) => {
    if(onlyNumbers){
      price = price.replace(/^0+/, '');
    }
    if (onlyNumbers && !(/^[0-9.]{0,60}$/i).test(price) && price !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else {
      const tempObj = Object.assign({}, pricingDetails, {
        ...pricingDetails,
        pricing_options: pricingDetails?.['pricing_options'].map((value) => {
          value[pricingOption]['pricingDetails'][type] = price;
          return value;
        }),
      });
      setProfileData(tempObj)
      setPricingDetails(tempObj);
    }
  };

  const removeSlot = (dayIndex, slotIndex, date) => {
    const tempObj = Object.assign({}, pricingDetails, {
      ...pricingDetails,
      pricing_options: pricingDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['pricingDetails']['days'] = value[pricingOption]['pricingDetails']['days'].filter(value=>value?.type !== date)
        return value;
      })
    });

    const tempObj2 = Object.assign({}, tempObj, {
      ...tempObj,
      pricing_options: tempObj?.['pricing_options'].map((value) => {
        value[pricingOption]['pricingDetails']['dayTypes'] = value[pricingOption]['pricingDetails']['dayTypes'].filter(value=>value !== date);
        setLastDayArray(value[pricingOption]['pricingDetails']['dayTypes'].filter(value=>value !== date))
        return value;
      })
    });
    setProfileData(tempObj2);
    setPricingDetails(tempObj2);
  }

  const updateDateType = (selectedArray) => {
    if (lastDayArray.length < selectedArray.length) {
      let differentValue = selectedArray.filter(value => !lastDayArray.includes(value));
      addMoreDays(differentValue[0]);
    } else {
      let differentValue = lastDayArray.filter(value => !selectedArray.includes(value));
      removeDays(differentValue[0]);
    }
    setLastDayArray(selectedArray);
    const tempObj = Object.assign({}, pricingDetails, {
      ...pricingDetails,
      pricing_options: pricingDetails?.['pricing_options'].map((value) => {
        value[pricingOption]['pricingDetails']['dayTypes'] = selectedArray;
        return value;
      })
    });
    setProfileData(tempObj);
    setPricingDetails(tempObj);
  };

  const handleTimeChange = (newTime, type, dayIndex, slotIndex) => {
    const tempObj = Object.assign({}, pricingDetails, {
      ...pricingDetails,
      pricing_options: pricingDetails?.[PRICING_OPTIONS].map((value) => {
        value[pricingOption]['pricingDetails']['days'] = value[pricingOption]['pricingDetails']['days'].map((value, index) => {
          if (dayIndex === index) {
            value.slots[slotIndex][type] = newTime;
          }
          return value;
        });
        return value;
      })
    });
    setProfileData(tempObj);
    setPricingDetails(tempObj);
  };

  const getBlockValue = () => {
    return pricingDetails?.[PRICING_OPTIONS]?.[0]?.[pricingOption]?.['pricingDetails'];
  };

  const handleTimeFormatting = (step, time) => {
    return step === 30 ? time ? moment(time, TIME_FORMAT).add(30, 'minutes').format(TIME_FORMAT) : '8:00 am' : time ? moment(time, TIME_FORMAT).add(1, 'hour').format(TIME_FORMAT) : '8:00 am';
  };

  const getBlockComponents = () => {
    return getBlockValue()?.days?.map((value, dayIndex) => (
      <div className="content-wrapper sm-input-height">
        {value?.slots.map((slot, slotIndex) => (
          <div className="card-wrapper">
            <div className="card-border">
              <div className="card">
                <div className="title-btn-wrapper">
                <span className="title">{value?.type}</span>
                  <span className="remove-btn" onClick={() => removeSlot(dayIndex, slotIndex, value?.type)}>X</span>
                </div>
                <div className="pickers-wrapper">
                <TimePicker
                    label={'Start Time'}
                    className="input-margin-wrapper"
                    id="startTime"
                    endLimit={'11:00pm'}
                    value={slot?.startTime}
                    step={60}
                    onChange={(newTime) => handleTimeChange(newTime, 'startTime', dayIndex, slotIndex)}
                  />
                  <TimePicker
                    label={'End Time'}
                    className="input-margin-wrapper end-time"
                    id="endTime"
                    value={slot?.endTime}
                    step={60}
                    beginLimit={handleTimeFormatting(60, slot?.startTime)}
                    endLimit={'11:00pm'}
                    onChange={(newTime) => handleTimeChange(newTime, 'endTime', dayIndex, slotIndex)}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <>
      <div className="left-side-inner-wrapper availability-detais-wrapper daily-wrapper">
      <div className="pricing-option-label">
          <img className="icon" src={IMAGES_BASE_URL + getProfileData()?.seating_and_common?.[0]?.pricingOptionRaw?.filter(value => value?.name?.toLowerCase() === pricingOption)?.[0]?.icon} alt={"Option Icon"} alt={"Icon"}/>
          <div className="title"><span style={{textTransform:'capitalize'}}>{pricingOption}</span> pricing</div>
        </div>
        <h1 className="listing-page-title"><span style={{textTransform:'capitalize'}}>{pricingOption}</span> pricing & availability details</h1>
        <TextField
          value={getBlockValue()?.price}
          className="input-margin-wrapper"
          id="price"
          label={chargingType === "WORK_HOTD" 
                ? `Price ${[DAILY_OPTION,HOURLY_OPTION, WEEKLY_OPTION].includes(pricingOption) ? 'per seat' : ''} (${getProfileData()?.tax_information?.[0]?.currency})`
                : `Price (${getProfileData()?.tax_information?.[0]?.currency})` }
          lineDirection="center"
          type={'number'}
          errorText="This field is required."
          onChange={(price) => handlePricingDetails('price', price, true)}
        />

        {pricingOption !== HOURLY_OPTION && (<Select
          className={`input-margin-wrapper placeHolderTop`}
          value={getBlockValue()?.twentySevenAccess}
          dropdownClassName="space-listing-drop-down"
          placeholder={'Allow 24/7 access'}
          onSelect={(value) => handlePricingDetails('twentySevenAccess', value)}
        >
          <Option value={false}>No</Option>
          <Option value={true}>Yes</Option>
        </Select>)}

        {!getBlockValue()?.twentySevenAccess && (<div>
          <p className="gray-text">Please provide the days and times that your space can be accessed:</p>


          <Select
            className={`input-margin-wrapper ${getBlockValue()?.dayTypes?.length > 0 ? 'placeHolderTop' : ''}`}
            dropdownClassName="space-listing-drop-down"
            mode="multiple"
            allowClear
            placeholder="Available On"
            onChange={(value) => updateDateType(value)}
            value={getBlockValue()?.dayTypes}
          >
            {dailyPricingOptionValidation(dateArray, getBlockValue()?.dayTypes)?.map(value => <Option
              value={value}>{value}</Option>)}
          </Select>

          {getBlockComponents()}
        </div>)}

      </div>

      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default PricingAndAvailability;

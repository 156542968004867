import { BASE_URL } from './../settings';
import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const setAuthToken = (token, config) => {
  if (token && checkUrl(config.url)) {
    //applying token
      config.headers = {
        ...config.headers,
        Authorization : token,
        'Content-Type': 'application/json;charset=UTF-8'
      };
    axiosInstance.defaults.headers.common['Authorization'] = token;
  } else {
    //deleting the token from header
    delete config.headers?.common?.Authorization;
    delete axiosInstance.defaults?.headers?.common?.Authorization;
  }
  return config;
};

//on successful response
axiosInstance.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.request.use(function (config) {
  return setAuthToken(Cookies.get('token'), config);
});

//Check the url for authentication token
const checkUrl = (url) => {
  let urlValid = true;
  unAuthenticatedUrls.map((unAuthenticatedUrl) => {
    if (url.includes(unAuthenticatedUrl)) {
        urlValid = false;
    }
  });
  return urlValid;
};

//whitelisted URL list
const unAuthenticatedUrls = ['https://maps.googleapis.com', 'https://www.googleapis.com'];

export default axiosInstance;

import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-md';
import Select from 'antd/es/select';
import { DEFAULT_DATA_OBJ, ERROR_NUMBER_EXCEEDED} from '../SpaceListingConstants';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import { contentIsNotNull } from '../Common/Validator';
import { IMAGES_BASE_URL } from '../../../settings';
import standardIcon from '../../../assets/images/space-listing/standard.svg';
import minusIcon from '../../../assets/images/common/minus-icon.svg';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { showErrorMessage } from '../Common/Common';
import { useParams } from 'react-router-dom';
const { Option } = Select;

const SeatingArrangement = ({currentBlock}) => {
  const { country } = useParams();
  const [seatingArrangementDetails, setSeatingArrangementDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  useEffect(() => {
    setIsNextBtnActive(
      contentIsNotNull(getBlockValue()?.selectedTypes, 0)
      && getBlockValue()?.data?.filter(value=>{
        return value?.selectedStatus ? !((value?.capacity > 0) && value?.capacity <= Number(getProfileData()?.seating_and_common?.[0]?.numberOfSeats)) : false;
      })?.length ===  0)
  }, [seatingArrangementDetails]);

  const getBlockValue = () => {
    return seatingArrangementDetails?.[currentBlock]?.[0];
  };

  const handleSeatingArrangementAdd = useCallback((newValue) => {
    if (newValue) {
      const selectedTypeUpdatedObj = Object.assign({}, seatingArrangementDetails, {
        ...seatingArrangementDetails,
        seating_arrangement: seatingArrangementDetails?.seating_arrangement?.map((value) => {
          value ["selectedTypes"] = newValue;
          return value;
        })
      });
      const tempObj = Object.assign({}, selectedTypeUpdatedObj, {
        ...selectedTypeUpdatedObj,
        seating_arrangement: selectedTypeUpdatedObj?.seating_arrangement?.map((value) => {
          value ["data"] = value["data"]?.map(arrangementElement => {
            if(newValue.includes(arrangementElement?.id)) {
              arrangementElement = { ...arrangementElement, selectedStatus: true }
            } else {
              arrangementElement = { ...arrangementElement, selectedStatus: false, capacity:0 }
            }
            return arrangementElement;
          });
          return value;
        })
      });
      setProfileData(tempObj);
      setSeatingArrangementDetails(tempObj);
    }
  }, []);


  const removeSeatingArrangement = (id) => {
    handleSeatingArrangementAdd(getBlockValue()?.selectedTypes?.filter(value => value !== id ));
  }

  const updateCapacity = (id,newValue) => {
    if(Number(getProfileData()?.seating_and_common?.[0]?.numberOfSeats)<Number(newValue)){
      showErrorMessage(ERROR_NUMBER_EXCEEDED);
    } else {
      newValue = Number(newValue.toString().replace(/^0+/, ''));
      const tempObj = Object.assign({}, seatingArrangementDetails, {
        ...seatingArrangementDetails,
        seating_arrangement: seatingArrangementDetails?.seating_arrangement?.map((value) => {
          value ["data"] = value["data"]?.map(arrangementElement => {
            if (arrangementElement?.id === id) {
              arrangementElement = { ...arrangementElement, capacity: newValue }
            }
            return arrangementElement;
          });
          return value;
        })
      });
      setProfileData(tempObj);
      setSeatingArrangementDetails(tempObj);
    }
  }

  const getBlockComponents = () => {
    return getBlockValue()?.data?.map((value, dayIndex)=>{
      if(getBlockValue()?.selectedTypes.includes(value?.id)) {
        return (

            <div className="card-wrapper">
              <div className="icon-title-wrapper">
                <img className="icon" src={standardIcon}  alt={'Standard Icon'}/>
                <div className="title">{value?.name}</div>
              </div>
              <TextField
                value={getBlockValue()?.data?.[dayIndex]?.capacity}
                className="input-margin-wrapper"
                id="capacity"
                label="Capacity"
                lineDirection="center"
                type={'number'}
                onChange={(capacity => updateCapacity(value?.id, capacity))}
                errorText="This field is required."
              />
              <div className="circle-btn" onClick={() => removeSeatingArrangement(value?.id)}>
                <img className="img" src={minusIcon} alt={'Remove icon.'}/>
              </div>
            </div>
        )
      }}
    )
  };

  return (
    <>
      <div className="left-side-inner-wrapper seating-arrangement">
        <h1 className="listing-page-title">Seating arrangements</h1>
        <div>
          <p className="gray-text">You can select multiple seating arrangement(s)</p>
          <Select
            className={`input-margin-wrapper ${getBlockValue()?.dayTypes?.length > 0 ? 'placeHolderTop' : ''}`}
            dropdownClassName="space-listing-drop-down"
            mode="multiple"
            allowClear
            placeholder="Seating arrangement"
            onChange={(value => handleSeatingArrangementAdd(value))}
            value={getBlockValue()?.selectedTypes}
          >
            {getBlockValue()?.data?.map(value => <Option
              value={value?.id}><img src={IMAGES_BASE_URL + value?.icon} style={{width:"20px", paddingRight:"5px"}} alt={value?.name + " icon."}/>{value?.name}</Option>)}
          </Select>
          <div className="content-wrapper">
          {getBlockComponents()}
          </div>

        </div>

      </div>

      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default SeatingArrangement;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { getSpaceReportData, getSearchResults, getSearchResultsAsLists } from '../ReportAction';
import {
  spaceTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  arrayColumnFilter,
  searchFilterForEmptyFields,
  spaceTrackerResultsObj,
  spaceTrackerObjList,
  setToolTip,
} from '../../../helper/ReportingHelper';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import YearMonthFilter from '../common/YearMonthFilter';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';
import decode from 'jwt-decode';

const SpaceTracker = ({ handleDownload, status }) => {
  const dispatch = useDispatch();
  const [searchingKey, setSearchingKey] = useState('');
  const spaceTrackerData = useSelector((state) => state?.report?.reports?.space?.data);
  const authToken = useSelector((state) => state?.auth?.loginDetails?.data?.token);
  const [searchingStatus, setSearchingStatus] = useState(false);
  const [searchStatusList, setSearchStatusList] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourcesLists, setDataSourcesLists] = useState([]);
  const [tableData, setTableData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const dataloading = useSelector((state) => state?.report?.reports?.space?.loading);
  const spaceTrackerSearchResult = useSelector((state) => state?.report?.searchResult || {});
  const spaceTrackerSearchResultList = useSelector((state) => state?.report?.searchResultList || []); 
  const x = spaceTrackerSearchResultList.searchResultList;
  const loggedUser = authToken ? decode(authToken) : '';
  const isSuperAdmin = loggedUser.authorities && (loggedUser.authorities[0] === 'role.suadmin') ? true : false;

  const AuthTokenData = decode(authToken);
  const userCountry = AuthTokenData?.location ?.country;

  useEffect(() => {
    setDataLoading(dataloading);
  }, [dataloading]);

  useEffect(() => {
    const formattedData = spaceTrackerObj(spaceTrackerData);
    formattedData?.length && setDataSource(formattedData);
  }, [spaceTrackerData]);

  useEffect(() => {
    if (spaceTrackerSearchResultList.length) {
      const formattedDataList = spaceTrackerObjList(spaceTrackerSearchResultList);

      formattedDataList?.length && setDataSourcesLists(formattedDataList);
    }
  }, [spaceTrackerSearchResultList]);

  const handleSearch = (setSelectedKeys, confirm, searchText, type, mainType, searchTextKey) => {
    setSearchingKey(searchTextKey);
    dispatch(getSearchResults(searchText, type, mainType));
    setSearchingStatus(true);
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSearchingStatus(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleSearchList = (setSelectedKeys, confirm, searchText, type, mainType) => {
    let startDate = '';
    let endDate = '';
    if (type === 'date') {
      startDate = searchText[0];
      endDate = searchText[searchText.length - 1];
    }
    setSearchingKey(type);
    dispatch(getSearchResultsAsLists(searchText, type, mainType, startDate, endDate));
    setSearchStatusList(true);
  };

  const handleResetList = (setSelectedKeys, clearFilters) => {
    setSearchStatusList(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleFilterSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleFilterReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const checkObj = (searchingKey) => {
    let searchText1 = searchingKey;

    let res = [];
    if (spaceTrackerSearchResult.hasOwnProperty(searchText1) && spaceTrackerSearchResult[searchText1] === null) {
      res = []; 
    } else {
      res = [spaceTrackerResultsObj(spaceTrackerSearchResult)];
    }

    return res;
  };

  const spaceTrackerColumns = [
    {
      key: 'spaceId',
      title: 'Space ID',
      dataIndex: 'spaceId',
      width: 113,
      className: 'custom-width',
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearch(setSelectedKeys, confirm, searchText, (type = 'spaceId'), (mainType = 'space-tracker'), (searchTextKey = 'spaceId'))
          }
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceId');
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      width: 113,
      className: 'custom-width',
      render: (text) => {
        return handleNullValues(text)
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="category"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '68px' }} theme="filled" />,
      onFilter: (value, record) => {
        return arrayColumnFilter(value, record, 'category');
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      width: 113,
      className: 'custom-width',
      render: (text) => {
         return handleNullValues(text)
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return arrayColumnFilter(value, record, 'activity');
      },
    },
    {
      key: 'organizationName',
      title: 'Organization Name',
      dataIndex: 'organizationName',
      width: 113,
      className: 'custom-width',
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'organizationName'),
              (mainType = 'space-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '76px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'organizationName');
      },
    },
    {
      key: 'listedDate',
      title: 'Listed Date',
      dataIndex: 'listedDate',
      width: 113,
    },
    {
      key: 'listedMonth',
      title: 'Listed Month',
      dataIndex: 'listedMonth',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="month"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '50px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'listedMonth');
      },
    },
    {
      key: 'listedYear',
      title: 'Listed Year',
      dataIndex: 'listedYear',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="year"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'listedYear');
      },
    },
    {
      key: 'hostName',
      title: 'Host Name',
      dataIndex: 'hostName',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostEmail',
      title: 'Host Email',
      dataIndex: 'hostEmail',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'hostEmail'),
              (mainType = 'space-tracker'),
              (startDate = ''),
              (endDate = ''),
              (searchTextKey = 'hostEmail')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '78px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'hostEmail');
      },
    },
    {
      key: 'hostNumber',
      title: 'Host Tel.#',
      dataIndex: 'hostNumber',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'spaceName'),
              (mainType = 'space-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
    {
      key: 'spaceAddress',
      title: 'Space Address',
      dataIndex: 'spaceAddress',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceCity',
      title: 'Space City',
      dataIndex: 'spaceCity',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, startDate, endDate }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'spaceCity'),
              (mainType = 'space-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '77px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceCity');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Space Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return text === 'LK' ? 'SL' : handleNullValues(text);
      },
      filterDropdown: isSuperAdmin ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ):null,
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'maxSpaceCapacity',
      title: 'Max. Space Capacity',
      dataIndex: 'maxSpaceCapacity',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'nameOnAccount',
      title: 'Name On Account',
      dataIndex: 'nameOnAccount',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'bank',
      title: 'Bank',
      dataIndex: 'bank',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'swiftCode',
      title: 'Swift Code',
      dataIndex: 'swiftCode',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'bankCode',
      title: 'Bank Code',
      dataIndex: 'bankCode',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'branchCode',
      title: 'Branch Code',
      dataIndex: 'branchCode',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'accountNumber',
      title: 'Account Number',
      dataIndex: 'accountNumber',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'commission',
      title: 'Commission %',
      dataIndex: 'commission',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="status"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '51px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'status');
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'space-tracker', totalDataLength: dataSource?.length, token: authToken, spaceCountry: userCountry });
  };

  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={spaceTrackerColumns}
        dataSource={searchingStatus ? checkObj(searchingKey) : searchStatusList ? dataSourcesLists : dataSource}
        tableDispatch={getSpaceReportData}
        props={{ increaseValue: 20, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
        searchStatus={searchingStatus}
        searchStatusList={searchStatusList}
        loading={dataLoading}
      />
    </div>
  );
};

export default withTracker('SPACE_TRACKER')(SpaceTracker);

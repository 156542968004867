import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InfinityTable as Table } from 'antd-table-infinity';
import { Spin } from 'antd';

const InfiniteTable = ({ columns, dataSource, tableDispatch, props, tableData, scroll, searchStatus, searchStatusList,loading=false }) => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  console.log('loading',loading);
  useEffect(() => {
    setPageSize(dataSource?.length || 1);
    console.log('dataSource',dataSource);
  }, [dataSource]);

  const handleFetch = () => {
    const newPage = pageCount + 1;
    setPageCount(newPage);
    dispatch(tableDispatch(pageCount, { ...props }));
  };

  const handleTableChange = (pagination, filter, sorter, data) => {
    tableData({
      filteredDataLength: data?.currentDataSource?.length,
      filters: filter,
    });
  };
  let locale1 = {
    emptyText: dataSource.length ? null : <Spin size="small" tip="Loading..." />,
  };
  let showLoading = {
    emptyText: loading ?  <Spin size="small" tip="Loading..." /> :null ,
  };

  const loaderHandling = () => {
    let locale;
    if (searchStatusList || searchStatus) {
      
    } else {
      locale = dataSource.length ? '' : locale1;
    }
    if (dataSource.length === 0) {
      locale = null;
    }
    return locale;
  };

  // Disable filterIcon when emptyText is displayed
  const tableColumns = dataSource && dataSource.length > 0 ? columns : columns.map((col) => ({ ...col, filterIcon: null, filterDropdown: null }));

  return (
    <div>
      <Table
        locale={showLoading}
        key="tracker"
        className="table-wrap"
        onFetch={handleFetch}
        pageSize={pageSize}
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        bordered
        scroll={scroll}
      />
    </div>
  );
};

export default InfiniteTable;

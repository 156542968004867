import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import Progress from "antd/es/progress";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import WelcomePage from "./SubComponents/Welcome";
import Commission from "./SubComponents/Commission";
import Agreement from "./SubComponents/HostAgreement";
import { Redirect, Route, Switch } from "react-router";
import PayoutDetails from "./SubComponents/PayoutDetails";
import Congratulations from "./SubComponents/Congratulations";
import { hostOnBoardingData } from "./Common/HostOnBoardingDataModal";
import OrganizationDetails from "./SubComponents/OrganizationDetails";
import { getProfileData, setProfileData } from "./Common/DataController";
import spaceListingBanner from "../../assets/images/space-listing/space-listing.jpg";
import { AGREEMENT, COMMISSION, CONGRATULATIONS, ORGANIZATION_DETAILS, PAYOUT_DETAILS, SPACE_LOCATION, WELCOME } from "./HostOnboardingConstants";
import { useParams } from 'react-router-dom';

const HostOnBoarding = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(1);
  const { country } = useParams();

  const baseRouteURL = `/${country}/dashboard/host-onboarding/`;

  useEffect(() => {
    setProfileData(hostOnBoardingData(country));
    dispatch(push(baseRouteURL + "welcome"));
  }, []);

  useEffect(() => {
    setProgress(((getProfileData()?.routeList?.indexOf(window.location.pathname) + 1) / getProfileData()?.routeList?.length) * 100);
  }, [location]);

  return (
    <>
      <div className="space-listing-page host-onboarding-page">
        <Progress percent={progress} status="active" showInfo={false} strokeColor={"#2A8485"} />
        <div className="full-height-wrapper">
          <Row className="section-wrapper">
            <Col className="left-side" xl={12} lg={12} md={24} sm={24}>
              <Switch>
                <Route path={baseRouteURL + WELCOME} component={() => <WelcomePage />} />
                <Route path={baseRouteURL + ORGANIZATION_DETAILS} component={() => <OrganizationDetails currentBlock={ORGANIZATION_DETAILS} />} />
                <Route path={baseRouteURL + COMMISSION} component={() => <Commission currentBlock={COMMISSION} />} />

                <Route path={baseRouteURL + PAYOUT_DETAILS} component={() => <PayoutDetails currentBlock={PAYOUT_DETAILS} />} />

                <Route path={baseRouteURL + AGREEMENT} component={() => <Agreement currentBlock={AGREEMENT} />} />

                <Route path={baseRouteURL + CONGRATULATIONS} component={() => <Congratulations currentBlock={CONGRATULATIONS} />} />

                <Route path={"*"} component={() => <Redirect to={baseRouteURL + WELCOME} />} />
              </Switch>
            </Col>
            {location.pathname !== baseRouteURL + SPACE_LOCATION && (
              <Col className="right-side" xl={12} lg={12} md={0} sm={0}>
                <div className="background-img-container" style={{ backgroundImage: `url("https://res.cloudinary.com/dgcojyezg/image/upload/v1618554603/ms-singapore/HostOnboarding/hostOnboarding.jpg")` }} />
              </Col>
            )}
            {location.pathname === baseRouteURL + SPACE_LOCATION && (
              <Col className="right-side map-section" xl={12} lg={12} md={24} sm={24}>
                <div className="map-content-wrapper">
                  <div className="map-title-wrapper">
                    <div className="map-title">Drag & drop the pin to your space location in the map</div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default HostOnBoarding;

import qs from 'query-string';
import moment from 'moment';
import { CURRENCY_SYMBOL, maxSeating } from '../components/Common/constants';

// export const searchMetaData = (address, eventType, spaceCategory) => ({
//     eventType: eventType,
//     spaceCategory: spaceCategory,
//     address: address
// })

export const searchMetaData = (eventType, startDate, endDate, locationOrSpace,country, lat, lng, category) => {
    let meta = {};
    
    if(eventType){
        meta.eventType = eventType
    }
    if(startDate){
        meta.startDate = startDate
    }
    if(endDate){
        meta.endDate = endDate
    }
    if(locationOrSpace){
        meta.locationOrSpace = locationOrSpace
    }
    if(country){
        meta.country = country
    }
    if(lat){
        meta.lat = lat
    }
    if(lng){
        meta.lng = lng
    }
    if(category){
        meta.category = category
    }

    return meta;
}



export const getvaluesfromURL = searchURLstring => qs.parse(searchURLstring, { arrayFormat: 'comma' });

export const getValuefromURLwithoutSearchMeta = (searchURLstring) => {
    const valuesfromURL = getvaluesfromURL(searchURLstring);

    if (valuesfromURL.address) {
        delete valuesfromURL.address
    }
    if (valuesfromURL.eventType) {
        delete valuesfromURL.eventType
    }
    if (valuesfromURL.spaceCategory) {
        delete valuesfromURL.spaceCategory
    }
    return valuesfromURL
}

export const getSearchMeta = (searchURLstring) => {
    const valuesfromURL = getvaluesfromURL(searchURLstring);
    return searchMetaData(valuesfromURL.address, valuesfromURL.eventType, valuesfromURL.spaceCategory, valuesfromURL.country, valuesfromURL.category)
}

export const getFilterFields = value => Object.keys(getvaluesfromURL(value));

export const getMoreAndPerksCount = (filters, type) => {
    // *** returns count of Selected More Values ***
    let moreFilterObjects = [];
    if(type === 'nearMrt'){
        moreFilterObjects = filters.map(filter => filter.field === type && filter.defaultValue ? countNumberofValues(filter.defaultValue) : 0)
    }else{
        moreFilterObjects = filters.map(filter => filter[type] && filter.defaultValue ? countNumberofValues(filter.defaultValue) : 0)
    }
    return moreFilterObjects.reduce((a, b) => a + b, 0)
}

const countNumberofValues = (defaultValue) => typeof (defaultValue) == "string" ? 1 : defaultValue.length

export const getEventTypefromURL = searchString => getvaluesfromURL(searchString).eventType;
export const getSpaceCategoryfromURL = searchString => getvaluesfromURL(searchString).spaceCategory;
export const getAddressfromURL = searchString => getvaluesfromURL(searchString).address;
export const getlocationOrSpace = searchString => getvaluesfromURL(searchString).locationOrSpace;
export const getstartDate = searchString => getvaluesfromURL(searchString).startDate;
export const getendDate = searchString => getvaluesfromURL(searchString).endDate;
export const getCountry = searchString => getvaluesfromURL(searchString).country;
export const getLatitude = searchString => getvaluesfromURL(searchString).lat;
export const getLongertude = searchString => getvaluesfromURL(searchString).lng;
export const getCategory = searchString => getvaluesfromURL(searchString).category;


export const clearFilterNames = (label, required, value) => required ? value[0] : label;
export const nameUnusedFilter = (label, defaultValue, required) => required ? defaultValue : label;
export const nameUsedFilter = (type, defaultValue, filterName, currency) => {
    if (defaultValue) {
        switch (type) {
            case 'SINGLE_SELECT':
                return filterName == 'Seats' ? seatfilterName(defaultValue[0], defaultValue[1]) : `${defaultValue[0].toUpperCase()}${defaultValue.substring(1)} `
            case 'MULTIPLE_SELECT':
                return `${filterName}`
            case 'RANGE_SELECT':
                return `${currency || CURRENCY_SYMBOL} ${defaultValue[0]}-${defaultValue[1]}`;
            case 'DATE_PICKER':
                return moment(defaultValue, "DD-MM-YYYY").format('DD MMM');
            case 'BOOLEAN_SELECT':
                return `${filterName}+s`
            default:
                return filterName
        }
    }
    else {
        return filterName
    }
}

export const seatfilterName = (minVal, maxValue) => {
    // ***input: array output:string***
    if (minVal === maxValue) {
        return minVal == 1 ? `${minVal.toString()} Seat` : minVal?.toString()
    } else {
        return maxValue == maxSeating ? `${minVal}+ Seats` : `${minVal}-${maxValue} Seats`
    }
}

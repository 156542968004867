import {
  FETCH_SPACE_PROFILE,
  FETCH_SPACE_PROFILE_SUCCESS,
  FETCH_SPACE_PROFILE_FAILS,
  PUT_FAVORITE_SUCCESS,
  PUT_FAVORITE_FAILS,
  FETCH_MORE_REVIEW,
  FETCH_MORE_REVIEW_SUCCESS,
  FETCH_MORE_REVIEW_FAILS,
  FETCH_SIMILAR_SPACE,
  FETCH_SIMILAR_SPACE_SUCCESS,
  FETCH_SIMILAR_SPACE_FAILS,
  FETCH_PAYMENT_TOKEN_SUCCESS,
  FETCH_PAYMENT_TOKEN_FAILS,
  FETCH_PAYMENT_TOKEN
} from './spaceActionTypes';

export const profileReducer = (
  state = {
    fetching: false,
    error: { status: false, message: '' },
    data: [],
    similarSpaces: {
      fetching: false,
      error: { status: false, message: '' },
      data: []
    }
  },
  action
) => {
  switch (action.type) {
    case FETCH_SPACE_PROFILE:
      return Object.assign({}, state, { fetching: true });

    case FETCH_SPACE_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload
      };
    case FETCH_SPACE_PROFILE_FAILS:
      return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: '' } });

    case FETCH_SIMILAR_SPACE:
      return {
        ...state,
        similarSpaces : {
          ...state.similarSpaces,
          fetching: true,
        }
      };

    case FETCH_SIMILAR_SPACE_SUCCESS:
      return {
        ...state,
        similarSpaces : {
          ...state.similarSpaces,
          fetching: false,
          data : action.payload
        }
      };

    case FETCH_SIMILAR_SPACE_FAILS:
      return {
        ...state,
        similarSpaces : {
          ...state.similarSpaces,
          fetching: false,
          error: { status: true, message: action.payload }
        }
      };

    case PUT_FAVORITE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          favorite: true
        }
      };

    case FETCH_MORE_REVIEW:
      return Object.assign({}, state, { fetching: false });

    case FETCH_MORE_REVIEW_SUCCESS:
      return {
        ...state,
        fetching: false,
        reviews: action.payload,
        data: { ...state.data }
      };

    case FETCH_MORE_REVIEW_FAILS:
      return Object.assign({}, { data:{...state.data}, reviews: {...state.data.reviews} }, { fetching: false }, { error: { status: true, message: '' } });
    case PUT_FAVORITE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          favorite: true
        }
      };
    case PUT_FAVORITE_FAILS:
      return {
        ...state,
        data: {
          ...state.data,
          feature: true
        }
      };

    default:
      return state;
  }
};

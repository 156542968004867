import React, { Suspense, useCallback, useEffect, useState } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { dependencies } from '../index';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { fetchCountrySortCode, getCountryCodes, getIpLocation } from '../redux/location/locationActions';
import { getSpaceActivities } from './Result/ResultAction';
import * as ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AppRoutes from './AppRoutes';
import { getHomePageData } from './Home/HomeAction';
import { Helmet } from 'react-helmet';
import { FACEBOOK_META_TAG } from '../settings';
import { SINGAPORE_URL, SRI_LANKA_URL, AUSTRALIA_URL } from './HostOnBoarding/HostOnboardingConstants';
import { COUNTRY_CODES } from './Common/constants';


function App() {
  const dispatch = useDispatch();

  const mockFunction = useCallback(() => {
  });
  const [cookies, setCookie] = useCookies(['authDetails']);
  const pathName = useSelector((state) => state?.router?.location?.pathname || {});
  const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
  const coordinates = useSelector((state) => state?.locationMeta?.coordinates || {});
  const countryLongName = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(" ", "-") || null);
  const countryShortCode = useSelector(state => state?.locationMeta?.data?.country_shortCode);
  const [setectedCountry, setSelectedCountry] = useState(null)
  const [isCountryFromLocation, setIsCountryFromLocation] = useState(false);

  useEffect(() => { 
    if (countryShortCode && countryShortCode !== setectedCountry) {
      dispatch(getHomePageData(countryShortCode));
      dispatch(getSpaceActivities(countryShortCode));
      setSelectedCountry(countryShortCode)
    }
  }, [countryShortCode]);

  useEffect(() => {
    ReactGA.pageview(pathName);
    ReactPixel.pageView();
  }, [pathName]);

  useEffect(() => {

    if (pathName.includes(SRI_LANKA_URL)) {
      dispatch(fetchCountrySortCode({ country_longCode: COUNTRY_CODES.SRI_LANKA.country_longCode }));
    } else {
      dispatch(getIpLocation());
      setIsCountryFromLocation(true);
    }
  }, []);

  useEffect(() => {
    if (coordinates?.lat && coordinates?.lon) {
      dispatch(getCountryCodes(coordinates.lat, coordinates.lon));
    }
  }, [coordinates]);

  useEffect(() => {
    if (countryLongName && isCountryFromLocation) {
      window.location.href = '/' + countryLongName;
    }
  }, [countryLongName]);

  useEffect(() => {
    if (!Cookies.get('token')) {
      if (authDetails) {
        setCookie('token', authDetails, { path: '/' });
      }
    }
  }, [authDetails]);

  return (
    <div className={`App ms-styles-wrapper ${dependencies.history.location.pathname.includes('/spaces') ? 'space-page' : ''}`}>
      {pathName === '/'+countryLongName &&
      <Helmet>
        <meta name="facebook-domain-verification" content={FACEBOOK_META_TAG} />
      </Helmet>
      }
      <>
        <ErrorBoundary>
          <ConnectedRouter history={dependencies.history}>
            <Suspense fallback={null}>
              <Switch>
                <Route
                    path="/:country"
                    component={AppRoutes}
                 />
              </Switch>

            </Suspense>
          </ConnectedRouter>
        </ErrorBoundary>
      </>
    </div>
  );
}

export default App;

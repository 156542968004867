import React, { useCallback, useEffect, useState } from 'react';
import { Select } from "antd";
import { TextField } from "react-md";
import FlowHandlingButtons from "../Common/HandleNavigationAndSubmit";
import { contentIsNotNull } from '../Common/Validator';
import PlacesAutocomplete from "react-places-autocomplete";
import { DEFAULT_DATA_OBJ, SINGAPORE, SRI_LANKA } from '../SpaceListingConstants';
import { spaceListingData } from "../Common/ProfileListingDataModal";
import { getProfileData, setProfileData } from "../Common/DataController";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const OrganizationDetails = ({ currentBlock }) => {
  const { country } = useParams();
  const [spaceLocation, setSpaceLocation] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const countryList = useSelector((state) => state?.auth?.countryListDetails?.countryList || []);

  useEffect(() => {
    setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue("streetName"), 0) &&
      contentIsNotNull(getFieldCurrentValue("city"), 0) &&
      contentIsNotNull(getFieldCurrentValue("streetName"), 0,500) &&
      contentIsNotNull(getFieldCurrentValue("postalAddress"), 0)
    );
  }, [spaceLocation]);

  const handleSpaceLocation = useCallback((name, newValue) => {
    const tempObj = Object.assign({}, spaceLocation, {
      ...spaceLocation,
      processStarted: true,
      [currentBlock]: spaceLocation?.[currentBlock].map((value) => {
        value[name] = newValue;
        return value;
      }),
    });
    setSpaceLocation(tempObj);
    setProfileData(tempObj);
  }, []);

  const getFieldCurrentValue = (type) => {
    return spaceLocation?.[currentBlock]?.[0]?.[type];
  };


  const lable1 = (
    <div>Business registration number <span className="gray-text">(optional)</span></div>
  );
  return (
    <>
      <div className="left-side-inner-wrapper space-location-wrapper">
        <h1 className="listing-page-title">Organization detail</h1>
        <div>

          <TextField
            className="input-margin-wrapper"
            id="organizationName"
            label="Organization name"
            lineDirection="center"
            placeholder="Eg: MIllionSpaces"
            required
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("organizationName", value)}
            value={getFieldCurrentValue("organizationName")}
          />
          <TextField
            className="input-margin-wrapper"
            id="organizationWebsite"
            label="Organization website"
            lineDirection="center"
            placeholder="Eg: www.millionspaces.com"
            required
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("organizationWebsite", value)}
            value={getFieldCurrentValue("organizationWebsite")}
          />
          <TextField
            className="input-margin-wrapper"
            id="businessRegistrationNo"
            label={lable1}
            placeholder="Eg: BR321627"
            lineDirection="center"
            required
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("businessRegistrationNo", value)}
            value={getFieldCurrentValue("businessRegistrationNo")}
          />
          <Select
            className={`input-margin-wrapper ${getFieldCurrentValue('country')?.length > 0 ? 'placeHolderTop' : ''}`}
            value={getFieldCurrentValue("country")}
            placeholder={"Country"}
            dropdownClassName="space-listing-drop-down "
            onSelect={(value) => handleSpaceLocation("country", value)}
          >
            {countryList?.map(value=><Option value={value?.name}>{value?.name}</Option>)}
          </Select>

          <TextField
            className="input-margin-wrapper"
            id="city"
            label="City"
            lineDirection="center"
            required
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("city", value)}
            value={getFieldCurrentValue("city")}
          />

          <PlacesAutocomplete
            value={getFieldCurrentValue("streetName")}
            onChange={(value) => handleSpaceLocation("streetName", value)}
            className="input-margin-wrapper"
            shouldFetchSuggestions={true}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="auto-complete-wrapper">
                <input
                  className="search-input"
                  {...getInputProps({
                    "aria-expanded": true,
                    placeholder: `Street Name`,
                    id: "search",
                  })}
                />
                <div className="search-menu-list">
                  {loading && <div className="suggestion-item md-list-item">Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active ? "suggestion-item--active md-list-item" : "suggestion-item md-list-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        className="list"
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                          onMouseEnter: "",
                          onMouseLeave: "",
                          onMouseDown: "",
                          onMouseUp: "",
                          onTouchStart: "",
                          onTouchEnd: "",
                          onClick: () => {
                            handleSpaceLocation("streetName", suggestion.description);
                          },
                        })}
                      >
                        <span className="list-item">{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <hr className="divider" />
          <TextField
            className="input-margin-wrapper"
            id="postalAddress"
            label="Postal Address"
            lineDirection="center"
            required
            errorText="This field is required."
            onChange={(value) => handleSpaceLocation("postalAddress", value)}
            value={getFieldCurrentValue("postalAddress")}
          />
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default OrganizationDetails;

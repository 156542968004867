import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/layout.scss';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware, push } from 'connected-react-router';
import axios from 'axios';
import Cookies from 'js-cookie';

import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import * as serviceWorker from './serviceWorker';
import reducers from './redux/index';
import App from './components/App';
import { CookiesProvider } from 'react-cookie';

// Imports for google analytics tracking.
import {FACEBOOK_PIXEL_TRACKING_CODE, GOOGLE_ANALYTICS_TRACKING_CODE, BASE_URL} from "./settings";
import {setAuthToken} from './axiosInstance';
import {removeAuthDetails, changeLoginModalVisibility} from './redux/auth/authAction';
import ReactGA from 'react-ga';
import ReactPixel from "react-facebook-pixel";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE);
ReactPixel.init(FACEBOOK_PIXEL_TRACKING_CODE);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const dependencies = {
  history: createBrowserHistory(),
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const store = createStore(
  reducers(dependencies.history),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(dependencies.history),
      thunk.withExtraArgument({ history: dependencies.history, axiosInstance }),
      // logger,
      immutableCheckMiddleWare(),
    ),
  ),
);

axiosInstance.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if((error).response.status === 401){
      Cookies.remove('token', { path: '/' });
      store.dispatch(removeAuthDetails());
      store.dispatch(changeLoginModalVisibility());
      return Promise.reject(error)
    }else{
      return Promise.reject(error)
    }
  }
);

axiosInstance.interceptors.request.use(function (config) {
  return setAuthToken(Cookies.get('token'), config, axiosInstance);
});

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <MsalProvider instance={msalInstance}>
        <App dependencies/>
      </MsalProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

function PrivateRoute({
  component: Component, isAuthenticated, userRole, autharizedRole, redirectPath, ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => (isAuthenticated && userRole === autharizedRole ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
          }}
        />
      ))
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
  autharizedRole: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired
};

export default PrivateRoute;

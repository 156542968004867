import {
    FETCH_COUNTRY_SHORT_CODE,
    FETCH_COUNTRY_SHORT_CODE_SUCCESS,
    FETCH_COUNTRY_SHORT_CODE_FAILS,
    ASSIGN_COUNTRY_SHORT_CODE,
    FETCH_IPLOCATION_SUCCESS,
    FETCH_IPLOCATION_FAILS,
} from './locationActionTypes';
import { getLocationDetails, getIPLocation } from '../../api/getLocationDetails';
import { getDetailsFromPlaceID, getLatLngfromPlaceId, getLatLngByAddress } from '../../api/getDetailsFromPlaceID';
import { defaultCountry, defaultShortCountryCode, defaultCurrency, COUNTRY_CODES } from '../../components/Common/constants';


export const getCountryCodes = (lat, long) => {

    return dispatch => {
        dispatch({
            type: FETCH_COUNTRY_SHORT_CODE
        });

        getLocationDetails(lat, long)
            .then(res => {
                const shortCode = extractShortCode(res.data);

                //temp
                let tempCountry = shortCode 
                if(shortCode ==="AUS"){ tempCountry = "Australia"}
                

                const currency = currencyFromCountryCode(shortCode);
                sessionStorage.setItem('countryShortCode', shortCode);
                sessionStorage.setItem('currency', currency);
                dispatch({
                    type: FETCH_COUNTRY_SHORT_CODE_SUCCESS,
                    payload: {
                        country_shortCode: (shortCode === 'SG' || shortCode === 'LK' || shortCode === 'AUS') ? shortCode : 'AUS',
                        country_longCode: extractLongCode(res.data),
                        currency: currencyFromCountryCode(shortCode),
                        tempCountry
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: FETCH_COUNTRY_SHORT_CODE_FAILS,
                    payload: error
                });
            });
    };
};

export const assignCountryCode = (shortCode, country) => {
    return {
        type: ASSIGN_COUNTRY_SHORT_CODE,
        payload: {
            country_shortCode: shortCode || defaultShortCountryCode,
            country_longCode: country || defaultCountry,
            currency: currencyFromCountryCode(shortCode)
        }
    };
};

export const assignDefaultCountryCode = () => {
    return {
        type: ASSIGN_COUNTRY_SHORT_CODE,
        payload: {
            country_shortCode: defaultShortCountryCode,
            country_longCode: defaultCountry,
            currency: defaultCurrency
        }
    };
};

export const browserLocation = (lat, lng) => {
    return dispatch => {
        sessionStorage.setItem('lat', lat);
        sessionStorage.setItem('lng', lng);
        dispatch({
            type: FETCH_IPLOCATION_SUCCESS,
            payload: {
                lat: lat,
                lon: lng
            }
        });
    }
}

export const getIpLocation = () => {
    return dispatch => {
        getIPLocation()
            .then(res => {
                sessionStorage.setItem('lat', res.data.location.lat);
                sessionStorage.setItem('lng', res.data.location.lng);
                dispatch({
                    type: FETCH_IPLOCATION_SUCCESS,
                    payload: res.data
                });
            })
            .catch(error => {
                dispatch({
                    type: FETCH_IPLOCATION_FAILS,
                    payload: error
                });
            })
    }
}

const extractShortCode = (result) => {
    if (result.status = 'ok') {
        let locationComponent = result.results.find(res => res.types.includes('country'))
        switch (locationComponent.address_components[0].short_name) {
            case 'LK':
            case 'SG':
                return locationComponent.address_components[0].short_name;
            default:
                return 'AUS';
        }
    }
}

const extractLongCode = (result) => {
    if (result.status = 'ok') {
        let locationComponent = result.results.find(res => res.types.includes('country'))
        switch (locationComponent.address_components[0].long_name) {
            case 'Sri Lanka':
                return 'Sri Lanka'
            case 'Singapore':
                return 'Singapore'
            case 'Australia':
                return 'Australia'
            default:
                return 'Singapore';
        }

    }
}
const currencyFromCountryCode = (countryCode) => {
    switch (countryCode) {
        case 'LK':
            return 'LKR';
        case 'SG':
            return 'SGD'
        case 'AUS':
            return 'AUD'
        default:
            return 'SGD';
    }
}

export const getLocationDetailsOfSelected = placecId => {
    return dispatch => {
        dispatch({
            type: FETCH_COUNTRY_SHORT_CODE
        });
        getDetailsFromPlaceID(placecId).
            then(res => {

                let shortCode = res[0].address_components.find(response => response.types.includes('country')).short_name;
                let longCode = res[0].address_components.find(response => response.types.includes('country')).long_name;
                sessionStorage.setItem('countryShortCode', shortCode);
                sessionStorage.setItem('currency', currencyFromCountryCode(shortCode));

                return {
                    country_shortCode: shortCode || defaultShortCountryCode,
                    country_longCode: longCode || defaultCountry,
                    currency: currencyFromCountryCode(shortCode)
                }
            }).then(res => {
                fetchCountrySortCode(res)
            })

            .catch(error => {
                dispatch({
                    type: FETCH_COUNTRY_SHORT_CODE_FAILS,
                    payload: error
                });
            });
    };
};

export const fetchCountrySortCode = (countryDetails) => {
    return dispatch => {
        sessionStorage.setItem('countryShortCode', countryDetails.country_shortCode);
        sessionStorage.setItem('currency', currencyFromCountryCode(countryDetails.country_shortCode));

        getLatLngByAddress(countryDetails.country_longCode)
            .then((res) => {
                dispatch(browserLocation(res.lat, res.lng));
                dispatch({
                    type: FETCH_COUNTRY_SHORT_CODE_SUCCESS,
                    payload: countryDetails
                });
            })
    }
};

export const getSuggestions = (lat, lng, country) => {
    return (dispatch, getState, { axiosInstance }) => {
        sessionStorage.setItem('currency', currencyFromCountryCode(country));
        if(lat && lng){
            dispatch(browserLocation(lat, lng));
        }else{
            for(let details in COUNTRY_CODES){
                if(COUNTRY_CODES[details].country_shortCode === country) {
                    const {lat, lng } = COUNTRY_CODES[details]
                    dispatch(browserLocation(lat, lng));
                }
                
            }
        }   
    }
}

export const getLatLngByPlaceId = (placeId) => {
    return dispatch => {
        getLatLngfromPlaceId(placeId)
            .then((res) => {
                dispatch(browserLocation(res.lat, res.lng));
            })

    }
}
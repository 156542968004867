import { AUSTRALIA } from '../SpaceListingConstants';

export const setProfileData = (data) => {
  sessionStorage.setItem('profileListingData', JSON.stringify(data));
};

export const getProfileData = () => {
  const spaceData = JSON.parse(sessionStorage.getItem('profileListingData'));
  const removedTaxInfo = spaceData?.routeList?.filter((res) => {
    return !res.includes(`/dashboard/list-a-space/tax_information`);
  });
  return spaceData?.space_location?.[0]?.spaceCountry === AUSTRALIA
    ? { ...spaceData, routeList: removedTaxInfo }
    : JSON.parse(sessionStorage.getItem('profileListingData'));
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Button } from 'antd';

const SearchFilters = ({ filterType, onChange,adminType }) => {
  const [filterValue, setFilterValue] = useState([]);
  const spaceActivity = useSelector(state => state?.searchResult?.spaceActivities?.data);

  const handleChange = (value) => {
    setFilterValue(value);
  };

  const handleChangeSearch = () => {
    onChange(filterValue);
  };

  const handleReset = () => {
    setFilterValue([]);
    onChange([]);
  };

  return (
    <div className="report-dropdown-input">
      <Checkbox.Group style={{ width: '100%' }} value={filterValue} onChange={handleChange}>
        {filterType === 'category' ? (
          spaceActivity?.map((category) => (
            <div>
              <Checkbox value={category.name}> {category.name} </Checkbox>
              <br />
            </div>
          ))
        ) : filterType === 'activity' ? (
          spaceActivity?.map((activity) =>
            activity.venueType?.map((type) => (
              <div>
                <Checkbox value={type.name}> {type.name} </Checkbox>
                <br />
              </div>
            ))
          )
        ) : filterType === 'status' ? (
          <div>
            <Checkbox value="ACTIVE"> ACTIVE </Checkbox>
            <br />
            <Checkbox value="INACTIVE"> INACTIVE </Checkbox>
            <br />
            <Checkbox value="PENDING"> PENDING </Checkbox>
            <br />
            <Checkbox value="DECLINED"> DECLINED </Checkbox>
            <br />
          </div>
        ) : filterType === 'country' ? (
          <div>
            <Checkbox value="SG"> SG </Checkbox>
            <br />
            <Checkbox value="LK"> SL </Checkbox>
            <br />
            <Checkbox value="AUS"> AUS </Checkbox>
            <br />
          </div>
        ) : filterType === 'paymentStatus' ? (
          <div>
            <Checkbox value="INITIATED"> INITIATED </Checkbox>
            <br />
            <Checkbox value="COMPLETED"> COMPLETED </Checkbox>
            <br />
            <Checkbox value="CANCELLED"> CANCELLED </Checkbox>
            <br />
          </div>
        ) : filterType === 'bookingStatus' ? (
          <div>
            <Checkbox value="INITIATED"> INITIATED </Checkbox>
            <br />
            <Checkbox value="CANCELLED"> CANCELLED </Checkbox>
            <br />
            <Checkbox value="TERMINATED"> TERMINATED </Checkbox>
            <br />
            <Checkbox value="DECLINED"> DECLINED </Checkbox>
            <br />
            <Checkbox value="COMPLETED"> COMPLETED </Checkbox>
            <br />
            <Checkbox value="CONFIRMED"> CONFIRMED </Checkbox>
            <br />
            <Checkbox value="REQUESTED"> REQUESTED </Checkbox>
            <br />
            <Checkbox value="WITHDRAWN"> WITHDRAWN </Checkbox>
          </div>
        ) : filterType === 'cardType' ? (
          <div>
            <Checkbox value="amex"> AMEX </Checkbox>
            <br />
            <Checkbox value="visa"> Visa </Checkbox>
            <br />
            <Checkbox value="mastercard"> MasterCard </Checkbox>
            <br />
          </div>
        ) : filterType === 'role' ? (
          <div>
            <Checkbox value="Admin"> Admin </Checkbox>
            <br />
            <Checkbox value="User"> User </Checkbox>
            <br />
          </div>
        ) : filterType === 'signUpMode' ? (
          <div>
            <Checkbox value="Google"> Google </Checkbox>
            <br />
            <Checkbox value="Facebook"> Facebook </Checkbox>
            <br />
            <Checkbox value="Traditional"> Traditional </Checkbox>
            <br />
          </div>
        ) : filterType === 'other' ? (
          <div>
            <Checkbox value="Yes"> Yes </Checkbox>
            <br />
            <Checkbox value="No"> No </Checkbox>
            <br />
          </div>
        ) : (
          <></>
        )}
      </Checkbox.Group>
      <div className="mt-3 border-top">
        <button onClick={handleReset} className="btn-reset">
          Reset
        </button>
        <button className="btn-search" onClick={handleChangeSearch}>
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchFilters;

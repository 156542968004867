import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { getBookingReportData, getSearchResults, getSearchResultsAsLists } from '../ReportAction';
import {
  bookingTrackerObj,
  searchOnFilter,
  searchTextOnFilter,
  handleNullValues,
  searchFilterForEmptyFields,
  setToolTip,
  bookingTrackerSearchResultObj,
  bookingTrackerObjList,
} from '../../../helper/ReportingHelper';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import YearMonthFilter from '../common/YearMonthFilter';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import DateFilter from '../common/DateFilter';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';
import decode from 'jwt-decode';
import { Spin } from 'antd';

const BookingTracker = ({ handleDownload, status }) => {
  const [dataSource, setDataSource] = useState([]);
  const [dataSource1, setDataSource1] = useState([]);
  const [tableData, setTableData] = useState({});
  const [searchingStatus,setSearchingStatus]= useState(false);
  const [searchStatusList,setSearchStatusList] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const dispatch = useDispatch();
  const bookingTrackerData =  useSelector((state) => state?.report.reports?.booking?.data);
  const dataloading = useSelector((state) => state?.report?.reports?.booking?.loading);
  const authToken  = useSelector((state) => state?.auth?.loginDetails?.data?.token);  
  const bookingTrackerSearchResult = useSelector((state) => state?.report?.searchResult || {});
  const bookingTrackerSearchResultList = useSelector((state) => state?.report?.searchResultList || []);
  const userDetails =useSelector((state) => state?.auth);
  const loggedUser = authToken ? decode(authToken) : '';
  const isSuperAdmin = loggedUser.authorities && (loggedUser.authorities[0] === 'role.suadmin') ? true : false;
  const [searchingKey, setSearchingKey] = useState('');
  const [dataSourcesLists, setDataSourcesLists] = useState([]);

  const fetchingData = useSelector((state) => state?.report.reports?.booking?.fetching || true);

  const userCountry = loggedUser?.location ?.country;
  
  useEffect(() => {
    const formattedData = bookingTrackerObj(bookingTrackerData);
    formattedData?.length && setDataSource(formattedData);
    console.log('booking data change',bookingTrackerData);

    // if(!fetching && !bookingTrackerData.length)
    // setLoading(false)
  }, [bookingTrackerData]);


  useEffect(() => {
      setDataLoading(dataloading)

  }, [dataloading]);

  useEffect(() => {
    if (bookingTrackerSearchResultList.length) {
      const formattedDataList = bookingTrackerObjList(bookingTrackerSearchResultList);

      formattedDataList?.length && setDataSourcesLists(formattedDataList);
    }
  }, [bookingTrackerSearchResultList]);

  const handleSearch = (setSelectedKeys, confirm, searchText, type, mainType, searchTextKey) => {
    const startDate = searchText[0];
    const endDate = searchText[searchText.length - 1];
    setSearchingKey(searchTextKey);
    dispatch(getSearchResults(searchText, type, mainType, startDate, endDate));
    setSearchingStatus(true);
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSearchingStatus(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleSearchList = (setSelectedKeys, confirm, searchText, type, mainType, searchTextKey) => {
    let startDate = '';
    let endDate = '';
    if (type === 'date') {
      startDate = searchText[0];
      endDate = searchText[searchText.length - 1];
    }
    setSearchingKey(type);
    dispatch(getSearchResultsAsLists(searchText, type, mainType, startDate, endDate));
    setSearchStatusList(true);
  };

  const handleResetList = (setSelectedKeys, clearFilters) => {
    setSearchStatusList(false);
    setSelectedKeys([]);
    clearFilters();
  };

  const handleFilterSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleFilterReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const checkObj = (searchingKey) => {
    let searchText1 = searchingKey;

    let res = [];
    if (bookingTrackerSearchResult.hasOwnProperty(searchText1) && bookingTrackerSearchResult[searchText1] === null) {
      res = []; 
    } else {
      res = [bookingTrackerSearchResultObj(bookingTrackerSearchResult)];
    }

    return res;
  };

  const bookingHeaders = [
    {
      key: 'bookingRef',
      title: 'Booking Ref.#',
      dataIndex: 'bookingRef',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, type, mainType, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearch(setSelectedKeys, confirm, searchText, (type = 'bookingId'), (mainType = 'booking-tracker'), (searchTextKey = 'bookingId'))
          }
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'bookingRef');
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="category"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '68px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'category');
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'activity');
      },
    },
    {
      key: 'guestName',
      title: 'Guest Name',
      dataIndex: 'guestName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, mainType, type, startDate, endDate, searchTextKey }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'guestName'),
              (mainType = 'booking-tracker'),
              (startDate = ''),
              (endDate = ''),
              searchTextKey
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'guestName');
      },
    },
    {
      key: 'guestEmail',
      title: 'Guest Email',
      dataIndex: 'guestEmail',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostName',
      title: 'Host Name',
      dataIndex: 'hostName',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostEmail',
      title: 'Host Email',
      dataIndex: 'hostEmail',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceId',
      title: 'Space ID',
      dataIndex: 'spaceId',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        mainType = 'booking-tracker',
        type = 'spaceName',
        startDate = '',
        endDate = '',
      }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) =>
            handleSearchList(
              setSelectedKeys,
              confirm,
              searchText,
              (type = 'spaceName'),
              (mainType = 'booking-tracker'),
              (startDate = ''),
              (endDate = '')
            )
          }
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '81px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return text === 'LK' ? 'SL' : handleNullValues(text);
      },
      filterDropdown:
      isSuperAdmin ?
       ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ):null,
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'bookingDate',
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      width: 113,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        mainType = 'booking-tracker',
        type = 'date',
        startDate,
        endDate,
        searchTextKey,
      }) => (
        <DateFilter
          onReset={() => handleResetList(setSelectedKeys, clearFilters)}
          onSearch={(searchText) =>
            handleSearchList(setSelectedKeys, confirm, searchText, (type = 'date'), (mainType = 'booking-tracker'), startDate, endDate, searchTextKey)
          }
        />
      ),
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'bookingDate');
      },
    },
    {
      key: 'eventStartDate',
      title: 'Event Start Date',
      dataIndex: 'eventStartDate',
      width: 113,
    },
    {
      key: 'eventEndDate',
      title: 'Event End Date',
      dataIndex: 'eventEndDate',
      width: 113,
    },
    {
      key: 'eventStartTime',
      title: 'Event Start Time',
      dataIndex: 'eventStartTime',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'eventEndTime',
      title: 'Event End Time',
      dataIndex: 'eventEndTime',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="month"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '51px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'month');
      },
    },
    {
      key: 'year',
      title: 'Year',
      dataIndex: 'year',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="year"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '38px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'year');
      },
    },
    {
      key: 'guestCount',
      title: '# of Guests',
      dataIndex: 'guestCount',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      title: 'Booking Duration',
      children: [
        {
          key: 'days',
          title: '# of Days',
          dataIndex: 'days',
          width: 113,
          render: (text) => {
            return handleNullValues(text);
          },
        },
        {
          key: 'hours',
          title: 'Hours',
          dataIndex: 'hours',
          width: 113,
          render: (text) => {
            return handleNullValues(text);
          },
        },
        {
          key: 'blocks',
          title: '# of Blocks',
          dataIndex: 'blocks',
          width: 113,
          render: (text) => {
            return handleNullValues(text);
          },
        },
        {
          key: 'bookingCount',
          title: 'Booking Count',
          dataIndex: 'bookingCount',
          width: 113,
          render: (text) => {
            return handleNullValues(text);
          },
        },
      ],
    },
    {
      key: 'promoCode',
      title: 'Promo Code',
      dataIndex: 'promoCode',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleFilterSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleFilterReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '50px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'promoCode');
      },
    },
    {
      key: 'bookingType',
      title: 'Booking Type',
      dataIndex: 'bookingType',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
    },
    {
      key: 'paymentStatus',
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="paymentStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }} theme="filled" />,
      onFilter: (value, record) => {
        return value.find((element) => record['paymentStatus'] === element);
      },
    },
    {
      key: 'bookingStatus',
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      className: 'custom-width',
      width: 113,
      render: (text) => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="bookingStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleFilterSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleFilterReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'bookingStatus');
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'booking-tracker', totalDataLength: dataSource?.length, token: authToken, spaceCountry: userCountry });
  };

  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={bookingHeaders}
        dataSource={searchingStatus ? checkObj(searchingKey) : searchStatusList ? dataSourcesLists : dataSource}
        tableDispatch={getBookingReportData}
        props={{ increaseValue: 20, token: authToken ,user:userDetails}}
        tableData={setTableData}
        searchStatus={searchingStatus}
        searchStatusList={searchStatusList}
        scroll={{ y: 330, x: 500 }}
        loading={dataLoading}
      />
    </div>
  );
};

export default withTracker('BOOKING_TRACKER')(BookingTracker);
